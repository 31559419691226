import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { fetchStripeSubscriptionDetails, cancelSubscription } from '../../redux/features/userSlice';
import Button from '../../components/Buttons/Button';
import { toggleAutoRenew, fetchPurchaseHistory, createCustomerPortal} from '../../api/stripeService';
import ConfirmationModal from '../../components/Modal/ConfirmationModal';
import { useNavigate } from 'react-router-dom';
import { ThreeDots } from 'react-loader-spinner'; // Import loader component
import { Helmet } from 'react-helmet';
import toast from 'react-hot-toast';

const InfoPayment = () => {
  const dispatch = useDispatch();
  const [loadingPortal, setLoadingPortal] = useState(false); // New state for loading
  const [loading, setLoading] = useState(true);
  const [cancelLoading, setCancelLoading] = useState(false); // New loading state

  const stripeData = useSelector((state) => state.user.stripe);
  const [purchaseHistory, setPurchaseHistory] = useState([]); // Nouvel état pour l'historique des achats
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const navigate = useNavigate()
  const [autoRenewLoading, setAutoRenewLoading] = useState(false); // New loading state for auto-renew
  const [portalUrl, setPortalUrl] = useState(null); // State to store the portal URL

  const [subscription, setSubscription] = useState({
    plan: '',
    payment: 0,
    frequency: 'monthly',
    startDate: '',
    nextRenewalDate: '',
    autoRenew: true,
    status: 'Disabled',
  });

  const handleDeleteSubscriptionClick = () => {
    setIsModalOpen(true);
  };
  
  const handleViewMoreClick = async () => {
    setLoadingPortal(true); // Start loading
    try {
      // Make a request to create the customer portal session
      const response = await createCustomerPortal(stripeData.stripe_customer_id)
      
      // Retrieve the URL from the response
      if (response.data.url) {
        setPortalUrl(response.data.url);
        window.location.href = response.data.url;
      } else {
        toast.error("An error occurred while generating the portal URL.");
        //console.error("No URL returned from backend.");
      }
    } catch (error) {
      toast.error("An error occurred while generating the portal URL.");
      //console.error("Error generating portal URL:", error);
    } finally {
      setLoadingPortal(false); // End loading
    }
  };

  const handleAutoRenewToggle = async () => {
    setAutoRenewLoading(true); // Start loading
    try {
      const res = await toggleAutoRenew(stripeData.stripe_subscriber_id); // Call the direct function
      //console.log("Toggle Auto-Renew Response:", res);
  
      // Update the local state based on the API response
      setSubscription((prevState) => ({
        ...prevState,
        autoRenew: res.auto_renew,
      }));
    } catch (err) {
      toast.error("Failed to toggle auto-renew.");
      //console.error("Failed to toggle auto-renew:", err);
    } finally {
      setAutoRenewLoading(false); // End loading
    }
  };

  const handleCancelSubscription = async (cancelType) => {
    setCancelLoading(true); // Start loading
    try {
      await dispatch(cancelSubscription({ cancelType })).unwrap();
      // Update the subscription state locally after a successful cancellation
      setSubscription((prev) => ({
        ...prev,
        status: "canceled",
      }));
      //console.log("Subscription cancelled successfully");
      setIsModalOpen(false); // Close the modal after successful cancellation
    } catch (err) {
      toast.error("Failed to cancel subscription, retry later.");
      //console.error("Failed to cancel subscription:", err);
    } finally {
      setCancelLoading(false); // End loading
    }
  };
  
  

  useEffect(() => {
    // Avoid fetching data if the stripe_customer_id is missing
    if (!stripeData?.stripe_customer_id) {
      return;
    }
  
    // Update the loading state before sending requests
    setLoading(true);
  
    // Fetch subscription details and purchase history concurrently
    const fetchDetails = async () => {
      try {
        if (stripeData.stripe_subscriber_id) {
          // Fetch subscription details
          const subscriptionRes = await dispatch(fetchStripeSubscriptionDetails(stripeData.stripe_subscriber_id)).unwrap();
    
          // Update subscription state if details are fetched successfully
          if (subscriptionRes) {
            setSubscription((prevState) => ({
              ...prevState,
              plan: subscriptionRes.plan_name,
              payment: subscriptionRes.price,
              frequency: subscriptionRes.interval,
              startDate: subscriptionRes.start_date,
              nextRenewalDate: subscriptionRes.next_renewal_date,
              autoRenew: subscriptionRes.auto_renew,
              status: subscriptionRes.status,
            }));
          }
          //console.log(subscriptionRes)

        }

  
        // Fetch purchase history
        const purchaseHistoryRes = await fetchPurchaseHistory(stripeData.stripe_customer_id);
        setPurchaseHistory(purchaseHistoryRes);
        //console.log(purchaseHistoryRes)
      } catch (err) {
        toast.error("Failed to fetch details, retry later.");
        //console.error("Failed to fetch details:", err);
      } finally {
        // Update the loading state at the end of the operation
        setLoading(false);
      }
    };
  
    fetchDetails();
  }, [dispatch]);
  

  // Helper function to format dates
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString('en-US', {
      month: 'short',
      day: 'numeric',
      year: 'numeric',
    });
  };

  // Helper function to determine the badge color and text based on status
    const getBadgeStyles = (status) => {
      switch (status) {
        case 'active':
          return 'bg-green-100 text-green-700'; // Active subscription
        case 'incomplete':
        case 'past_due':
          return 'bg-yellow-100 text-yellow-700'; // Payment issue or incomplete subscription
        case 'canceled':
        case 'incomplete_expired':
        case 'unpaid':
          return 'bg-red-100 text-red-700'; // Canceled, expired, or unpaid subscription
        case 'trialing':
          return 'bg-blue-100 text-blue-700'; // In trial period
        default:
          return 'bg-gray-100 text-gray-700'; // Default or unknown status
      }
    };
  

  // Calculate the time remaining for the subscription renewal
  const calculateTimePassedPercentage = () => {
    const currentDate = new Date();
    const nextRenewal = new Date(subscription.nextRenewalDate);
    const start = new Date(subscription.startDate);
  
    const totalDuration = nextRenewal - start;
    const timePassed = currentDate - start;
  
    const passedPercentage = Math.max(
      0,
      Math.min(100, ((timePassed / totalDuration) * 100).toFixed(2))
    );
    return passedPercentage;
  };

  const timePassedPercentage = calculateTimePassedPercentage();

  return (
    <div className="p-6 bg-white shadow-md rounded-b-lg">
      <Helmet>
        <title>Payment Information - History - Billing - Plans - Havenude</title>
        <meta name="description" content="Payment Information - History - Billing - Plans - Have Nude" />
        <meta property="og:title" content="Havenude" />
        <meta property="og:description" content="Payment Information - History - Billing - Plans - Have Nude." />
        {/* Ajouter d'autres balises SEO ou Open Graph ici */}
      </Helmet>
      <h1 className="text-2xl font-bold mb-4 sm:mb-8">Billing and Plan</h1>
      <div className="flex flex-col lg:flex-row">
        {/* Left Section - Subscription Details */}
        <div className="w-full lg:w-2/3 p-4 sm:p-5">
          <h3 className="text-lg font-semibold text-gray-900 mb-4">My plans</h3>
          <div className="p-4 sm:p-6 rounded-lg shadow-md mb-6 bg-gray-200">
            {!stripeData?.stripe_subscriber_id ? (
                <div className="text-center text-gray-600 py-8">
                  No active subscription found.
                </div>
              ) : (
              <>
            <div className="flex flex-col sm:flex-row sm:justify-between sm:items-start">
                {/* Plan Name, Badge, and Payment */}
                <div className="flex flex-col">
                  <div className="flex items-center">
                  {loading ? (
                    <div className="w-36 h-6 bg-gray-300 animate-pulse rounded-md" />
                      ) : (
                        <h2 className="text-xl font-semibold text-gray-900">{subscription.plan}</h2>
                      )}
                      {loading ? (
                        <div className="ml-4 w-16 h-6 bg-gray-300 animate-pulse rounded-full" />
                      ) : (
                        <span className={`ml-4 px-4 py-2 rounded-full text-xs font-medium ${getBadgeStyles(subscription.status)}`}>
                          {subscription.status}
                        </span>
                      )}
                  </div>
                  {loading ? (
                    <div className="w-24 h-5 mt-1 bg-gray-300 animate-pulse rounded-md" />
                    ) : (
                      <p className="text-sm text-gray-500 mt-1">${subscription.payment} {subscription.frequency}</p>
                    )}                  
                  </div>

                  {/* Auto Renew and Cancel or Re-subscribe Button */}
                  <div className="flex flex-col sm:flex-row sm:items-center sm:space-x-4 text-blue-600">


                  {/* Toggle Switch for Auto Renew */}
                  {loading ? (
                    <div className="w-20 h-8 bg-gray-300 animate-pulse rounded-md" />
                  ) : (
                    <label
                      className={`flex items-center cursor-pointer mt-4 sm:mt-0 ${
                        subscription.status === "canceled" ? 'opacity-10 grayscale-[0.5] pointer-events-none' : ''
                      }`}
                    >
                      <div className="relative flex items-center">
                        <input
                          type="checkbox"
                          className="sr-only"
                          checked={subscription.autoRenew}
                          disabled={subscription.status === "Disabled" || autoRenewLoading} // Disable if loading or status is Disabled
                          onChange={handleAutoRenewToggle}
                        />
                        <div
                          className={`block w-10 h-5 sm:w-12 sm:h-6 rounded-full transition-colors duration-300 ${
                            subscription.autoRenew ? 'bg-blue-500' : 'bg-gray-300'
                          } ${autoRenewLoading ? 'opacity-50' : ''}`} // Add transparency when loading
                        ></div>
                        <div
                          className={`dot absolute left-1 top-0.5 w-4 sm:w-5 h-4 sm:h-5 rounded-full bg-white transition-transform duration-300 ${
                            subscription.autoRenew ? 'transform translate-x-5 sm:translate-x-6' : ''
                          }`}
                        ></div>
                        
                      </div>
                      <span className="ml-2 sm:ml-3 text-gray-700 font-medium text-sm">Auto Renew</span>
                    </label>
                  )}


                
                {loading ? (
                  // Display button skeleton during loading
                  <div className="w-24 h-8 bg-gray-300 animate-pulse rounded-md mt-5 sm:mt-0" />
                ) : (
                  <>
                  {subscription.status === "canceled" ? (
                    <Button
                      onClick={() => navigate("/shop")}
                      size="small"
                      variant="outline"
                      className="relative mt-5 sm:mt-0"
                    >
                      Buy Again
                    </Button>
                  ) : (
                    // Display Cancel Subscription Button when status is active
                  <Button
                    onClick={handleDeleteSubscriptionClick}
                    size="small"
                    variant="outline"
                    className="relative mt-5 sm:mt-0"
                  >
                    Cancel Subscription
                  </Button>
                  )
                }
                </>
              )}
                       
                </div>
                
            </div>

            {subscription.status === "canceled" ? (
                <div></div>
              ) : (
                <>
                  <hr className="my-4 border-gray-400" />

                  <div className="flex flex-col sm:flex-row sm:justify-between items-start mt-8">
                    {/* Left side - Progress Bar */}
                    <div className="w-full sm:w-1/2">
                      {loading ? (
                        <div className="w-full h-2 bg-gray-300 animate-pulse rounded-full mb-1" />
                      ) : (
                        <>
                          {/* Conditional Message for Canceled Subscription */}
                          {subscription.autoRenew ? (
                          <h4 className="text-sm font-medium text-gray-600 mb-1">Time remaining until renewal</h4>
                          ) : (
                            <h4 className="text-sm font-medium text-gray-600 mb-1"> Your subscription is still available until the end of the period.</h4>

                          )}
                          <div className="relative h-2 bg-gray-300 rounded-full overflow-hidden mb-1">
                            <div className="h-full bg-blue-500" style={{ width: `${timePassedPercentage}%` }} />
                          </div>
                          <p className="text-xs text-gray-600">
                            {timePassedPercentage}% of time has passed {subscription.autoRenew ? ("until renewal") : (null)}
                          </p>
                        </>
                      )}
                    </div>
                    {/* Right side - Additional Information */}
                    <div className="w-full sm:w-1/2 text-left sm:text-right mt-4 sm:mt-0">
                      {loading ? (
                        <>
                          <div className="w-24 h-5 mb-2 bg-gray-300 animate-pulse rounded-md" />
                          <div className="w-24 h-5 bg-gray-300 animate-pulse rounded-md" />
                        </>
                      ) : (
                        <>
                          <p className="text-sm text-gray-600 mt-2">
                            <span className="font-medium">Started On:</span> {formatDate(subscription.startDate)}
                          </p>
                          <p className="text-sm text-gray-600 mt-2">
                            <span className="font-medium">Next Billing Date:</span> {formatDate(subscription.nextRenewalDate)}
                          </p>
                        </>
                      )}
                    </div>
                  </div>
                </>
              )}

            </>
            )}
          </div>
          <div className="flex justify-center items-center mt-4">
            <Button
              onClick={handleViewMoreClick}
              size="medium"
              variant="outline"
              className="relative"
              disabled={stripeData == null || loadingPortal} // Disable the button when loading
            >
              {loadingPortal ? (
                <ThreeDots // Display a loading spinner if the URL is being generated
                  height="16"
                  width="16"
                  color="blue"
                />
              ) : (
                "View More about your plan"
              )}
            </Button>
          </div>
        </div>

        {/* Right Section - Purchase History List */}
        <div className="w-full lg:w-1/3 p-4 sm:p-5">
          <h3 className="text-lg font-semibold text-gray-900 mb-4">Purchase History</h3>
          {!stripeData?.stripe_customer_id ? (
            <div className="text-center text-gray-600 py-8">
              No history.
            </div>
          ) : loading ? (
            [...Array(2)].map((_, index) => (
              <div
                key={index}
                className="w-full h-24 bg-gray-300 animate-pulse rounded-lg mb-3"
              />
            ))
          ) : (
            purchaseHistory.length > 0 ? (
              purchaseHistory.map((purchase) => (
                <div
                  key={purchase.id}
                  className="bg-white p-4 rounded-lg shadow-md mb-4 flex flex-col justify-between"
                >
                   
                  {/* Displaying Title with a fallback */}
                  <h4 className="text-md font-semibold text-gray-900 mb-1">
                    {purchase.title || "One-time Purchase"}
                  </h4>
                  
                  {/* Amount and Status Section */}
                  <div className="flex items-center justify-between mb-2">
                    <p className="text-lg font-semibold text-gray-800">
                      €{purchase.amount.toFixed(2)}
                    </p>
                    { purchase.refunds ? 
                      <span
                      className={"px-2 py-1 text-xs font-medium rounded-full bg-purple-100 text-purple-700"}
                    >
                      Refund
                    </span>
                    :
                      <span
                      className={`px-2 py-1 text-xs font-medium rounded-full ${
                        purchase.status === "paid"
                          ? "bg-green-100 text-green-700"
                          : purchase.status === "unpaid"
                          ? "bg-red-100 text-red-700"
                          : "bg-yellow-100 text-yellow-700"
                      }`}
                    >
                      {purchase.status === "paid"
                        ? "Successful"
                        : purchase.status === "unpaid"
                        ? "Failed"
                        : "Unknown"}
                    </span>
                    }
                    

                  </div>

                  {/* Date and Time */}
                  <div className="text-xs text-gray-500">
                    <p>{purchase.date}</p>
                    <p>{purchase.time}</p>
                  </div>

                  <div className="text-xs font-medium text-gray-500 mt-2">
                    {"Reference : " + purchase.reference || "One-time Purchase"}
                  </div>
                </div>
              ))
            ) : (
              <p className="text-sm text-gray-500">No purchase history found.</p>
            )
          )}
          
        </div>

      </div>
      {/* Confirmation Modal */}
      <ConfirmationModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        onConfirm={() => handleCancelSubscription("immediate")}
        isChecked={isChecked}
        setIsChecked={setIsChecked}
        loading={cancelLoading}
      />
    </div>
  );
};

export default InfoPayment;
