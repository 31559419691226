import { configureStore } from '@reduxjs/toolkit';
import { composeWithDevTools } from '@redux-devtools/extension';
import autoMergeLevel2 from "redux-persist/lib/stateReconciler/autoMergeLevel2"; // ADDED

import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from 'redux-persist'
import storageSession from 'redux-persist/lib/storage/session'
import storage from 'redux-persist/lib/storage';
import userReducer from './features/userSlice';
import photoReducer from './features/photoSlice';
import { combineReducers } from 'redux';

const persistConfig = {
  key: 'rootpans',
  storage,
  stateReconciler: autoMergeLevel2 // ADDED

};

const rootReducer = combineReducers({
  user: userReducer,
  photos: photoReducer,
  // Ajoute d'autres slices ici si nécessaire
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false, // Désactivation du contrôle de sérialisation
    })
    //.concat(tokenMiddleware) // Ajout du middleware token
    //.concat(rehydrationMiddleware), // Ajout du middleware de réhydratation
});

export const persistor = persistStore(store)
