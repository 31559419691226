import axios from 'axios';
import authService from './authService';
import { globalMessages } from '../utils/globalMessage';

const environment = process.env.REACT_APP_ENV || 'local'; // 'local' sera la valeur par défaut si REACT_APP_ENV est undefined

let URL_BACKEND;

if (environment === 'prod') {URL_BACKEND = 'https://api.havenude.com/';}  // URL pour l'environnement de production
else if (environment === 'dev') {URL_BACKEND = 'https://dev.api.havenude.com/';}  // URL pour l'environnement de dev
else if (environment === 'local') {URL_BACKEND = 'http://localhost:8000/';}  // URL par défaut pour l'environnement local

//const API_URL = "http://api.havenude.com:8082/";
//const API_URL_NODNS = "http://5.196.28.104:8082/";
//const API_LOCAL = "http://localhost:8000";

let isRefreshing = false;
let failedQueue = [];

const apiClient = axios.create({
    baseURL: URL_BACKEND, // Remplacez par l'URL de votre backend
    headers: {
        'Content-Type': 'application/json',
    },
});


const processQueue = (error, token = null) => {
    failedQueue.forEach(prom => {
      if (error) {
        prom.reject(error);
      } else {
        prom.resolve(token);
      }
    });
  
    failedQueue = [];
  };

apiClient.interceptors.request.use(
    (config) => {
        const accessToken = localStorage.getItem('idToken'); // get stored access token
        if (accessToken) {
          config.headers.Authorization = `Bearer ${accessToken}`; // set in header
        } 
        return config;
    },
    (error) => {
      //console.log('ERROR TOKENNNNNNN:', error);
        return Promise.reject(error);
    }
);

apiClient.interceptors.response.use(
    response => {
      //const successMessage = response.data?.message; // Assuming `message` holds the success key
      //console.log('response:', successMessage);
      return response;
    },
    async error => {
      const originalRequest = error.config;

      //console.log('error.response:', error);
      // Vérifier si c'est une erreur de token expiré
      if (error.response.status === 403 && !originalRequest._retry) {
        if (isRefreshing) {
            //console.log('isRefreshing:', isRefreshing);
          // Si le token est déjà en cours de rafraîchissement, ajouter la requête à la queue
          return new Promise((resolve, reject) => {
            failedQueue.push({ resolve, reject });
          }).then(token => {
            originalRequest.headers['Authorization'] = `Bearer ${token}`;
            return apiClient(originalRequest);
          }).catch(err => {
            return Promise.reject(err);
          });
        }
  
        originalRequest._retry = true;
        isRefreshing = true;
  
        // Rafraîchir le token
        try {
          //console.log('Token expired, refreshing...');
          const refreshToken = localStorage.getItem('refreshToken');
          const data = await authService.refreshToken(refreshToken);
          // Sauvegardez les nouveaux tokens dans le localStorage
          //console.log('data:', data);
          localStorage.setItem("idToken", data.idToken);
          localStorage.setItem("refreshToken", data.refreshToken);
  
          apiClient.defaults.headers['Authorization'] = `Bearer ${data.newToken}`;
          processQueue(null, data.newToken);
  
          return apiClient(originalRequest);
        } catch (err) {
          processQueue(err, null);
          return Promise.reject(err);
        } finally {
          isRefreshing = false;
        }
      } else {
        const errorResponse = error.response?.data?.detail || "An unknown error occurred.";
        const errorCode = error.response?.status || "UNKNOWN_ERROR";
        //console.error(`Error (${errorCode}): ${errorResponse}`);
      }
  
      return Promise.reject(error);
    }
  );

export default apiClient;
