import apiClient from './apiClient';
import qs from 'qs'; // Importer qs pour convertir les objets en query string

const signup = async (data) => {
    try {
        //console.log(data)
        const response = await apiClient.post('/auth/signup', data);
        //console.log('API Response in authservice for signup:', response); // Log the response from the API

        return response.data;
    } catch (error) {
        //console.error('Error during signup', error.response);
        throw error;
    }
};

const googleSSO = async (data) => {
    try {
        //console.log(data)
        const response = await apiClient.post('/auth/google-signup', data);
        //console.log('API Response in authservice for signup:', response); // Log the response from the API

        return response.data;
    } catch (error) {
        //console.error('Error during signup', error);
        throw error;
    }
};

const login = async (data) => {
    try {
        const response = await apiClient.post('/auth/login', data);
        //console.log('API Response un authservice for login:', response); // Log the response from the API

        return response.data;
    } catch (error) {
        //console.error('Error during login', error);
        throw error;
    }
};

const logout = async () => {
    try {
        localStorage.removeItem("idToken");
        localStorage.removeItem("refreshToken");
        localStorage.removeItem("userId");
        sessionStorage.removeItem("currentPage");
        sessionStorage.removeItem("posts");
        sessionStorage.removeItem("scrollPosition");
        sessionStorage.removeItem("isReload");

    } catch (error) {
        //console.error('Error during logout', error);
        throw error;
    }
};

const refreshToken = async (refreshTok) => {
    try {
        const data = qs.stringify({ refresh_token: refreshTok });

        const response = await apiClient.post('/auth/refresh', data, {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
        });
        //console.log('API Response in authservice for refresh token:', response); // Log the response from the API

        return response.data;
    } catch (error) {
        //console.error('Error during logout', error);
        throw error;
    }
};

const resetPassword = async (email) => {
    try {
        const response = await apiClient.post('/auth/send-email-password', email, {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
        });
        //console.log('API Response un authservice for send-email-password:', response); // Log the response from the API

        return response.data;
    } catch (error) {
        //console.error('Error reset password', error);
        throw error;
    }
};

const authService = {
    signup,
    login,
    refreshToken,
    logout,
    resetPassword,
    googleSSO
};
  
export default authService;
