import React, { useState } from 'react';
import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  RouterProvider,
  ScrollRestoration
} from 'react-router-dom';
import Layout from './components/Layout';
import Shop from './pages/Shop/Shop';
import { Navigate } from 'react-router-dom';
import Contact from './pages/Contact';
import NotFound from './pages/NotFound';
import AboutUs from './pages/AboutUs';
import InfoPayment from './pages/SectionProfile/InfoPayment';
import Home from './pages/Home';
import Profile from './pages/Profile';
import ProfileInformation from './pages/SectionProfile/ProfileInformation';
import Checkout from './pages/Payment/Checkout';
import Photo from './pages/SectionProfile/Photo';
import PublicProfile from './pages/PublicProfile/PublicProfile';
import CheckoutResult from './pages/Shop/CheckoutResult';
import AgeVerificationPopup from './components/Modal/AgeVerificationPopup';
import ResetPasswordPage from './pages/ResetPasswordPage';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { fetchUserData, fetchStripeData } from './redux/features/userSlice';
import FAQPage from './pages/FAQ';
import TermsOfUse from './pages/TermsOfUse';
import SSEComponent from './components/SSE/SSEComponent';
import toast from 'react-hot-toast';

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/" element={<Layout />}>
      <Route index element={<Home />} />
      <Route path="home" element={<Navigate to="/" replace />} />
      <Route path="contact" element={<Contact />} />
      <Route path="reset-password" element={<ResetPasswordPage />} />
      <Route path="public-profile/:userId" element={<PublicProfile />} />
      <Route path="about-us" element={<AboutUs />} />
      <Route path="profile-info" element={<Profile />}>
          <Route index path="informations" element={<ProfileInformation />} />
          <Route path="photo" element={<Photo />} />
          <Route path="payment" element={<InfoPayment />} />
      </Route>
      <Route path="checkout" element={<Checkout />} />
      <Route path="faq" element={<FAQPage />} />
      <Route path="terms" element={<TermsOfUse />} />
      <Route path="checkout-result" element={<CheckoutResult />} /> 
      <Route path="*" element={<NotFound />} /> {/* Use NotFound component */}
    </Route>
  )
);

export default function App() {
  const [isAgeVerified, setIsAgeVerified] = useState(false);
  const isProductionEnv = process.env.REACT_APP_ENV === 'prod';
  const isAuthenticated = useSelector((state) => state.user.isAuthenticated);
  const dispatch = useDispatch();

  useEffect(() => {
    if (isAuthenticated){
      //console.log("j'suis co je getuserdata")
      const getUserData = async () => {
        try {
          dispatch(fetchUserData());
          //dispatch(fetchStripeData());  // Nouvelle action pour obtenir les infos Stripe

        } catch (err) {
          //console.error("Failed to fetch user data:", err);
          toast.error("Failed to fetch user data");
        }
      };
  
      getUserData();
    }
    
  }, [dispatch]);

  return (
    <>
      {isProductionEnv && !isAgeVerified && (
        <AgeVerificationPopup setIsAgeVerified={setIsAgeVerified} />
      )}
      <RouterProvider router={router} >
      <ScrollRestoration
        getKey={(location, matches) => {
          return location.pathname;
        }}
      />
      </RouterProvider>

      {/* Add SSEComponent to listen for server-sent events 
      {isAuthenticated && <SSEComponent />}  */}
    </>
  );
}