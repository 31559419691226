import apiClient from "./apiClient"; // Assurez-vous que apiClient est correctement configuré

export const createPaymentSession = async (stripeId, wantsInvoice) => {
    //console.log("stripe id " + stripeId)
    //console.log("want invoice ou pas " + wantsInvoice)
    try {
        
        const response = await apiClient.post('/stripe/create-checkout-session', {
                product_type: stripeId,
                user_wants_receipt: wantsInvoice ? true : false,
                frontend_url: window.location.origin
        });
        //console.log("stripe sessionId : " + response.data.sessionId);
        return response.data.sessionId;
    } catch (error) {
        console.error('Error stripe request', error);
        throw error;
    }
};

export const getStripeInfo = async () => {
    try {
      const response = await apiClient.get('/stripe/stripe-info');
      return response.data;
    } catch (error) {
      console.error("Failed to fetch Stripe info:", error);
      throw error;
    }
  };

export const toggleAutoRenew = async (stripe_subscriber_id) => {
    try {
        // Call your backend API endpoint for toggling auto-renew
        const response = await apiClient.post('/stripe/subscription/toggle-auto-renew', { subscriptionId: stripe_subscriber_id });
        
        // If needed, return the response data
        return response.data;
    } catch (error) {
        //console.error("Error toggling auto-renew:", error.response.data);
        // Handle or throw an error as needed
        throw error.response.data;
    }
};

export const subscriptionCancel = async (cancelType) => {
    try {
        const response = await apiClient.post("/stripe/subscription/cancel-subscription", { cancel_type: cancelType })
        
        // If needed, return the response data
        return response.data;
    } catch (error) {
        //console.error("Error in cancel subscription:", error.response.data);
        // Handle or throw an error as needed
        throw error.response.data;
    }
};

export const getSubscriptionDetails = async (subscriptionId) => {
    try {
        // Appel vers ton backend configuré via `apiClient`
        const response = await apiClient.get(`/stripe/subscription/${subscriptionId}`);
        return response;
    } catch (error) {
        //console.error("Error in cancel subscription:", error.response.data);
        // Handle or throw an error as needed
        throw error.response.data;
    }
};


export const fetchPurchaseHistory = async (subscriptionId) => {
    try {
        const response = await apiClient.get('/stripe/purchase/history', {
            params: { subscriptionId: subscriptionId },
        });
        //console.log("response.data.purchases", response);
        return response.data.purchases;
    } catch (error) {
        //console.error("Error in cancel subscription:", error.response.data);
        // Handle or throw an error as needed
        throw error.response.data;
    }
};

export const checkoutSuccess = async (sessionId) => {
    const response = await apiClient.get(`/stripe/checkout/success`, { 
        params: { session_id: sessionId } 
    });
    return response;
};


export const createCustomerPortal = async (stripe_customer_id) => {
    try {
        const response = await apiClient.post(`/stripe/customer-portal`, { 
            stripe_customer_id: stripe_customer_id,
            frontend_url: window.location.origin
        });
        return response;
    } catch (error) {
        //console.error("Error in portal subscription:", error);
        // Handle or throw an error as needed
        throw error.response.data;
    }
};