import React, {useState} from 'react';
import { useLocation } from 'react-router-dom';
import OrderSummary from './OrderSummary';
import { FaChevronDown, FaChevronUp } from 'react-icons/fa';
import { Helmet } from 'react-helmet';


const Checkout = () => {
  const [disabled, setDisabled] = useState(true);

  const location = useLocation();
  const { offer } = location.state || { offer: {} };

  const [selectedFeature, setSelectedFeature] = useState(null);

  const toggleFeature = (index) => {
    if (selectedFeature === index) {
      setSelectedFeature(null);
    } else {
      setSelectedFeature(index);
    }
  };

  const [wantsInvoice, setWantsInvoice] = useState(false);

  const toggleInvoice = () => {
    //console.log('click to toggle' + !wantsInvoice);
    setWantsInvoice(!wantsInvoice);
  };

  return (
    <div className="min-h-screen bg-gray-100 flex flex-col items-center py-10">
      <Helmet>
        <title>Order Summary - {offer.title} - Havenude</title>
        <meta name="description" content="Public Profile - Have Nude" />
        <meta property="og:title" content="Havenude" />
        <meta property="og:description" content="Public Profile - Have Nude." />
        {/* Ajouter d'autres balises SEO ou Open Graph ici */}
      </Helmet>
      <div className="flex flex-col lg:flex-row w-full lg:pl-16 lg:pr-16 pr-5 pl-5">
        {/* Left side: Payment steps */}
        <div className="w-full lg:w-7/12  mb-6 md:mb-0 md:mr-6">
          
          
        <div className="mb-12">
            <h2 className="text-2xl font-medium mb-8">Invoices</h2>
            <div className="flex flex-wrap gap-4">
              <div 
                className={`flex-1 border p-4 text-center bg-white rounded-2xl ${
                  disabled ? 'cursor-not-allowed opacity-50' : 'hover:border-[#AC3B61] cursor-pointer'
                } flex flex-col items-center`}
                onClick={!disabled ? toggleInvoice : undefined} // Désactive l'événement onClick si disabled est vrai
                >
                <h3 className="font-semibold mb-2">Receive an invoice by email</h3>
                <p className="text-gray-600 mb-4">Check this box to receive an invoice at the email associated with your account.</p>
                <input 
                  type="checkbox" 
                  checked={wantsInvoice}
                  readOnly
                  disabled={disabled} // Désactive l'input si disabled est vrai
                  className="w-6 h-6 text-blue-500 rounded cursor-pointer pointer-events-none"  // Empêche la checkbox de bloquer le clic
                />
              </div>
            </div>
          </div>

          <div>
            <h2 className="text-2xl font-medium mb-8">Advantages of Your Offer</h2>
          <div className="">
            {offer.features && offer.features.length > 0 ? (
              offer.features.map((feature, index) => (
                <div key={index} className="mb-4">
                  <button 
                    className={`w-full flex justify-between items-center bg-white border py-4 px-4 hover:border-[#AC3B61] cursor-pointer ${selectedFeature === index ? 'rounded-t-2xl' : 'rounded-2xl mb-4'}`}
                    onClick={() => toggleFeature(index)}
                  >
                    {feature.name}
                    {selectedFeature === index ? <FaChevronUp /> : <FaChevronDown />}
                  </button>
                  {selectedFeature === index && (
                    <div className="p-4 mb-4 bg-gray-50 border rounded-b-2xl">
                      {feature.description}
                    </div>
                  )}
                </div>
              ))
            ) : (
              <p className="text-gray-600">No benefits are available for this offer.</p>
            )}
          </div>
          </div>
        </div>

        {/* Right side: Order summary */}
        <div className="w-full md:w-5/12 lg:pl-4">
          <OrderSummary offer={offer} wantsInvoice={wantsInvoice}/>
        </div>
      </div>
    </div>
  );
};

export default Checkout;
