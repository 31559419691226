import React from 'react';
import Button from '../Buttons/Button';
import { useNavigate } from 'react-router-dom';

const Footer = () => {
  const navigate = useNavigate(); // Move useNavigate inside the component

  const handleContactClick = () => {
    navigate('/contact');
    window.scrollTo({ top: 0, behavior: 'smooth' });

  };
  
  return (
    <footer className="bg-white py-10 shadow-inner">
      <div className="md:px-6 lg:px-10">
        <div className="flex flex-col md:flex-row justify-between items-center md:items-start ">
          {/* Logo and Download Section */}
          <div className="flex flex-col items-center md:items-start mb-8 md:mb-0 md:pr-24 md:pl-10">
            <div className="flex items-center mb-4">
              <span className="text-2xl font-bold text-gray-900">HaveNude</span>
            </div>
            <img src="assets/logo.png" alt="Logo" className="h-10 mr-2 mt-5" />

          </div>

          {/* Links Section */}
          <div className="grid grid-cols-2 md:grid-cols-4  w-full md:w-auto text-center md:text-left">
            {/* Company Links */}
            <div className='px-5 hidden md:flex'>
              
            </div>

            <div className='px-5'>
              <h5 className="font-bold text-gray-900 mb-4 text-left">COMPANY</h5>
              <ul className="space-y-3">
                <li>
                  <button
                    onClick={() => navigate('/about-us')}
                    className="text-gray-600 hover:text-gray-800 text-left w-full"
                  >
                    About Us
                  </button>
                </li>
                <li>
                  <button
                    onClick={() => navigate('/contact')}
                    className="text-gray-600 hover:text-gray-800 text-left w-full"
                  >
                    Contact Us
                  </button>
                </li>
              </ul>
            </div>

          
            {/* Help Links */}
            <div className="px-5">
              <h5 className="font-bold text-gray-900 mb-4 text-left">HELP</h5>
              <ul className="space-y-3">
                <li>
                  <button
                    onClick={() => navigate('/faq')}
                    className="text-gray-600 hover:text-gray-800 text-left w-full"
                  >
                    Frequently Asked Questions
                  </button>
                </li>
                <li>
                  <button
                    onClick={() => navigate('/terms')}
                    className="text-gray-600 hover:text-gray-800 text-left w-full"
                  >
                    Privacy Policy
                  </button>
                </li>
                <li>
                  <button
                    onClick={() => navigate('/terms')}
                    className="text-gray-600 hover:text-gray-800 text-left w-full"
                  >
                    Terms & Condition
                  </button>
                </li>
              </ul>
            </div>

            {/* Follow Us Links */}
            <div className='px-5'>
              <Button
              onClick={handleContactClick}
              variant={'outline'}
              size="medium"
              rounded="xl"
              className="hidden md:flex w-full mt-2 mb-7 justify-center"
            >
              Contact Us
            </Button>
            
            </div>
            <div className='px-5 md:hidden sm:flex'>
            <Button
              onClick={handleContactClick}
              variant={'outline'}
              size="medium"
              rounded="xl"
              className="w-full mt-2 md:hidden sm:flex"
            >
              Contact Us
            </Button>
            </div>
          </div>
        </div>
        <div className="text-center text-gray-600">
          © 2025 PansF. All Rights Reserved.
        </div>
      </div>
    </footer>
  );
};

export default Footer;