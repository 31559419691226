import React from 'react';
import logo from '../assets/logo.png';

const LoadingPage = () => {

  return (
    <nav
      
      className="p-3 flex justify-between items-center fixed top-0 left-0 w-full z-50 transition-colors duration-300"
    >
      {/* Logo and Brand Name */}
      <div className="flex items-center space-x-4">
          
        <img src={logo} alt="Logo" className="h-10" />
      </div>


      {/* Main Menu */}
     <div className="hidden lg:flex space-x-10"> </div> 
      

      {/* Right Side: Profile and Authentication */}
      <div className="lg:flex space-x-4 items-center hidden mr-5">
          <div  className="relative">
              <div className="h-10 w-10 rounded-full bg-gray-300 animate-pulse"></div>
        </div>
        
      </div>

      {/* Hamburger Menu for Mobile */}
      <div className="lg:hidden flex items-center space-x-2">
        <div  className="relative mr-5">        
            <div className="h-12 w-12 rounded-full bg-gray-300 animate-pulse"></div>       
        </div>
        
      </div>
    </nav>
  );
};

export default LoadingPage;
