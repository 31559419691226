import React, { useState, useRef, useEffect } from 'react';
import Modal from 'react-modal';
import Webcam from 'react-webcam';
import AvatarEditor from 'react-avatar-editor';
import Dropzone from 'react-dropzone';
import { useDispatch } from 'react-redux';
import './css/modal.css'; 
import { uploadPhoto, deletePhoto } from '../../redux/features/photoSlice';
import toast from 'react-hot-toast';
import Button from '../Buttons/Button';
import { Oval } from 'react-loader-spinner';
import { useSelector } from 'react-redux';
import { isMobile } from 'react-device-detect'; // Import isMobile from react-device-detect

Modal.setAppElement('#root');

const ChangePhotoModal = ({ isOpen, onRequestClose, setProfileImage }) => {

  const [isCameraOpen, setIsCameraOpen] = useState(false);
  const [photo, setPhoto] = useState(null);
  const [scale, setScale] = useState(1);
  const [photoId] = useState("photoprofil");
  const webcamRef = useRef(null);
  const editorRef = useRef(null);
  const [isDeleteLoading, setIsDeleteLoading] = useState(false); // Loading state for deleting photo
  const [isSaveLoading, setIsSaveLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false); // Loading state for deleting photo
  const dispatch = useDispatch(); // Initialiser dispatch ici


  const photoUrl = useSelector((state) => state.photos?.photos?.photoprofil?.url || null); // Adjust to match your state structure
  const defaultImageUrl = "/assets/blank-profile-login.jpg";

  const customStyles = {
    content: {
      position: 'none',
      width: '90%',
      maxWidth: '400px',
      height: 'auto',
      margin: 'auto',
      borderRadius: '8px',
      padding: '0px',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: 'white',
      outline: 'none',
    },
    overlay: {
      backgroundColor: 'rgba(0, 0, 0, 0.75)',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
  };

  const openCamera = () => {
    setIsCameraOpen(true);
    setPhoto(null); // Reset the photo when switching to camera mode
  };

  const closeCamera = () => {
    if (webcamRef.current && webcamRef.current.video.srcObject) {
      const stream = webcamRef.current.video.srcObject;
      const tracks = stream.getTracks();
      tracks.forEach(track => track.stop()); // Arrête chaque piste de la caméra
    }
    setIsCameraOpen(false);
  };

  const takePhoto = (e) => {
    e.preventDefault(); // Empêche le rechargement de la page
    if (webcamRef.current) {
      const imageSrc = webcamRef.current.getScreenshot();
      if (imageSrc) {
        setPhoto(imageSrc); // Définir la photo d'abord
          closeCamera(); // Fermer la caméra après la capture
      } else {
        toast.error('Failed to capture image. Webcam may not be initialized correctly.');
        //console.error('Failed to capture image. Webcam may not be initialized correctly.');
      }
    }
  };

  const handleFileChange = (acceptedFiles) => {
    const file = acceptedFiles[0];
    const reader = new FileReader();
    reader.onload = () => {
      setPhoto(reader.result);
    };
    reader.readAsDataURL(file);
  };

  const saveCroppedPhoto = async () => {
    setIsSaveLoading(true); // Set loading to true when saving starts
    if (editorRef.current) {
      const canvas = editorRef.current.getImageScaledToCanvas();
  
      // Convert the canvas to a Blob
      canvas.toBlob(async (blob) => {
        if (blob) {
          try {
            const resultAction = await dispatch(uploadPhoto({ photoId, file: blob })).unwrap();
            const uploadedImageUrl = resultAction.photoUrl;
            const message = resultAction.message;
            setProfileImage(uploadedImageUrl);
            toast.success(message);
            onRequestClose();
          } catch (error) {
            //console.error('Failed to upload photo:', error);
            toast.error('Failed to save photo.');
          } finally {
            setIsSaveLoading(false); // Set loading to false when the action completes
          }
        } else {
          //console.error('Failed to create Blob from canvas.');
          setIsSaveLoading(false); // Set loading to false if Blob creation fails
        }
      }, 'image/jpeg');
    }
  };

  const handleDeletePhoto = async () => {
    setIsDeleteLoading(true);
    try {
      const { photoIdd, message } = await dispatch(deletePhoto({ photoId:"photoprofil" })).unwrap();
      setProfileImage(null)
      toast.success(message);
      onRequestClose();
    } catch (error) {
      //console.error("Failed to delete photo:", error);
      toast.error("Failed to delete photo.");
    } finally {
      setIsDeleteLoading(false);
    }
  };

  // Reset modal state when it's closed
  useEffect(() => {
    if (!isOpen) {
      setIsCameraOpen(false);
      setPhoto(null);
      setScale(1);  // Reset the scale as well
    }
  }, [isOpen]);

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      style={customStyles}
    >
      <div className="relative bg-white shadow">
        <button
          type="button"
          className="absolute top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center"
          onClick={onRequestClose}
        >
          <svg
            aria-hidden="true"
            className="w-5 h-5"
            fill="#c6c7c7"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
              clipRule="evenodd"
            ></path>
          </svg>
          <span className="sr-only">Close popup</span>
        </button>

        <div className="p-10">
          <h2 className="text-2xl font-semibold mb-4 text-center">Change Profile Picture</h2>
          <p className="text-center mb-6">Choose an option to change your profile picture.</p>
          
          {!photo && !isCameraOpen && (
            <>
              {/* Only show "Take a Photo" button on non-mobile devices */}
              {!isMobile && (
                <button className="w-full bg-blue-500 text-white p-2 rounded mb-4" onClick={openCamera}>
                  Take a Photo
                </button>
              )}
              
              <Dropzone onDrop={handleFileChange}>
                {({ getRootProps, getInputProps }) => (
                  <div {...getRootProps()} className="w-full bg-blue-500 text-white p-2 rounded mb-4 text-center cursor-pointer">
                    <input {...getInputProps()} />
                    Choose Photo
                  </div>
                )}
              </Dropzone>
               {/* Delete Photo Button */}
               
               {/* Conditionally render Delete Photo button */}
              {photoUrl && photoUrl !== defaultImageUrl && (
                <Button
                  onClick={handleDeletePhoto}
                  size="medium"
                  variant="filled"
                  className="w-full bg-red-500 text-white p-2 rounded mb-4"
                  disabled={isDeleteLoading}
                >
                  {isDeleteLoading ? (
                    <Oval
                      height={20}
                      width={20}
                      color="#FFFFFF"
                      wrapperStyle={{}}
                      visible={true}
                      ariaLabel='oval-loading'
                      secondaryColor="#FFFFFF"
                      strokeWidth={7}
                      strokeWidthSecondary={7}
                    />
                  ) : (
                    "Delete Photo"
                  )}
                </Button>
              )}
            </>
          )}

          {isCameraOpen && (
            <>
              <Webcam
                audio={false}
                ref={webcamRef}
                screenshotFormat="image/jpeg"
                className="w-full mb-4"
              />
              <button className="w-full bg-green-500 text-white p-2 rounded mb-4" onClick={takePhoto}>
                Capture Photo
              </button>
              <button className="w-full bg-gray-500 text-white p-2 rounded mb-4" onClick={closeCamera}>
                Back to Options
              </button>
            </>
          )}

          {photo && (
            <>
              <AvatarEditor
                ref={editorRef}
                image={photo}
                width={250}
                height={250}
                border={50}
                borderRadius={125} // Circular cropping
                scale={scale}
                rotate={0}
                className="mb-4"
              />
              <input
                type="range"
                min="1"
                max="2"
                step="0.01"
                value={scale}
                onChange={(e) => setScale(parseFloat(e.target.value))}
                className="w-full mb-4"
              />
              <Button
                onClick={saveCroppedPhoto}
                size="medium"
                variant="filled"
                className="w-full bg-green-500 text-white p-2 rounded mb-4"
                disabled={isSaveLoading}
              >
                {isSaveLoading ? (
                  <Oval
                    height={20}
                    width={20}
                    color="#FFFFFF"
                    wrapperStyle={{}}
                    visible={true}
                    ariaLabel='oval-loading'
                    secondaryColor="#FFFFFF"
                    strokeWidth={7}
                    strokeWidthSecondary={7}
                  />
                ) : (
                  "Save Photo"
                )}
              </Button>
              <button className="w-full bg-gray-500 text-white p-2 rounded mb-4" onClick={() => setPhoto(null)}>
                Back to Options
              </button>
            </>
          )}
        </div>
      </div>
    </Modal>
  );
};

export default ChangePhotoModal;
