import React, { useState, useEffect, useRef, act } from 'react';
import Button from '../../components/Buttons/Button';
import { Oval } from 'react-loader-spinner'; 
import { editUserData, deleteUser } from '../../api/userService';
import toast from 'react-hot-toast';
import { useDispatch, useSelector } from 'react-redux';
import { fetchUserData, updateUser, toggleHide } from '../../redux/features/userSlice';
import { Helmet } from 'react-helmet';
import { bannedWordsRegex } from '../../utils/bannedWords';
import { ExclamationCircleIcon } from '@heroicons/react/24/outline'; // Import Heroicons
import predefinedTags from '../../data/tags.json';
import { genderOptions, orientationOptions } from '../../data/enum';
import Post from '../../components/HomeComponents/Post';
import { getAvailablePhotosAPI } from '../../api/storagePhoto';
import { topUpThunk, firePinThunk } from '../../redux/features/userSlice';
import Modal from 'react-modal';
import { resetUserState, logoutUser, deleteAccount } from '../../redux/features/userSlice';
import { resetPhotoState } from '../../redux/features/photoSlice';
import { useNavigate, useLocation } from 'react-router-dom'; // Import useLocation
import SEO from '../../utils/SEO';

Modal.setAppElement('#root');

const ProfileInformation = () => {

  const navigate = useNavigate()

  const isFieldMissing = (field) => missingFields.includes(field);
  const [topUp, setTopUp] = useState(100);
  const [firePin, setFirePin] = useState(100);
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user.user);
  const photo = useSelector(state => state.photos.photos.photoprofil);
  const [showAlert, setShowAlert] = useState(false);
  
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalAction, setModalAction] = useState(null);
  const [isFormChanged, setIsFormChanged] = useState(false); // Nouvel état pour suivre les changements
  const [tags, setTags] = useState([]);
  const [tagInput, setTagInput] = useState('');
  const [filteredTags, setFilteredTags] = useState([]);
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [message, setMessage] = useState({ text: '', color: '' });

  const [isTagListVisible, setIsTagListVisible] = useState(false);
  const tagInputRef = useRef(null); // Référence pour le champ d'entrée des tags
  const tagListRef = useRef(null); // Référence pour la liste déroulante des tags

  const openConfirmationModal = (action) => {
    if (missingFields.length > 0 && action !== 'deleteAccount') {
      setShowAlert(true);
      toast.error("There are missings fields in your profile.");
      return;
    }

    if (action === 'topUp' && topUp <= 0) {
      toast.error("Insufficient top-up balance.");
      return;
    }

    if (action === 'firePin' && firePin <= 0) {
      toast.error("Insufficient FirePin balance.");
      return;
    }


    setModalAction(action);
    setIsModalOpen(true);
  };

  const closeModal = () => setIsModalOpen(false);

  const handleConfirmation = async () => {
    setIsLoading(true);
    try {
      if (modalAction === 'topUp') {
        await dispatch(topUpThunk()).unwrap();
        //await handleRefresh();
        toast.success('Top up used successfully! Refresh your page to see the effect.');
      } else if (modalAction === 'firePin') {
        await dispatch(firePinThunk()).unwrap();
        //await handleRefresh();
        toast.success('Fire pin used successfully! Wait a few seconds for the effect to take place.');
      } else if(modalAction === 'deleteAccount') {
        await handleDeleteAccount();
      }
    } catch (error) {
      //console.error("Action failed", error);
      toast.error(error.data.message);
    } finally {
      setIsLoading(false);
      closeModal();
    }
  };

  const handleDeleteAccount = async () => {
    setIsLoading(true);
    try {
      dispatch(deleteAccount()); // Dispatch the thunk
      dispatch(resetUserState()); // This will set isAuthenticated to false in the Redux state
      dispatch(logoutUser());
      dispatch(resetPhotoState());
      navigate('/'); // Navigate to the home page
      toast.success('Logged out successfully!');
    } catch (error) {
      //console.error("Failed to delete account:", error);
      toast.error('Failed to delete account.');
    } finally {
      setIsLoading(false);
    }
  };


  // Function to set a success message
  const setSuccessMessage = (text) => {
    setMessage({ text, color: 'green' });
  };

  // Function to set an error message
  const setErrorMessage = (text) => {
    setMessage({ text, color: 'red' });
  };


  const [isTogglingHide, setIsTogglingHide] = useState(false); // New state for toggling hide
  const [missingFields, setMissingFields] = useState([]);

  const initialData = {
    username: user.username || '',
    gender: user.gender || '',
    age: user.age || null,
    orientation: user.orientation || '',
    country: user.country || 'Unknown',
    description: user.description || '',
    insta_username: user.insta_username || '',
    twitter_username: user.twitter_username || '',
    of_username: user.of_username || '',
  };
  const [formData, setFormData] = useState(initialData);
  const [availablePhotos, setAvailablePhotos] = useState([]);
  

  const checkForBannedWords = (text) => {
    // Vérifie si le texte contient un mot interdit
    const matchedWord = bannedWordsRegex.find((regex) => regex.test(text));
    if (matchedWord) {
      //console.log('Banned word detected:', matchedWord);
      return true;
    }
    return false;
  };

  useEffect(() => {
    const fetchAvailablePhotos = async () => {
      try {
        // Appel à l'API pour obtenir les photos disponibles
        const photosData = await getAvailablePhotosAPI();
  
        if (!photosData) {
          console.error("No data returned from API");
          setAvailablePhotos([]);
          return;
        }
        // Transforme les données en tableau et filtre les photos (exclut 'photoprofil')
        const photosArray = Object.entries(photosData || {})
          .filter(([photoId]) => photoId !== 'photoprofil')
          .map(([photoId, photoUrl]) => ({
            photoId,
            photoUrl,
          }));
  
        setAvailablePhotos(photosArray); // Stocke le tableau des photos
      } catch (error) {
        console.error("Error fetching available photos:", error);
        toast.error("Failed to load photos.");
        setAvailablePhotos([]); // Par sécurité, initialisez un tableau vide

      }
    };
  
    fetchAvailablePhotos();
  }, []);

  useEffect( () => {
    
    //console.log('User:', user);
    if (!user || JSON.stringify(user) === '{}') {
      //console.log('User not found in store, fetching...');
      // Si l'utilisateur n'est pas dans le store, on le récupère via fetchUserData
       dispatch(fetchUserData());
    } else {
      // Si l'utilisateur est déjà dans le store, on initialise les données du formulaire
      setFormData({
        username: user.username || '',
        gender: user.gender || '',
        age: user.age || null,
        orientation: user.orientation || '',
        country: user.country || '',
        description: user.description || '',
        insta_username: user.insta_username || '',
        twitter_username: user.twitter_username || '',
        of_username: user.of_username || '',

      });
      setTags(user.tags || []);
    }
  }, [dispatch, user]);

  useEffect(() => {
    // Check for missing fields
    const requiredFields = ["username", "age", "gender", "orientation"];
    const missing = requiredFields.filter(field => !formData[field]);
    setMissingFields(missing);
  }, [formData]);


  useEffect(() => {
    const isEqual = (a, b) => JSON.stringify(a) === JSON.stringify(b);
    const formFieldsChanged = !isEqual(formData, initialData);
    const tagsChanged = !isEqual(tags, user.tags || []);
    setIsFormChanged(formFieldsChanged || tagsChanged);
  }, [formData, tags, initialData, user.tags]);

  const handleSaveChanges = async (event) => {
    event.preventDefault();

    if (!isFormChanged) {
      setMessage('No changes made.');
      toast.error("This didn't work.");
      return;
    }

    // Check for banned words in the form fields
    if (checkForBannedWords(formData.username) || checkForBannedWords(formData.description)) {
      toast.error('Please remove any inappropriate words.');
      return;
    }
    

    setIsLoading(true);
    setMessage('');
    
    try {

      const updatedData = {
        ...formData,
        age: formData.age ? parseInt(formData.age, 10) : null, // Convert 'age' to an integer or set it to null
        tags,
        instagram: formData.instagram || null,
        twitter: formData.twitter || null,
        onlyfans: formData.onlyfans || null,
      };

      //console.log('Updated data:', updatedData);
      await editUserData(updatedData); // Faites l'appel API
      //console.log('Changes saved:', response);
      dispatch(updateUser(updatedData));

      setSuccessMessage('Your changes have been saved successfully!');
      toast.success('Changes saved successfully!');
    } catch (error) {
        //console.error('Failed to save changes:', error);
        setErrorMessage('Failed to save changes : ' + error.response.data.message);
    } finally {
        setIsLoading(false); // Le loader continue tant que l'API n'a pas répondu
    }
  };

  const handleInputChange = (e) => {
    const { id, value } = e.target;
    
      setFormData(prevState => ({
        ...prevState,
        [id]: id === 'age' ? parseInt(value, 10) : value // Convert the age to an integer
      }));
    
    
  };

  const handleTagInputChange = (e) => {
    const input = e.target.value;
    setTagInput(input);
    if (input) {
      const filtered = predefinedTags.filter(t => t.toLowerCase().includes(input.toLowerCase()));
      setFilteredTags(filtered);
    } else {
      setFilteredTags([]);
    }
    setError('');
  };

  const handleTagSelect = (selectedTag) => {
    if (tags.length >= 5) {
      setError('You can only select up to 5 tags.');
      return;
    }
    if (!tags.includes(selectedTag)) {
      setTags([...tags, selectedTag]);
      setTagInput('');
      setFilteredTags([]);
      setError(''); // Efface les erreurs précédentes
      setIsFormChanged(true); // Tag sélectionné, formulaire modifié
    } else {
      setError('This tag is already taken.');
    }
  };

  const handleTagRemove = (tagToRemove) => {
    setTags(tags.filter(tag => tag !== tagToRemove));
    setError('');
    setIsFormChanged(true); // Tag supprimé, formulaire modifié
  };

  const handleTagInputFocus = () => {  // Affiche tous les tags disponibles non sélectionnés lors du clic
    setFilteredTags(predefinedTags.filter((tag) => !tags.includes(tag)));
    setIsTagListVisible(true); // Affiche la liste des tags
  };

  const handleOutsideClick = (event) => {
    if (
      tagInputRef.current &&
      !tagInputRef.current.contains(event.target) &&
      tagListRef.current &&
      !tagListRef.current.contains(event.target)
    ) {
      setIsTagListVisible(false); // Ferme la liste des tags si le clic est à l'extérieur
    }
  };

  useEffect(() => {
    if (isTagListVisible) {
      document.addEventListener('mousedown', handleOutsideClick);
    } else {
      document.removeEventListener('mousedown', handleOutsideClick);
    }

    // Cleanup listener on unmount
    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, [isTagListVisible]);

  // Function to toggle user.hide
  const handleToggleHide = async () => {
    setIsTogglingHide(true);
  
    try {
      const response = await dispatch(toggleHide()).unwrap(); // Dispatch the thunk
      dispatch(updateUser({ ...user, hide: response.hide })); // Update the Redux store with the new hide status
  
      toast.success(`Profile visibility ${response.hide ? 'hidden' : 'visible'} successfully.`);
    } catch (error) {
      //console.error('Failed to toggle hide:', error);
      toast.error('Failed to update profile visibility.');
    } finally {
      setIsTogglingHide(false);
    }
  };


  return (
    <div className="p-6 bg-white shadow-md rounded-b-lg">
      
            <SEO 
              title="Profile Information - Havenude" 
              description="Login and update your profile information. Share and post your hot content with the world." 
              name="HaveNude" 
              type="website"
            />
      {/* Alert Banner for Missing Fields */}
      {missingFields.length > 0 && (
        <div className="p-4 mb-6 bg-yellow-50 text-yellow-800 border border-yellow-500 rounded-md">
          <p className="text-sm font-semibold">
            To fully unlock your account ,Please fill in the following required information: {missingFields.join(", ")}.
          </p>
        </div>
      )}

      

      <h2 className="text-2xl font-semibold mb-6">Personal Information</h2>

      <form onSubmit={handleSaveChanges}>
        {/* Email Field */}
        <div className="relative mb-6 md:w-1/2">
          <div className="flex items-center relative">
            <input
              type="text"
              id="email"
              className="w-full cursor-not-allowed p-2 border border-gray-300 text-gray-500 bg-gray-100 rounded focus:outline-none"
              value={user.email || "No email provided"}
              readOnly // Makes the input read-only
            />
          </div>
          <label
            htmlFor="email"
            className="absolute text-gray-600 transform -translate-y-6 scale-75 top-3 left-3 z-10 bg-white px-1 transition-all"
          >
            Email
          </label>
        </div>

        
          <>
            {/* Instagram Username */}
            <div className="relative mb-6 md:w-1/2">
              <div className="flex items-center relative">
                <input
                  type="text"
                  id="insta_username"
                  className="w-full cursor-text p-2 border border-gray-300 rounded focus:outline-none"
                  value={formData.insta_username || ""}
                  onChange={handleInputChange}
                  placeholder=" "
                  maxLength="30"
                />
              </div>
              <label
                htmlFor="insta_username"
                className="absolute text-gray-600 transform -translate-y-6 scale-75 top-3 left-3 z-10 bg-white px-1 transition-all"
              >
                Instagram Username (bonus vip)
              </label>
            </div>

            {/* Twitter Username */}
            <div className="relative mb-6 md:w-1/2">
              <div className="flex items-center relative">
                <input
                  type="text"
                  id="twitter_username"
                  className="w-full cursor-text p-2 border border-gray-300 rounded focus:outline-none"
                  value={formData.twitter_username || ""}
                  onChange={handleInputChange}
                  placeholder=" "
                  maxLength="30"
                />
              </div>
              <label
                htmlFor="twitter_username"
                className="absolute text-gray-600 transform -translate-y-6 scale-75 top-3 left-3 z-10 bg-white px-1 transition-all"
              >
                Twitter Username (bonus vip)
              </label>
            </div>

            {/* OnlyFans Username */}
            <div className="relative mb-6 md:w-1/2">
              <div className="flex items-center relative">
                <input
                  type="text"
                  id="of_username"
                  className="w-full cursor-text p-2 border border-gray-300 rounded focus:outline-none"
                  value={formData.of_username || ""}
                  onChange={handleInputChange}
                  placeholder=" "
                  maxLength="30"
                />
              </div>
              <label
                htmlFor="of_username"
                className="absolute text-gray-600 transform -translate-y-6 scale-75 top-3 left-3 z-10 bg-white px-1 transition-all"
              >
                OnlyFans Username (bonus vip)
              </label>
            </div>
          </>
        


        {/* Username */}
        <div className="relative mb-6 md:w-1/2">
          <div className="flex items-center relative">
            <input
              type="text"
              id="username"
              className={`w-full cursor-text p-2 border rounded focus:outline-none ${
                isFieldMissing('username') ? 'border-yellow-500' : 'border-gray-300'
              }`}
              value={formData.username}
              onChange={handleInputChange}
              maxLength="20"
              placeholder=" "
            />
            {isFieldMissing('username') && (
              <ExclamationCircleIcon className="w-5 h-5 text-yellow-500 absolute right-2 mr-7" />
            )}
          </div>
          <label
            htmlFor="username"
            className="absolute text-gray-600 transform -translate-y-6 scale-75 top-3 left-3 z-10 bg-white px-1 transition-all"
          >
            Username (relie to your snap)
          </label>
        </div>

        {/* Gender */}
        <div className="relative mb-6 md:w-1/2">
          <div className="flex items-center relative">
            <select
              id="gender"
              className={`w-full cursor-pointer p-2 border rounded focus:outline-none ${
                isFieldMissing('gender') ? 'border-yellow-500' : 'border-gray-300'
              }`}
              value={formData.gender || ""}
              onChange={handleInputChange}
            >
              {genderOptions.map((option, index) => (
                <option key={index} value={option}>{option}</option>
              ))}
            </select>
            {isFieldMissing('gender') && (
              <ExclamationCircleIcon className="w-5 h-5 text-yellow-500 absolute right-2 mr-7" />
            )}
          </div>
          <label
            htmlFor="gender"
            className="absolute text-gray-600 transform -translate-y-6 scale-75 top-3 left-3 z-10 bg-white px-1 transition-all"
          >
            Gender (required)
          </label>
        </div>

        {/* Orientation */}
        <div className="relative mb-6 md:w-1/2">
          <div className="flex items-center relative">
            <select
              id="orientation"
              className={`w-full cursor-pointer p-2 border rounded focus:outline-none ${
                isFieldMissing('orientation') ? 'border-yellow-500' : 'border-gray-300'
              }`}
              value={formData.orientation || ""}
              onChange={handleInputChange}
            >
              {orientationOptions.map((option, index) => (
                <option key={index} value={option}>{option}</option>
              ))}
            </select>
            {isFieldMissing('orientation') && (
              <ExclamationCircleIcon className="w-5 h-5 text-yellow-500 absolute right-2 mr-7" />
            )}
          </div>
          <label
            htmlFor="orientation"
            className="absolute text-gray-600 transform -translate-y-6 scale-75 top-3 left-3 z-10 bg-white px-1 transition-all"
          >
            Orientation (required)
          </label>
        </div>

        {/* Age */}
        <div className="relative mb-6 md:w-1/2">
          <div className="flex items-center relative">
            <select
              id="age"
              className={`w-full cursor-pointer p-2 border rounded focus:outline-none ${
                isFieldMissing('age') ? 'border-yellow-500' : 'border-gray-300'
              }`}
              value={formData.age || ""}
              onChange={handleInputChange}
            >
              <option value="">Select Age</option>
              {/* Générer les options de 18 à 80 */}
              {Array.from({ length: 63 }, (_, i) => 18 + i).map((age) => (
                <option key={age} value={age}>
                  {age}
                </option>
              ))}
            </select>
            {isFieldMissing('age') && (
              <ExclamationCircleIcon className="w-5 h-5 text-yellow-500 absolute right-2 mr-7" />
            )}
          </div>
          <label
            htmlFor="age"
            className="absolute text-gray-600 transform -translate-y-6 scale-75 top-3 left-3 z-10 bg-white px-1 transition-all"
          >
            Age (required)
          </label>
        </div>
        
        <div className="relative mb-6">
          <textarea
            id="description"
            className="w-full cursor-text p-2 border border-gray-300 text-gray-400 focus:text-black rounded focus:outline-none focus:border-[#AC3B61]"
            rows="4"
            value={formData.description}
            onChange={handleInputChange}
            maxLength="200" // Set max length for username
            placeholder=" "
          />
        
          <label htmlFor="description" className="absolute text-gray-600 transform -translate-y-6 scale-75 top-3 left-3 z-10 origin-[0] bg-white px-1 peer-focus:px-1 peer-focus:text-[#AC3B61] peer-focus:top-2 peer-focus:scale-75 peer-placeholder-shown:scale-100 peer-placeholder-shown:top-5 peer-placeholder-shown:bg-transparent transition-all">
            Description
          </label>
        </div>

        {/* Tags */}
        <div className="relative mb-6">
          <div className="flex items-center gap-4">
            <label className="text-gray-700 font-semibold">Tags</label>
            <p className="text-sm text-gray-600">{tags.length}/5 tags selected</p>
          </div>

          <div className="flex flex-wrap gap-2 mb-2">
            {tags.map((tag, index) => (
              <div key={index} className="flex items-center bg-gray-200 rounded-sm px-3 py-1">
                <span className="mr-2">{tag}</span>
                <button
                  type="button"
                  className="text-gray-500 hover:text-gray-700"
                  onClick={() => handleTagRemove(tag)}
                >
                  &times;
                </button>
              </div>
            ))}
          </div>

          {/* Input pour ajouter un tag */}
          <input
            ref={tagInputRef} // Attachez la référence à l'input
            type="text"
            className="w-full p-2 border border-gray-300 rounded focus:outline-none focus:border-[#AC3B61]"
            placeholder="Add tag"
            value={tagInput}
            onChange={handleTagInputChange}
            onFocus={handleTagInputFocus} // Affiche la liste au focus
            disabled={tags.length >= 5} // Désactive l'entrée si 5 tags sont sélectionnés
          />
          {error && <p className="text-red-500 mt-1">{error}</p>}

          {/* Liste déroulante des tags */}
          {isTagListVisible && filteredTags.length > 0 && (
            <ul
              ref={tagListRef} // Attachez la référence à la liste
              className="absolute bg-white border border-gray-300 rounded w-full mt-1 max-h-40 overflow-y-auto z-10"
            >
              {filteredTags.map((t, index) => (
                <li
                  key={index}
                  className="p-2 hover:bg-[#AC3B61] hover:text-white cursor-pointer"
                  onClick={() => handleTagSelect(t)}
                >
                  {t}
                </li>
              ))}
            </ul>
          )}
        </div>

        {message.text && (
          <p
            className={`text-center mb-4 ${
              message.color === 'red'
                ? 'text-red-500 bg-red-100 border border-red-500 rounded-md p-2'
                : 'text-green-500 bg-green-100 border border-green-500 rounded-md p-2'
            }`}
          >
            {message.text}
          </p>
        )}

        <h2 className="text-2xl font-semibold mb-6">Hide Profile</h2>

        <label
          className="flex items-center cursor-pointer mt-10 sm:mt-0 mb-10"
        >
          <div className="relative flex items-center">
            <input
              type="checkbox"
              className="sr-only"
              checked={user.hide}
              onChange={handleToggleHide} // Toggle hide on change
              disabled={isTogglingHide} // Disable while toggling
            />
            <div
              className={`block w-10 h-5 sm:w-12 sm:h-6 rounded-full transition-colors duration-300 ${
                user.hide ? 'bg-blue-500' : 'bg-gray-300'
              } ${isTogglingHide ? 'opacity-50' : ''}`} // Add transparency when loading
            ></div>
            <div
              className={`dot absolute left-1 top-0.5 w-4 sm:w-5 h-4 sm:h-5 rounded-full bg-white transition-transform duration-300 ${
                user.hide ? 'transform translate-x-5 sm:translate-x-6' : ''
              }`}
            ></div>
          </div>
          <span className="ml-2 sm:ml-3 text-gray-700 font-medium text-sm">
            {user.hide ? 'Toggle to make visible your posts' : 'Toggle to Hide your posts'}
          </span>
        </label>

        <Button
            onClick={handleSaveChanges}
            size="medium"
            variant="filled"
            className="relative flex items-center"
            rounded='threexl'
            disabled={!isFormChanged || isLoading} // Désactive le bouton si aucune modification n'a été faite ou si le formulaire est en cours de chargement
          >
            {isLoading && (
              <Oval
                height={20}
                width={20}
                color="#FFFFFF"
                wrapperStyle={{}}
                wrapperClass="mr-2"
                visible={true}
                ariaLabel='oval-loading'
                secondaryColor="#FFFFFF"
                strokeWidth={7}
                strokeWidthSecondary={7}
              />
            )}
            Save Information
          </Button>

        
      </form>

      <h2 className="text-lg font-semibold mb-4 text-gray-700 text-center mt-2">Visualize your Post</h2>

      <div className="border border-gray-200 rounded-md max-w-md mx-auto m-10">
        <Post
          author={{
            id: user?.user_id || '',
            name: formData.username || '',
            image: photo || '',
          }}
          sexe={formData.gender}
          orientation={formData.orientation}
          age={formData.age}
          images={availablePhotos.map(photo => photo.photoUrl || '' )}
          description={formData.description || ''}
          isVerified={formData.isVerified}
          tier={formData.tier}
          last_top_up="exemple"
          country={formData.country}
          tags={tags}
          firePinExpiresAt={formData.firePinExpiresAt}
          onProfileClick={() => {
            console.log('Navigate to profile with ID:', user);
          }}
        />
      </div>

      <div className="flex justify-center mt-10">
        <Button
          onClick={()=> openConfirmationModal('topUp')}
          size="medium"
          variant="filled"
          className="relative flex items-center"
        >
          
          Make a Post
        </Button>

        <Button
          onClick={()=> openConfirmationModal('firePin')}
          size="medium"
          variant="filled"
          className="relative flex items-center ml-5"
        >
          
          Use a Fire Pin
        </Button>
      </div>

      <Button
            onClick={()=> openConfirmationModal('deleteAccount')}
            size="medium"
            variant="filled"
            className="relative flex items-center bg-red-500"
            rounded='threexl'
          >
           
            Delete Account
          </Button>

      <Modal
        isOpen={isModalOpen}
        onRequestClose={closeModal}
        contentLabel="Confirmation Modal"
        className="modal-content"
        overlayClassName="modal-overlay"
      >
        <h2 className="text-lg font-semibold mb-4">Confirm Action</h2>
        <p className="text-gray-700 mb-6">
          {modalAction === 'topUp' 
            && "Are you sure you want to use a top up? This action will decrease your top-up balance." 
            
          }
          {
            modalAction === 'firePin' && "Are you sure you want to use a FirePin? This action will make your profile stand out for a limited time."
          }
          {
            modalAction === 'deleteAccount' && "Are you sure you want to delete your account? This action cannot be undone."
          }
        </p>
        <div className="flex justify-end space-x-4">
          <button onClick={closeModal} className="px-4 py-2 bg-gray-300 rounded">Cancel</button>
          <button onClick={handleConfirmation} className="px-4 py-2 bg-blue-600 text-white rounded">
            {isLoading ? "Processing..." : "Confirm"}
          </button>
        </div>
      </Modal>
    </div>
  );
};

export default ProfileInformation;
