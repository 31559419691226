import React, { useState, useEffect, useRef } from 'react';
import { FaBars } from 'react-icons/fa';
import { Link, NavLink } from 'react-router-dom';
import ProfileDropdown from '../Dropdown/ProfileDropdown';
import Button from '../Buttons/Button';
import LoginModal from '../Modal/LoginModal';
import SignupModal from '../Modal/SignUpModal';
import ResetPasswordModal from '../Modal/ResetPasswordModal';
import { useSelector, useDispatch } from 'react-redux';
import { checkProfileImage } from '../../utils/profileImageService';
import logo from '../../assets/logo.png';
import blankProfilePicture from "../../assets/blank-profile-picture.png"

const Navbar = ({ toggleSidebar }) => {
  const { getStatus } = useSelector((state) => state.photos);

  const dispatch = useDispatch(); 

  const isAuthenticated = useSelector(state => state.user.isAuthenticated);
  const profileImageRedux = useSelector(state => state.photos.photos.photoprofil);
  //const userId = useSelector(state => state.user.userId);

  const username = useSelector((state) => state.user.user?.username);

  const [dropdown, setDropdown] = useState(null);
  const [isScrolled, setIsScrolled] = useState(false);
  const [profileDropdown, setProfileDropdown] = useState(false);
  const [profileImage, setProfileImage] = useState(profileImageRedux);

  const navRef = useRef();
  const profileRef = useRef();

  const [isLoginModalOpen, setLoginModalOpen] = useState(false);
  const [isSignupModalOpen, setSignupModalOpen] = useState(false);
  const [isResetPasswordModalOpen, setResetPasswordModalOpen] = useState(false);

  const openLoginModal = () => setLoginModalOpen(true);
  const closeLoginModal = () => setLoginModalOpen(false);
  const openSignupModal = () => setSignupModalOpen(true);
  const closeSignupModal = () => setSignupModalOpen(false);
  const openResetPasswordModal = () => setResetPasswordModalOpen(true);
  const closeResetPasswordModal = () => setResetPasswordModalOpen(false);

  const navigateToLogin = () => openLoginModal();
  const navigateToSignUp = () => openSignupModal();

  const handleProfileClick = () => {
    setProfileDropdown(!profileDropdown);
    setDropdown(null);
  };

  const handleClickOutside = (event) => {
    if (!navRef.current.contains(event.target)) {
      setDropdown(null);
      setProfileDropdown(false);
    }
  };

  const handleScroll = () => {
    if (window.scrollY > 50) {
      setIsScrolled(true);
    } else {
      setIsScrolled(false);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    if (isLoginModalOpen || isSignupModalOpen || isResetPasswordModalOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, [isLoginModalOpen, isSignupModalOpen, isResetPasswordModalOpen]);

  useEffect(() => {
    const fetchProfileImage = async () => {
      if (isAuthenticated ) {
        //console.log("Vérification de la photo de profil pour l'utilisateur quand je suis connecté en fait ??:", userId);
        await checkProfileImage(profileImageRedux, setProfileImage, dispatch);
      } else if (!isAuthenticated) {
        //console.log("pas co la donc photo de base")
        setProfileImage(blankProfilePicture);
      }
    };
  
    fetchProfileImage();
  
  }, [isAuthenticated]);

  return (
    <nav
      ref={navRef}
      className={`p-3 flex justify-between items-center fixed top-0 left-0 w-full z-40 transition-colors duration-300 ${
        isScrolled ? 'bg-slate-100 bg-opacity-90 ' : 'bg-white'
      }`}

    >
      {/* Logo and Brand Name */}
      <div className="flex items-center space-x-4">
        <Link to="/" className="text-xl font-semibold text-[#AC3B61] pl-2 md:pl-10">
          HaveNude
        </Link>
        <img src={logo} alt="Logo" className="h-10" />
      </div>


      {/* Main Menu */}
{getStatus === 'pending' ? null : (
  <div className="hidden lg:flex space-x-10">
    <NavLink
      to="/"
      className={({ isActive }) =>
        `text-gray-600  px-3 py-1 rounded-lg transition-all ${
          isActive ? 'bg-[#c16282] text-white' : ''
        }`
      }
    >
      Home
    </NavLink>
    
    <NavLink
      to="/contact"
      className={({ isActive }) =>
        `text-gray-600 px-3 py-1 rounded-lg transition-all ${
          isActive ? 'bg-[#c16282] text-white' : ''
        }`
      }
    >
      Contact
    </NavLink>
    <NavLink
      to="/about-us"
      className={({ isActive }) =>
        `text-gray-600 px-3 py-1 rounded-lg transition-all ${
          isActive ? 'bg-[#c16282] text-white' : ''
        }`
      }
    >
      About Us
    </NavLink>
  </div>
)}

      

      {/* Right Side: Profile and Authentication */}
      <div className="lg:flex space-x-4 items-center hidden mr-5">
        {isAuthenticated ? (
          <div ref={profileRef} className="relative flex items-center space-x-2">
            {getStatus !== 'succeeded' ? (
              // Loader Circle when loading
              <div className="w-10 h-10 rounded-full bg-gray-300 animate-pulse"></div>
            ) : (
              <div
                className="flex items-center space-x-2 cursor-pointer hover:opacity-40 transition-opacity duration-300"
                onClick={handleProfileClick}  // Action lors du clic
              >
                <img
                  src={profileImage}
                  alt="Profile"
                  className="h-10 w-10 rounded-full"
                />
                <span className="text-gray-700 font-medium">
                  {username}
                </span>
              </div>
            )}
            <ProfileDropdown
              isOpen={profileDropdown}
              closeDropdown={() => setProfileDropdown(false)}
            />
          </div>
        ) : (
          <>
            <Button
              onClick={navigateToLogin}
              variant={'outline'}
              size="medium"
              rounded="threexl"
              className="w-full mt-2"
            >
              Log In
            </Button>
            <Button
              onClick={navigateToSignUp}
              variant={'outline'}
              className="w-full mt-2"
              rounded="threexl"
              size="medium"
            >
              Register
            </Button>
          </>
        )}
      </div>


      {/* Hamburger Menu for Mobile */}
      <div className="lg:hidden flex items-center space-x-2">
        <div ref={profileRef} className="relative mr-5">
          {getStatus !== 'succeeded' && isAuthenticated ? (
            <div className="h-12 w-12 rounded-full bg-gray-300 animate-pulse"></div>
          ) : (
            <img
              src={profileImage}
              alt="Profile"
              className="h-12 w-12 rounded-full cursor-pointer mr-3 hover:opacity-40 transition-opacity duration-300"
              onClick={handleProfileClick}
            />
          )}
          <ProfileDropdown
            isOpen={profileDropdown}
            closeDropdown={() => setProfileDropdown(false)}
          />
        </div>
        <button onClick={toggleSidebar} className="text-gray-600 focus:outline-none pr-5">
          <FaBars className="h-6 w-6" />
        </button>
      </div>

      {/* Modals */}
      <LoginModal isOpen={isLoginModalOpen} onRequestClose={closeLoginModal} openSignupModal={openSignupModal} openResetPasswordModal={openResetPasswordModal} />
      <SignupModal isOpen={isSignupModalOpen} onRequestClose={closeSignupModal} openLoginModal={openLoginModal} />
      <ResetPasswordModal isOpen={isResetPasswordModalOpen} onRequestClose={closeResetPasswordModal} openLoginModal={openLoginModal}  />
    </nav>
  );
};

export default Navbar;
