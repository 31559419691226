// src/api/userService.js
import apiClient from './apiClient';

const token = localStorage.getItem('idToken');

export const getUserData = async () => {
    try {
        const response = await apiClient.get('/user/getuserdata');
        //console.log('API Response in userService get user:', response); 
        return response.data;
    } catch (error) {
        //console.error('Error fetching user data', error);
        throw error;
    }
};

export const editUserData = async (userData) => {
    try {
        //console.log('User token:', userData);
        const response = await apiClient.post('/user/edit', userData);
        //console.log('API Response in userService edit user:', response); 
        return response.data;
    } catch (error) {
        //console.error('Error editing user data', error);
        throw error;
    }
};

// Service function for toggling user hide status
export const hideUser = async () => {
    try {
      const response = await apiClient.post('/user/toggle-hide');
      //console.log('API Response in userService:', response);
      return response.data;
    } catch (error) {
      //console.error('Error toggling user hide status', error);
      throw error;
    }
  };


export const topUpUser = async () => {
    try {
        const response = await apiClient.post('/user/topup');
        //console.log('API Response in userService topup :', response); 
        return response.data;
    } catch (error) {
        //console.error('Error using top up', error);
        throw error;
    }
};

export const firePinUser = async () => {
    try {
        const response = await apiClient.post('/user/firepin');
        //console.log('API Response in userService firepin :', response); 
        return response.data;
    } catch (error) {
        //console.error('Error using fire pin', error);
        throw error;
    }
};

export const reportUser = async (postUserId) => {
    try {
        const response = await apiClient.post('/user/report_user', {
            post_user_id: postUserId, // Inclure l'ID de l'utilisateur du post
        });
        //console.log('API Response in userService report user:', response); 
        return response.data;
    } catch (error) {
        //console.error('Error using reportUser', error);
        throw error;
    }
};


export const deleteUser = async () => {
    try {
        const response = await apiClient.delete('/user/delete_user');
        console.log('API Response in userService delete user:', response); 
        return response.data;
    } catch (error) {
        console.error('Error deleting user', error);
        throw error;
    }
}