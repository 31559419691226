import React, { useEffect, useState } from 'react';
import { useLocation, Link } from 'react-router-dom';
import { checkoutSuccess } from '../../api/stripeService';
import Button from '../../components/Buttons/Button';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';

const CheckoutResult = () => {
  const location = useLocation();
  const [sessionData, setSessionData] = useState(null);
  const [status, setStatus] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true); // New loading state


  useEffect(() => {
    const fetchSessionData = async (sessionId) => {
      try {
        const response = await checkoutSuccess(sessionId);
        setSessionData(response.data.data);
        setStatus('success');
      } catch (error) {
        setStatus('canceled');
        setErrorMessage(error.response?.data?.detail || 'An error occurred while retrieving your order.');
      } finally {
        setLoading(false); // Stop loading regardless of success or error
      }
    };

    const queryParams = new URLSearchParams(location.search);
    const paymentStatus = queryParams.get('status');
    const sessionId = queryParams.get('session_id');

    if (paymentStatus === 'success' && sessionId) {
      fetchSessionData(sessionId);
    } else {
      setStatus('canceled');
      setLoading(false); // Stop loading if session ID is missing
    }
  }, [location]);

  if (loading) {
    return (
      <div className="flex flex-col items-center justify-center min-h-screen bg-gray-100">
        <div className="spinner-border animate-spin inline-block w-8 h-8 border-4 rounded-full" role="status">
          <span className="visually-hidden">.</span>
        </div>
        <p className="mt-4 text-gray-500">Loading your checkout details...</p>
      </div>
    );
  }

  return (
    <div className="flex flex-col">
      <Helmet>
        <title>Checkout After Payment - Havenude</title>
        <meta name="description" content="Checkout After Payment - Have Nude" />
        <meta property="og:title" content="Havenude" />
        <meta property="og:description" content="Checkout After Payment - Have Nude." />
        {/* Ajouter d'autres balises SEO ou Open Graph ici */}
      </Helmet>
      {/* Section with payment details and gray background */}
      <div className="bg-gray-100 flex-grow p-5">
        <div className="p-8 md:p-12 text-center max-w-2xl mx-auto">
          {status === 'success' ? (
            <div className="space-y-4">
              <div className="flex justify-center">
                <div className="bg-[#6aa860] text-white p-4 rounded-full">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-10 w-10"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      fillRule="evenodd"
                      d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.707a1 1 0 10-1.414-1.414L9 10.586 7.707 9.293a1 1 0 10-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                      clipRule="evenodd"
                    />
                  </svg>
                </div>
              </div>

              <h2 className="text-2xl font-bold text-gray-800 mb-1">Congratulations {sessionData.customer_name || 'N/A'}  You’ve got covered</h2>
              {sessionData && (
                <>
                  <p className="text-gray-700 font-medium mb-4">
                    Reference : <span className="font-bold">{sessionData.session_id || 'N/A'}</span>
                  </p>
                  
                  <p className="text-gray-700 mb-2">
                    Email: <span className="font-bold">{sessionData.customer_email || 'N/A'}</span>
                  </p>

                  <p className="text-gray-700 mb-2">
                    Payment Method: <span className="font-bold">{sessionData.payment_method || 'N/A'}</span>
                  </p>

                  <div className="border-b border-gray-300 mb-4" />

                  <div className="bg-gray-100 p-4 rounded-md border-slate-500 mb-4">
                    <h2 className="font-semibold text-lg mb-3">What about your payments ?</h2>
                    <div className="space-y-4">
                      <div className="flex justify-between items-center bg-white p-4 rounded-md shadow-sm">
                        <div>
                          <h3 className="font-bold">{sessionData.product_name }</h3>
                          <p className="text-gray-500 text-sm">
                            
                          </p>
                        </div>
                        <div className="text-right">
                          <p className="font-bold">
                            {sessionData.amount_paid} {sessionData.currency.toUpperCase()}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>

                  {sessionData.receipt_url && (
                    <a
                      href={sessionData.receipt_url}
                      className="inline-block bg-blue-600 text-white px-6 py-3 rounded-full font-semibold mb-4"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      View Receipt
                    </a>
                  )}

                  <Button
                    onClick={() => navigate("/shop")}
                    size="large"
                    variant="filled"
                    className="relative mt-5 sm:mt-0 bg-[#6aa860] hover:bg-[#8eb988]"
                    rounded="full"
                  >
                    Continue
                  </Button>
                </>
              )}
            </div>
          ) : (
            <div className="space-y-6">
              <h1 className="text-3xl font-bold text-red-600 mb-4">Payment Failed</h1>
              <p className="text-gray-700 mb-6">
                {errorMessage || 'Unfortunately, your payment could not be processed. Please try again.'}
              </p>
              <Link to="/shop" className="inline-block bg-red-500 text-white px-6 py-3 rounded-full font-semibold">
                Return to Shop
              </Link>
            </div>
          )}
        </div>
      </div>

      {/* Divider Line */}
      <div className="bg-white">
        <div className="flex items-center justify-center mt-12 mb-10 max-w-2xl mx-auto">
          <hr className="flex-grow border-t border-gray-300" />
          <span className="px-4 text-gray-500 font-semibold text-sm">What’s Next</span>
          <hr className="flex-grow border-t border-gray-300" />
        </div>
      </div>

      {/* Section with steps and white background */}
      {status === 'success' && (
        <div className="bg-white pb-20 text-center flex justify-center">
          <div className="flex flex-col md:flex-row items-center justify-center md:space-x-8">
            <div className="text-gray-700 text-sm flex flex-col items-center">
              <span className="font-bold">Step 1:</span>
              <span>Go to your profile</span>
            </div>
            <div className="text-gray-700 text-sm flex flex-col items-center justify-center">
              <span className="font-bold">Step 2:</span>
              <span>Check your subscription</span>
            </div>
            <div className="text-gray-700 text-sm flex flex-col items-center">
              <span className="font-bold">Step 3:</span>
              <span>Enjoy</span>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default CheckoutResult;