// firebase.js
import { initializeApp } from 'firebase/app';
import { getAuth, GoogleAuthProvider } from 'firebase/auth';

const environment = process.env.REACT_APP_ENV || 'local'; // 'local' sera la valeur par défaut si REACT_APP_ENV est undefined
let firebaseConfig;

if (environment === 'prod') {
  firebaseConfig = {
    apiKey: "AIzaSyA_JJAcKfmMPWbhb39K8ymPCZlfAHH0iJg",
    authDomain: "pansfinder-prod.firebaseapp.com",
    projectId: "pansfinder-prod",
    storageBucket: "pansfinder-prod.firebasestorage.app",
    messagingSenderId: "173420735998",
    appId: "1:173420735998:web:7ed13752e4f3826991a4af",
    measurementId: "G-9JJJ3VZ4Q0"
  };
}  // URL pour l'environnement de production
else  {
  firebaseConfig = {
    apiKey: "AIzaSyDvW4ExznXFX3J-rma_GseCvd5AMvc32ps",
    authDomain: "pansfinder-433014.firebaseapp.com",
    databaseURL: "https://pansfinder-433014-default-rtdb.europe-west1.firebasedatabase.app",
    projectId: "pansfinder-433014",
    storageBucket: "pansfinder-433014.appspot.com",
    messagingSenderId: "16562862977",
    appId: "1:16562862977:web:e5434176623c999ad9c680",
    measurementId: "G-2HLCH7HTME"
  };
}  // URL pour l'environnement de dev et local





// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const provider = new GoogleAuthProvider();

export { auth, provider }; // Export auth and provider
