import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
Modal.setAppElement('#root'); // Set root element for accessibility

const AgeVerificationPopup = ({ setIsAgeVerified }) => {
  const [isVisible, setIsVisible] = useState(false);

  // Custom styles for the modal
  const customStyles = {
    content: {
      position: 'none',
      background: 'white',
      outline: 'none',
      margin: 'auto',
      width: '90%',
      padding: '0',
      maxWidth: '500px',
      borderRadius: '0.5rem',
    },
    overlay: {
      backgroundColor: 'rgba(0, 0, 0, 0.9)',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      zIndex: 1000,
    },
  };

  useEffect(() => {
    const ageConfirmed = sessionStorage.getItem('ageConfirmed');
    if (!ageConfirmed) {
      setIsVisible(true);
      document.body.style.overflow = 'hidden';
    } else {
      setIsAgeVerified(true);
      document.body.style.overflow = 'unset';
    }
  }, [setIsAgeVerified]);

  const handleYesClick = () => {
    sessionStorage.setItem('ageConfirmed', 'true');
    setIsVisible(false);
    setIsAgeVerified(true);
  };

  const handleNoClick = () => {
    window.location.href = 'https://www.google.com';
  };

  return (
    <Modal
      isOpen={isVisible}
      onRequestClose={handleNoClick}
      contentLabel="Age Verification"
      shouldCloseOnOverlayClick={false}
      style={customStyles}
    >
      <h2 className="text-xl font-semibold m-4">Adults only (18+)</h2>
      <p className="text m-4">
        This website contains adult material and is only suitable for those 18 years or older.
        Click Enter only if you are at least 18 years of age.
      </p>
      <div className="flex justify-center space-x-4 m-4">
        <button onClick={handleYesClick} className="bg-green-600 text-white px-4 py-2 rounded-md">
          Enter
        </button>
        <button onClick={handleNoClick} className="bg-red-600 text-white px-4 py-2 rounded-md">
          Exit
        </button>
      </div>
    </Modal>
  );
};

export default AgeVerificationPopup;