import React, { useState } from 'react';
import Modal from 'react-modal';
import './css/modal.css'; 
import Button from '../Buttons/Button';
import { useDispatch } from 'react-redux'; 
import { loginUser } from '../../redux/features/userSlice'; 
import toast from 'react-hot-toast';
import snapchatLogo from '../../assets/images/snapchat-logo.svg';
import googleLogo from '../../assets/images/google-logo.svg';
import { signInWithPopup } from 'firebase/auth'; // Import Firebase auth methods
import { registerWithGoogle } from '../../redux/features/userSlice';
import { Oval } from 'react-loader-spinner';
import { EyeIcon, EyeSlashIcon } from '@heroicons/react/24/outline'; // Adjust this path if needed
import { auth, provider } from '../../utils/firebase'; // Adjust the path if needed

Modal.setAppElement('#root'); // Set root element for accessibility

const LoginModal = ({ isOpen, onRequestClose, openSignupModal, openResetPasswordModal }) => {
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(false); // État de chargement
  const [isLoadingGoogle, setIsLoadingGoogle] = useState(false); // État de chargement

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [message, setMessage] = useState({ text: '', color: '' });
  const [showPassword, setShowPassword] = useState(false); // New state for password visibility


  const togglePasswordVisibility = () => setShowPassword(!showPassword);

  // Custom styles for the modal
  const customStyles = {
    content: {
      position: 'none',
      background: 'white',
      outline: 'none',
      margin: 'auto',
      width: '90%', // Default width for small screens
      padding: '0',
      maxWidth: '500px', // Max width to prevent it from becoming too large on big screens
      borderRadius: '0.5rem',
    },
    overlay: {
      backgroundColor: 'rgba(0, 0, 0, 0.9)',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
  };


  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      setMessage({ text: '', color: '' });
      setIsLoading(true);
      await dispatch(loginUser({ email, password })).unwrap();
      toast.success('Successfully logged in! Welcome back!');
      //await dispatch(fetchUserData()).unwrap();
      onRequestClose();
      //navigate('/profile-info/informations');
    } catch (error) {
      toast.error('Invalid email or password. Please try again.');
      setMessage({ text: 'Invalid email or password. Please try again.', color: 'red' });
    } finally {
      setIsLoading(false);
    }
  };

  const handleGoogleSignUp = async () => {
    //const auth = getAuth();
    //const provider = new GoogleAuthProvider();
    setIsLoadingGoogle(true); // Set loading state to true when Google Sign-In starts
  
    try {
      // Open Google Sign-In popup and authenticate the user
      const result = await signInWithPopup(auth, provider);
  
      // Get the ID Token from the authenticated user
      const idToken = await result.user.getIdToken();
      const refreshToken = result.user.refreshToken; // Get the refreshToken
  
      //console.log("refreshtoken" + refreshToken);
  
      // Dispatch the action to sign up with Google
      const response = await dispatch(registerWithGoogle({ idToken, refreshToken, is_google_sso: true })).unwrap();
  
      if (response) {
        // Handle successful signup
        //console.log("Google signup successful:", response);
        toast.success('Successfully signed in with Google!');
        onRequestClose(); // Close the modal
      } else {
        // Handle errors
        toast.error('Google signup failed.');
        //console.error("Google signup failed:", response);
      }
    } catch (error) {
      //console.error("Error during Google Sign-Up:", error);
      toast.error('Failed to sign in with Google. Please try again.');
    } finally {
      setIsLoadingGoogle(false); // Set loading state to false when the process is complete
    }
  };
  



  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      style={customStyles}
      shouldCloseOnOverlayClick={true} // Close the modal when clicking outside
    >
      <div className="relative bg-white  shadow">
        <button
          type="button"
          className="absolute top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center"
          onClick={onRequestClose}
        >
          <svg
            aria-hidden="true"
            className="w-5 h-5"
            fill="#c6c7c7"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
              clipRule="evenodd"
            ></path>
          </svg>
          <span className="sr-only">Close popup</span>
        </button>

        <div className="p-10">
          <div className="text-center">
            <p className="mb-3 text-2xl font-semibold leading-5 text-slate-900">
              Login to your account
            </p>
          </div>

          <div className="mt-7 flex flex-col gap-2">
            <button
              className="hover:bg-slate-200 inline-flex h-10 w-full items-center justify-center gap-2 rounded border border-slate-300 bg-white p-2 text-sm font-medium text-black outline-none focus:ring-2 focus:ring-[#333] focus:ring-offset-1 disabled:cursor-not-allowed disabled:opacity-60"
            >
              <img
                src={snapchatLogo}
                alt="Snapchat"
                className="h-[18px] w-[18px]"
              />
              Continue with Snapchat
            </button>

            <button
              className="hover:bg-slate-200 inline-flex h-10 w-full items-center justify-center gap-2 rounded border border-slate-300 bg-white p-2 text-sm font-medium text-black outline-none focus:ring-2 focus:ring-[#333] focus:ring-offset-1 disabled:cursor-not-allowed disabled:opacity-60"
              onClick={handleGoogleSignUp}
              disabled={isLoadingGoogle} // Disable button while loading
            >
              {isLoadingGoogle ? (
                <Oval
                  height={20}
                  width={20}
                  color="#AC3B61"
                  wrapperClass="mr-2"
                  visible={true}
                  ariaLabel="oval-loading"
                  secondaryColor="#AC3B61"
                  strokeWidth={7}
                  strokeWidthSecondary={7}
                />
              ) : (
                <img
                  src={googleLogo}
                  alt="Google"
                  className="h-[18px] w-[18px]"
                />
              )}
              {isLoadingGoogle ? 'Signing in...' : 'Continue with Google'}
            </button>
          </div>

          <div className="flex w-full items-center gap-2 py-6 text-sm text-slate-600">
            <div className="h-px w-full bg-slate-200"></div>
            OR
            <div className="h-px w-full bg-slate-200"></div>
          </div>

          {/* Affichage du message */}
          {message.text && (
            <div className={`mt-3 mb-3 text-sm text-${message.color}-600 bg-${message.color}-100 border border-${message.color}-400 text-${message.color}-700 px-4 py-3 rounded mb-4`}>
              {message.text}
            </div>
          )}

          <form className="w-full" onSubmit={handleLogin}>
            <label htmlFor="email" className="sr-only">
              Email address
            </label>
            <input
              name="email"
              type="email"
              autoComplete="email"
              required
              className="block w-full rounded-lg border border-gray-300 px-3 py-2 shadow-sm outline-none placeholder:text-gray-400"
              placeholder="Email Address"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <label htmlFor="password" className="sr-only">
              Password
            </label>
            
            <div className="relative mt-5">
            <input
              name="password"
              autoComplete="current-password"
              required
              className="mt-2 block w-full rounded-lg border border-gray-300 px-3 py-2 shadow-sm outline-none placeholder:text-gray-400"
              placeholder="Password"
              type={showPassword ? "text" : "password"} // Toggle input type based on state
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
              <button
                type="button"
                onClick={togglePasswordVisibility}
                className="absolute right-3 top-3 text-gray-500"
              >
              {showPassword ? <EyeSlashIcon className="h-5 w-5" /> : <EyeIcon className="h-5 w-5" />}
              </button>
            </div>
            <Button
            type="submit"
            variant={'filled'}
            className="w-full mt-3"
            disabled={isLoading}
            >
              {isLoading ? 'Login in...' : 'Continue'}
            </Button>  
          </form>

          <p className="mb-3 mt-2 text-sm text-gray-500">
            <button onClick={() => { onRequestClose(); openResetPasswordModal(); }} className="font-medium text-[#4285f4]">
              Reset your password ?
            </button>
          </p>

          <div className="mt-6 text-center text-sm text-slate-600">
            Don't have an account? 
            <button onClick={() => { onRequestClose(); openSignupModal(); }} className="font-medium text-[#4285f4]">
              Sign up
            </button>
              
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default LoginModal;