import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import LoginModal from '../Modal/LoginModal';
import SignupModal from '../Modal/SignUpModal';
import ResetPasswordModal from '../Modal/ResetPasswordModal';
import Button from '../Buttons/Button';
import { useSelector, useDispatch } from 'react-redux';
import { logoutUser, resetUserState } from '../../redux/features/userSlice';
import { resetPhotoState } from '../../redux/features/photoSlice';
import toast from 'react-hot-toast';

const ProfileDropdown = ({ isOpen, closeDropdown }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isAuthenticated = useSelector((state) => state.user.isAuthenticated);
  const [isLoginModalOpen, setLoginModalOpen] = useState(false);
  const [isSignupModalOpen, setSignupModalOpen] = useState(false);
  const [isResetPasswordModalOpen, setResetPasswordModalOpen] = useState(false);

  const openLoginModal = () => setLoginModalOpen(true);
  const closeLoginModal = () => setLoginModalOpen(false);
  const openSignupModal = () => setSignupModalOpen(true);
  const closeSignupModal = () => setSignupModalOpen(false);
  const openResetPasswordModal = () => setResetPasswordModalOpen(true);
  const closeResetPasswordModal = () => setResetPasswordModalOpen(false);

  const handleLinkClick = (path) => {
    closeDropdown(); // Close dropdown before navigating
    navigate(path);  // Navigate to the specified path
  };

  const handleLogout = async () => {
    closeDropdown(); // Close dropdown before navigating
    try {
      //console.log("Logging out...");
      dispatch(resetUserState()); // This will set isAuthenticated to false in the Redux state

      dispatch(logoutUser());
      dispatch(resetPhotoState());
      navigate('/'); // Navigate to the home page
      toast.success('Logged out successfully!');
    } catch (error) {
      toast.success('A problem occurred while logging out');
    }
  };

  const navigateToLogin = () => {
    closeDropdown(); // Close dropdown before navigating
    openLoginModal(); // Navigate to the login page
    
  }

  const navigateToSignUp = () => {
    closeDropdown(); // Close dropdown before navigating
    openSignupModal(); // Navigate to the login page
  }

  return (
    <div>
      <div
        className={`absolute right-0 mt-6 w-60 bg-white rounded-md shadow-lg py-6 px-7 ${
          isOpen ? 'block' : 'hidden'
        }`}
      >
        {isAuthenticated ? (
          <>
            <div
              onClick={() => handleLinkClick('/profile-info/informations')}
              className="block px-4 py-2 text-gray-600 hover:bg-gray-100 cursor-pointer"
            >
              Profile
            </div>
            <div
              onClick={() => handleLinkClick('/profile-info/photo')}
              className="block px-4 py-2 text-gray-600 hover:bg-gray-100 cursor-pointer"
            >
              Photos
            </div>
            
            
            <Button
              onClick={handleLogout}
              variant={'outline'}
              className="w-full mt-5"
            >
              Log Out
            </Button>
          </>
        ) : (
          <>
            <Button
              onClick={navigateToLogin}
              variant={'outline'}
              className="w-full mt-2"
            >
              Log In
            </Button>
            <Button
              onClick={navigateToSignUp}
              variant={'outline'}
              className="w-full mt-3"
            >
              Sign Up
            </Button>
          </>
        )}
      </div>
      <LoginModal isOpen={isLoginModalOpen} onRequestClose={closeLoginModal} openSignupModal={openSignupModal} openResetPasswordModal={openResetPasswordModal} />
      <SignupModal isOpen={isSignupModalOpen} onRequestClose={closeSignupModal} openLoginModal={openLoginModal} />
      <ResetPasswordModal isOpen={isResetPasswordModalOpen} onRequestClose={closeResetPasswordModal} openLoginModal={openLoginModal}  />
    </div>
  );
};

export default ProfileDropdown;
