import React, { useEffect, useState } from "react";

const environment = process.env.REACT_APP_ENV || 'local'; // 'local' sera la valeur par défaut si REACT_APP_ENV est undefined

export default function Advertisement({ classNameMobile, dataZoneIdMobile, classNameDesktop, dataZoneIdDesktop }) {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    // Détecter si l'utilisateur est sur mobile
    const checkIsMobile = () => window.innerWidth <= 768;
    setIsMobile(checkIsMobile());

    // Listener pour ajuster en cas de redimensionnement
    const handleResize = () => setIsMobile(checkIsMobile());
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    // Nettoyer les anciens scripts
    const existingScripts = document.querySelectorAll('script[data-ad="dynamic"]');
    existingScripts.forEach((script) => script.remove());

    // Ajouter le script commun pour le fournisseur de publicité
    const script1 = document.createElement("script");
    script1.src = "https://a.magsrv.com/ad-provider.js";
    script1.async = true;
    script1.type = "application/javascript";
    script1.setAttribute("data-ad", "dynamic");
    document.body.appendChild(script1);

    // Initialiser AdProvider après le chargement du script
    const script2 = document.createElement("script");
    script2.innerHTML = `(AdProvider = window.AdProvider || []).push({"serve": {}});`;
    script2.setAttribute("data-ad", "dynamic");
    document.body.appendChild(script2);

    // Nettoyage des scripts au démontage du composant
    return () => {
      document.querySelectorAll('script[data-ad="dynamic"]').forEach((script) => script.remove());
    };
  }, [isMobile]); // Recharger les scripts si la plateforme (mobile/desktop) change

  // N'afficher les publicités que si l'environnement est 'prod'
  if (environment !== "prod") {
    return null;
  }

  return (
    
    <div className="flex justify-center p-10">
      {isMobile ? (
        // Publicité pour mobile
        <ins className={classNameMobile} data-zoneid={dataZoneIdMobile}></ins>
      ) : (
        // Publicité pour desktop
        <ins className={classNameDesktop} data-zoneid={dataZoneIdDesktop}></ins>
      )}
    </div>
   
  );

}
