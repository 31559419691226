import bannedWords from '../data/bannedWords.json';

// Générer des regex pour chaque mot interdit et ses variations
const generateRegexPattern = (word) => {
  const flexiblePattern = word
    .replace(/a/gi, '[a@]')
    .replace(/e/gi, '[e3]')
    .replace(/i/gi, '[i1!]')
    .replace(/o/gi, '[o0]')
    .replace(/u/gi, '[u]')
    .replace(/s/gi, '[s$]')
    .replace(/g/gi, '[gq]')
    .split('')
    .map((char) => `${char}+?`) // Permet d'ajouter ou supprimer des lettres
    .join('.?'); // Autorise des lettres intermédiaires

  return new RegExp(`\\b${flexiblePattern}\\b`, 'i');
};

export const bannedWordsRegex = bannedWords.map(generateRegexPattern);