import React from 'react';
import { Outlet } from 'react-router-dom';
import ProfileHeader from '../components/Profile/ProfileHeader';
import ProfileNav from '../components/Profile/ProfileNav';

const Profile = () => {
  return (
    <div className=" mx-auto p-4">
      <ProfileHeader />
      <ProfileNav />
      <div className="bg-white shadow-md rounded-lg">
        <Outlet />
      </div>
    </div>
  );
};

export default Profile;
