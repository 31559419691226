// src/redux/features/userSlice.js
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import AuthService from "../../api/authService";
import { getUserData, hideUser, topUpUser, firePinUser } from "../../api/userService";
import authService from "../../api/authService";
import { resetPhotoState } from "./photoSlice";
import { getSubscriptionDetails } from "../../api/stripeService";
import { getStripeInfo, subscriptionCancel } from "../../api/stripeService";
import apiClient from "../../api/apiClient";

const initialState = {
  idToken: localStorage.getItem("idToken") || null,
  refreshToken: localStorage.getItem("refreshToken") || null,
  isAuthenticated: !!localStorage.getItem("idToken"),
  user: {},
  stripe: {},
  userId: localStorage.getItem("userId") || null,
  loading: false,
  status: "idle",
  error: null,
};

// Thunks
export const fetchUserData = createAsyncThunk(
  "user/fetchUserData",
  async (_, { rejectWithValue }) => { // Notez l'ajout de "rejectWithValue"
    try {
      const response = await getUserData();
      return {
        user: response.userData,
        email: response.email
      };
    } catch (error) {
      // Retourner toute l'erreur
      if (error.response) {
        return rejectWithValue({
          status: error.response.status,
          headers: error.response.headers,
          data: error.response.data,
        });
      }

      // Sinon, retourner un message d'erreur générique
      return rejectWithValue({ message: "An unexpected error occurred." });
    }
  }
);

export const fetchStripeSubscriptionDetails = createAsyncThunk(
  "stripe/fetchSubscriptionDetails",
  async (stripeSubscriptionId, { rejectWithValue }) => {
    try {
    const response = await getSubscriptionDetails(stripeSubscriptionId);
    //console.log('API Response in fetchStripeSubscriptionDetails:', response);
    return response.data;  // Assure-toi que cette réponse a les données attendues
    } catch (error) {
      // Retourner toute l'erreur
      if (error.response) {
        return rejectWithValue({
          status: error.response.status,
          headers: error.response.headers,
          data: error.response.data,
        });
      }

      // Sinon, retourner un message d'erreur générique
      return rejectWithValue({ message: "An unexpected error occurred." });
    }
  }
);

export const fetchStripeData = createAsyncThunk(
  "user/fetchStripeData",
  async (_, { rejectWithValue }) => {
    try {
      const response = await getStripeInfo();
      return response;
    } catch (error) {
      // Retourner toute l'erreur
      if (error.response) {
        return rejectWithValue({
          status: error.response.status,
          headers: error.response.headers,
          data: error.response.data,
        });
      }

      // Sinon, retourner un message d'erreur générique
      return rejectWithValue({ message: "An unexpected error occurred." });
    }
  }
);

export const cancelSubscription = createAsyncThunk(
  "user/cancelSubscription",
  async ({ cancelType }, { rejectWithValue }) => {
    try {
      const response = await subscriptionCancel(cancelType);
      return response;
    } catch (error) {
      // Retourner toute l'erreur
      if (error.response) {
        return rejectWithValue({
          status: error.response.status,
          headers: error.response.headers,
          data: error.response.data,
        });
      }

      // Sinon, retourner un message d'erreur générique
      return rejectWithValue({ message: "An unexpected error occurred." });
    }
  }
);


export const loginUser = createAsyncThunk(
  "user/loginUser",
  async ({ email, password }, { rejectWithValue }) => {
    try {
      const data = { email, password };
      const response = await AuthService.login(data);
      localStorage.setItem("idToken", response.idToken);
      localStorage.setItem("refreshToken", response.refreshToken);
      localStorage.setItem("userId", response.userId);
      //console.log("Response from loginUser:", response);
      return {
        idToken: response.idToken,
        refreshToken: response.refreshToken,
        user: response.userData,
        userId: response.userId,
        stripe: response.stripe,
        email: response.email
      };
    } catch (error) {
      // Retourner toute l'erreur
      if (error.response) {
        return rejectWithValue({
          status: error.response.status,
          headers: error.response.headers,
          data: error.response.data,
        });
      }

      // Sinon, retourner un message d'erreur générique
      return rejectWithValue({ message: "An unexpected error occurred." });
    }
  }
);

export const registerUser = createAsyncThunk(
  "user/registerUser",
  async ({ email, password }, { rejectWithValue }) => {
    try {
      const data = { email, password };
      await AuthService.signup(data);
      return {
        user: null,
      };
    } catch (error) {
      // Retourner toute l'erreur
      if (error.response) {
        return rejectWithValue({
          status: error.response.status,
          headers: error.response.headers,
          data: error.response.data,
        });
      }

      // Sinon, retourner un message d'erreur générique
      return rejectWithValue({ message: "An unexpected error occurred." });
    }
  }
);

// Redux Thunk for toggling hide status
export const toggleHide = createAsyncThunk(
  "user/toggleHide",
  async (_, { rejectWithValue }) => {
    try {
      const response = await hideUser(); // Call hideUser without parameters
      return response; // Return the API response
    } catch (error) {
      // Retourner toute l'erreur
      if (error.response) {
        return rejectWithValue({
          status: error.response.status,
          headers: error.response.headers,
          data: error.response.data,
        });
      }

      // Sinon, retourner un message d'erreur générique
      return rejectWithValue({ message: "An unexpected error occurred." });
    }
  }
);

export const registerWithGoogle = createAsyncThunk(
  "user/registerWithGoogle",
  async ({ idToken, refreshToken }, { rejectWithValue }) => {
    try {
      // Utilisez l'AuthService pour envoyer le idToken
      const data = { idToken,refreshToken, is_google_sso: true };
      const response = await AuthService.googleSSO(data); // Appelle l'endpoint de signup
      localStorage.setItem("idToken", response.idToken);
      localStorage.setItem("refreshToken", response.refreshToken);
      localStorage.setItem("userId", response.userId);
      //console.log("Response from google oauth:", response);

      return {
        idToken: response.idToken,
        refreshToken: response.refreshToken,
        user: response.userData,
        userId: response.userId,
        stripe: response.stripe,
        email: response.email
      };
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);


// Thunk for refreshing token
export const refreshTokenThunk = createAsyncThunk(
  'user/refreshToken',
  async (refreshToken, { rejectWithValue }) => {
    try {
      const response = await authService.refreshToken(refreshToken);
      // Sauvegardez les nouveaux tokens dans le localStorage
      localStorage.setItem("idToken", response.idToken);
      localStorage.setItem("refreshToken", response.refreshToken);
      return {
        idToken: response.id_token,
        refreshToken: response.refresh_token,
      };
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

// Thunk for refreshing token
export const deleteAccount = createAsyncThunk(
  'user/deleteAccount',
  async (_, { rejectWithValue }) => {
    try {
      const response = await authService.deleteAccount();
      
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const logoutUser = createAsyncThunk(
  'user/logout',
  async (_, { dispatch }) => {
    // Retirer les tokens du localStorage
    await authService.logout();

    dispatch(resetUserState());
    dispatch(resetPhotoState());

    // Par exemple, si tu as d'autres slices que tu veux réinitialiser, tu pourrais les gérer ici.
    // dispatch(anotherSliceResetAction());

    // Si tu utilises redux-persist et que tu veux effacer le cache:
    //persistor.purge(); // Pour réinitialiser complètement le store persistant
    
    // Si nécessaire, tu peux ajouter d'autres actions, comme le reset d'autres slices
    return; // Ne retourne rien puisque nous réinitialisons simplement l'état dans les extraReducers
  }
);

export const topUpThunk = createAsyncThunk(
  'user/topUp',
  async (_, { rejectWithValue }) => {
    try {
      const response = await topUpUser();
      //console.log('API Response in userService topup :', response); 
      return response.data;
  } catch (error) {
      //console.error('Error using top up', error);
      // Retourner toute l'erreur
      if (error.response) {
        return rejectWithValue({
          status: error.response.status,
          headers: error.response.headers,
          data: error.response.data,
        });
      }

      // Sinon, retourner un message d'erreur générique
      return rejectWithValue({ message: "An unexpected error occurred." });
  }
});


export const firePinThunk = createAsyncThunk(
  'user/firePin',
  async (_, { rejectWithValue }) => {
  try {
      const response = await firePinUser();
      //console.log('API Response in userService firepin :', response); 
      return response.data;
  } catch (error) {
      //console.error('Error using fire pin', error);
      // Retourner toute l'erreur
      if (error.response) {
        return rejectWithValue({
          status: error.response.status,
          headers: error.response.headers,
          data: error.response.data,
        });
      }

      // Sinon, retourner un message d'erreur générique
      return rejectWithValue({ message: "An unexpected error occurred." });
  }
});


const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUser(state, action) {
      state.user = action.payload.user;
      state.idToken = action.payload.idToken;
      state.refreshToken = action.payload.refreshToken;
      state.isAuthenticated = true;
    },
    updateUser(state, action) {
      state.user = { ...state.user, ...action.payload };
    },
    resetUserState(state) {
      return initialState;  // On retourne simplement l'état initial défini au début
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(loginUser.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(loginUser.fulfilled, (state, action) => {
        state.loading = false;
        state.isAuthenticated = true;
        state.userId = action.payload.userId;
        state.idToken = action.payload.idToken;
        state.refreshToken = action.payload.refreshToken;
        state.user = action.payload.user || state.user;
        state.user.email = action.payload.email;
        state.photos = null

        // Ajouter les données Stripe à l'état si elles sont présentes
        if (action.payload.stripe) {
          state.stripe = action.payload.stripe;
        }
      })

      .addCase(registerWithGoogle.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(registerWithGoogle.fulfilled, (state, action) => {
        state.loading = false;
        state.isAuthenticated = true;
        state.userId = action.payload.userId;
        state.idToken = action.payload.idToken;
        state.refreshToken = action.payload.refreshToken;
        state.user = action.payload.user || state.user;
        state.user.email = action.payload.email;

        // Ajouter les données Stripe à l'état si elles sont présentes
        if (action.payload.stripe) {
          state.stripe = action.payload.stripe;
        }
      })
      .addCase(fetchStripeData.fulfilled, (state, action) => {
        state.stripe = action.payload;
      })
      .addCase(fetchStripeData.rejected, (state, action) => {
        state.error = action.payload || "Failed to fetch Stripe data.";
      })
      .addCase(loginUser.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      .addCase(registerUser.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(registerUser.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(registerUser.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      .addCase(fetchUserData.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchUserData.fulfilled, (state, action) => {
        state.loading = false;
        state.user = action.payload.user;
        state.user.email = action.payload.email;
      })
      .addCase(fetchUserData.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload || "Failed to fetch user data.";
      })


      .addCase(refreshTokenThunk.pending, (state) => {
        state.loading = true;
      })
      .addCase(refreshTokenThunk.fulfilled, (state, action) => {
        state.loading = false;
        state.idToken = action.payload.idToken;
        state.refreshToken = action.payload.refreshToken;
        state.isAuthenticated = true;
      })
      .addCase(refreshTokenThunk.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload || "Failed to refresh token";
        state.isAuthenticated = false;
      })

      .addCase(logoutUser.fulfilled, (state) => {
        // Réinitialise l'état de l'utilisateur lors du logout
        state.idToken = null;
        state.refreshToken = null;
        state.isAuthenticated = false;
        state.userId = null;
        state.user = null;
        state.loading = false;
        state.error = null;
        state.stripe = null
      })
      .addCase(cancelSubscription.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(cancelSubscription.fulfilled, (state, action) => {
        state.loading = false;
        state.stripe.subscription_status = action.payload.status;
        state.stripe.stripe_subscriber_id = null
        state.user.tier = "Free"
        // Optionally, update user tier or other properties based on the response
        //state.user.tier = action.payload.message.includes("immediately") ? "Free" : state.user.tier;
      })
      .addCase(cancelSubscription.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      
      .addCase(topUpThunk.fulfilled, (state, action) => {
        state.loading = false;
        state.user.top_up_balance -= 1;
      })

      .addCase(firePinThunk.fulfilled, (state, action) => {
        state.loading = false;
        state.user.fire_pins_balance -= 1;
      });
  },
});

export const { setUser, updateUser, resetUserState } = userSlice.actions;
export default userSlice.reducer;
