import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { FaCamera, FaInfoCircle } from 'react-icons/fa';
import ReactCountryFlag from 'react-country-flag';
import ChangePhotoModal from '../Modal/ChangePhotoModal';
import { checkProfileImage } from '../../utils/profileImageService';
import Modal from 'react-modal';
import { Link } from 'react-router-dom';

const InfoModal = ({ isOpen, onRequestClose, title, content }) => (
  <Modal
    isOpen={isOpen}
    onRequestClose={onRequestClose}
    style={{
      content: {
        maxWidth: '600px', // Augmenter la largeur
        maxHeight: '80vh', // Augmenter la hauteur
        margin: 'auto',     // Centrer horizontalement
        padding: '30px',    // Ajouter plus de padding
        borderRadius: '12px', // Bords arrondis plus doux
        overflowY: 'auto',  // Permettre le défilement si le contenu dépasse
        inset: 'unset',     // Réinitialiser les positions par défaut
        boxShadow: '0 4px 10px rgba(0, 0, 0, 0.3)', // Ajouter une ombre
      },
      overlay: {
        backgroundColor: 'rgba(0, 0, 0, 0.6)', // Un overlay plus doux
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      },
    }}
  >
    <h2 className="text-2xl font-bold mb-4 text-center">{title}</h2>
    <div className="text-gray-700 text-justify leading-relaxed">
      {content.split('\n').map((paragraph, index) => (
        <p key={index} className="mb-4">
          {paragraph}
        </p>
      ))}
    </div>
    <div className="flex justify-center">
      <button
        onClick={onRequestClose}
        className="bg-blue-500 text-white py-2 px-6 rounded hover:bg-blue-600 transition"
      >
        Close
      </button>
    </div>
  </Modal>
);


const ProfileHeader = () => {
  const profileImageRedux = useSelector(state => state.photos.photos.photoprofil);
  const [profileImage, setProfileImage] = useState();
  const { getStatus } = useSelector((state) => state.photos);
  const user = useSelector((state) => state.user.user);
  const dispatch = useDispatch();

  const [userName, setUserName] = useState('Marvin McKinney');
  const [location, setLocation] = useState('United States');
  const [topUp, setTopUp] = useState(100);
  //const [coins, setCoins] = useState(7800);
  const [firePin, setFirePin] = useState(10490);
  
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isInfoModalOpen, setIsInfoModalOpen] = useState(false);
  const [infoModalContent, setInfoModalContent] = useState({ title: '', content: '' });

  const handleImageClick = () => setIsModalOpen(!isModalOpen);

  const openInfoModal = (title, content) => {
    setInfoModalContent({ title, content });
    setIsInfoModalOpen(true);
  };

  useEffect(() => {
    if (user) { 
      setUserName(user.username);
      setLocation(user.country);
      setTopUp(user.top_up_balance);
      setFirePin(user.fire_pins_balance);
    }
  }, [user]);

  useEffect(() => {
    checkProfileImage(profileImageRedux, setProfileImage, dispatch);
  }, [profileImageRedux]);

  return (
    <div className="bg-gray-100 p-6 rounded-lg shadow-md text-center">
      
      <div className="relative inline-block mt-5">
        {getStatus !== 'succeeded' ? (
          <div className="w-32 h-32 rounded-full bg-gray-300 animate-pulse"></div>
        ) : (
          <img
            src={profileImage}
            alt="Profile"
            className="w-32 h-32 rounded-full mx-auto object-cover cursor-pointer hover:opacity-40 transition-opacity duration-300"
            onClick={handleImageClick}
          />
        )}
        <div className="absolute top-0 right-0 mt-2 mr-2 bg-blue-500 rounded-full p-2 cursor-pointer" onClick={handleImageClick}>
          <FaCamera className="text-white" size={12} />
        </div>
      </div>
      
      <h2 className="text-2xl font-semibold mt-4">{userName}</h2>
      <p className="text-gray-600">
        <ReactCountryFlag countryCode={location} style={{ fontSize: "1.5em" }} svg />  
      </p>
      
      <div className="mt-4 flex justify-around">
        {/* Points Section 
        <div className="relative">
          <span className="font-semibold text-lg">{coins}</span>
          <p className="text-gray-600 flex items-center cursor-pointer" onClick={() => openInfoModal("Points", "Here is some information about Points.")}>
            Points 
            <FaInfoCircle className="ml-2 text-gray-500" size={16} />
          </p>
        </div>
        */}

        {/* Fire Pin Section */}
        <div className="relative">
          <span className="font-semibold text-lg">{firePin}</span>
          <p className="text-gray-600 flex items-center cursor-pointer" onClick={() => openInfoModal("Fire Pin", 
   "A FirePIN is a premium feature that guarantees your profile's visibility and prominence for a specified period. Unlike a Top-Up, which boosts your profile temporarily until someone else takes your spot, a FirePIN ensures that your profile remains locked at the top of the post or profile list for a duration of 30 minutes. This exclusivity helps you stand out and attract maximum attention, as no other profile can replace yours during this period. With FirePIN, you have the power to secure the spotlight and make a lasting impression.")}>
            Fire Pin 
            <FaInfoCircle className="ml-2 text-gray-500" size={16} />
          </p>
        </div>

        {/* Top Up Section */}
        <div className="relative">
          <span className="font-semibold text-lg">{topUp}</span>
          <p className="text-gray-600 flex items-center cursor-pointer" onClick={() => openInfoModal(
  "Top-Up",
  "A Top-Up is a feature that allows you to boost your profile’s visibility on our platform. When you use a Top-Up, your profile is immediately placed at the top of the post or profile list visible to other users.\n\nBenefits of a Top-Up:\n\n1. Instant visibility boost: By appearing at the top of the list, you quickly capture the attention of other users.\n\n2. Temporary yet effective: Unlike other features, a Top-Up provides an immediate advantage, but its effect can be replaced if other users perform a Top-Up after you.\n\n3. A quick way to stand out: If you want to promote your profile or attract interactions faster, the Top-Up is the perfect tool.\n\nNote: The Top-Up does not guarantee a permanent spot at the top. For a more lasting effect, you can explore other features like the FirePIN, which secures a fixed position at the top for a set duration."
)}
>
            Top Up 
            <FaInfoCircle className="ml-2 text-gray-500" size={16} />
          </p>
        </div>

        
      </div>
      {/* Bandeau d'avertissement si l'utilisateur a été reporté */}
      {user?.report >= 3 && (
        <div className="bg-red-100 text-red-800 border border-red-300  p-4 rounded-lg mb-10 mt-16">
          <p className="text-sm font-bold">
            Your account has been reported multiple times. Your profile is temporary ban from HaveNude and hide from users. To resolve this issue, please contact us.
          </p>
          <Link
            to="/contact"
            className="text-blue-200 underline hover:text-blue-300 transition-colors"
          >
            Go to Contact Page
          </Link>
        </div>
      )}

      {/* Modals */}
      <ChangePhotoModal
        isOpen={isModalOpen}
        onRequestClose={() => setIsModalOpen(false)}
        setProfileImage={setProfileImage}
      />

      <InfoModal
        isOpen={isInfoModalOpen}
        onRequestClose={() => setIsInfoModalOpen(false)}
        title={infoModalContent.title}
        content={infoModalContent.content}
      />
    </div>
  );
};

export default ProfileHeader;
