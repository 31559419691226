import React from 'react';
import Button from '../Buttons/Button'; // Adjust the import path as needed
import { ThreeDots } from 'react-loader-spinner';

const ConfirmationModal = ({ isOpen, onClose, onConfirm, isChecked, setIsChecked, loading }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
      <div className="bg-white p-6 rounded-lg shadow-md w-80">
        <h2 className="text-xl font-semibold mb-4">Confirm Deletion</h2>
        <p className="text-gray-700 mb-4">Are you sure you want to delete this subscription?</p>
        
        {/* Checkbox for consequences */}
        <div className="flex items-start mb-4">
          <input
            type="checkbox"
            id="acknowledge"
            className="mr-2"
            checked={isChecked}
            onChange={(e) => setIsChecked(e.target.checked)}
          />
          <label htmlFor="acknowledge" className="text-sm text-gray-600">
            I understand the consequences of canceling this subscription.
          </label>
        </div>

        {/* Buttons */}
        <div className="flex justify-end space-x-3">
          <Button variant="outline" onClick={onClose} disabled={loading}>
            Cancel
          </Button>
          <Button variant="filled" onClick={onConfirm} disabled={!isChecked || loading}>
            {loading ? (
              <ThreeDots height="16" width="16" color="#fff" ariaLabel="loading" />
            ) : (
              'Confirm'
            )}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default ConfirmationModal;
