import React, { useState } from 'react';
import NavBar from './Navigation/NavBar';
import Footer from './Navigation/Footer';
import { Outlet } from 'react-router-dom';
import Sidebar from './Navigation/Sidebar';
import { Toaster } from 'react-hot-toast';

const Layout = () => {

  const [isSidebarOpen, setSidebarOpen] = useState(false);

  const toggleSidebar = () => {
    setSidebarOpen(!isSidebarOpen);
  };

  return (
    <div className="flex flex-col min-h-screen ">
      <NavBar toggleSidebar={toggleSidebar} />
      <Toaster
        position="bottom-right"
        reverseOrder={true}
      />
      <Sidebar isOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />
      <main className="flex-grow w-full pt-16">
        <Outlet />
      </main>
      <Footer />
    </div>
  );
};

export default Layout;
