import React, { useEffect, useRef } from 'react';
import { FaHome, FaShoppingCart, FaInfoCircle, FaPhoneAlt, FaChevronLeft } from 'react-icons/fa';
import { FiLogOut } from 'react-icons/fi';
import { NavLink, useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { logoutUser, resetUserState } from '../../redux/features/userSlice';
import { resetPhotoState } from '../../redux/features/photoSlice';
import toast from 'react-hot-toast';



const Sidebar = ({ isOpen, toggleSidebar }) => {
  const sidebarRef = useRef();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const isAuthenticated = useSelector((state) => state.user.isAuthenticated);


  const handleClickOutside = (event) => {
    if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
      toggleSidebar();
    }
  };

  const handleLogout = async () => {
    try {
      //console.log("Logging out...");
      dispatch(resetUserState()); // This will set isAuthenticated to false in the Redux state
      dispatch(logoutUser());
      dispatch(resetPhotoState());
      navigate('/'); // Navigate to the home page
      toast.success('Logged out successfully!');
      toggleSidebar();
    } catch (error) {
      toast.success('A problem occurred while logging out');
    }
  };

  useEffect(() => {
    if (isOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isOpen]);

  return (
    <>
      {/* Background overlay */}
      <div
        className={`fixed inset-0 bg-black bg-opacity-50 z-40 transition-opacity duration-300 ease-in-out ${
          isOpen ? 'opacity-100' : 'opacity-0 pointer-events-none'
        }`}
        onClick={toggleSidebar}
      ></div>

      {/* Sidebar container */}
      <div
        ref={sidebarRef}
        className={`fixed right-0 top-0 h-full bg-gray-600 text-white z-50 transform ${
          isOpen ? 'translate-x-0' : 'translate-x-full'
        } transition-transform duration-300 ease-in-out w-4/5 sm:w-64`}
      >
        {/* Header */}
        <div className="flex justify-between items-center px-4 py-4 border-b border-gray-800">
          <div className="text-lg font-semibold">Menu</div>
          <button onClick={toggleSidebar} className="text-gray-400 focus:outline-none">
            <FaChevronLeft className="h-6 w-6" /> {/* Nouvelle icône pour le retour */}
          </button>
        </div>

        {/* Navigation links */}
        <nav className="mt-4">
          <NavLink
            to="/"
            onClick={toggleSidebar}
            className={({ isActive }) =>
              `flex items-center px-4 py-3 text-gray-400 hover:bg-gray-800 hover:text-white transition-colors ${
                isActive ? 'bg-gray-800 text-white font-bold' : ''
              }`
            }
          >
            <FaHome className="mr-3" />
            Home
          </NavLink>

          <NavLink
            to="/about-us"
            onClick={toggleSidebar}
            className={({ isActive }) =>
              `flex items-center px-4 py-3 text-gray-400 hover:bg-gray-800 hover:text-white transition-colors ${
                isActive ? 'bg-gray-800 text-white font-bold' : ''
              }`
            }
          >
            <FaInfoCircle className="mr-3" />
            About Us
          </NavLink>

          <NavLink
            to="/contact"
            onClick={toggleSidebar}
            className={({ isActive }) =>
              `flex items-center px-4 py-3 text-gray-400 hover:bg-gray-800 hover:text-white transition-colors ${
                isActive ? 'bg-gray-800 text-white font-bold' : ''
              }`
            }
          >
            <FaPhoneAlt className="mr-3" />
            Contact
          </NavLink>
        </nav>
        {/* Bottom action buttons */}
        <div className="absolute bottom-4 left-4 right-4">
          {isAuthenticated ? (
            <button
              onClick={handleLogout}
              className="flex items-center justify-between w-full px-4 py-3 mt-2 text-sm font-semibold text-red-600 bg-gray-800 rounded hover:bg-red-700 hover:text-white transition-colors"
            >
              <span>Logout</span>
              <FiLogOut />
            </button>
          ) : (
            <p></p>
          )}
        </div>
      </div>
    </>
  );
};

export default Sidebar;
