// src/api/storagePhoto.js
import apiClient from "./apiClient"; // Assurez-vous que apiClient est correctement configuré

export const uploadPhotoAPI = async (photoId, file) => {
    const formData = new FormData();
    formData.append("file", file, `${photoId}.jpg`); // Assuming the file is a Blob, add a filename with an extension
    formData.append("type", "image/jpeg");
  
    try {
      const response = await apiClient.post(
        `/photo/uploadphoto?photo_id=${photoId}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            "accept": "application/json",
          },
        }
      );
  
      if (response.status !== 200) {
        throw new Error("Upload failed");
      }
      
      //console.log("response.data.photo_url", response.data.photo_url);
      return response.data.photo_url;
    } catch (error) {
      //console.error("Error uploading photo:", error);
      throw error; // Propagate the error to be caught by the thunk
    }
  };

export const getAvailablePhotosAPI = async () => {
  try {
    const response = await apiClient.get(`/photo/getavailablephotos`, {
      headers: {
        "Content-Type": "multipart/form-data",
        "accept": "application/json",
      },
    });
    return response.data.available_photos; // Retourne les photos disponibles
  } catch (error) {
    //console.error("Error getting available photos:", error);
    throw error; // Propager l'erreur
  }
};

export const getPhotoAPI = async (photoId) => {
  try {
    const response = await apiClient.get(`/photo/getphoto`, {
        params: {
          photo_id: photoId  // Paramètre pour photoId
        },
        headers: {
            "Content-Type": "multipart/form-data",
            "accept": "application/json",
          },
    });
    return response.data;
  } catch (error) {
    throw error;
        //console.error("Error getting photo:", error);
  }
};

export const deletePhotoAPI = async (photoId) => {
  console.log("photoId in delete api", photoId);
  try {
    const response = await apiClient.delete(`/photo/deletephoto`, {
      params: {
        photo_id: photoId  // Paramètre pour photoId
      },
      headers: {
          "Content-Type": "multipart/form-data",
          "accept": "application/json",
        }
  });
      return { photoId, message: response.data.message }; 
  } catch (error) {
    //console.error("Error deleting photo:", error);
    throw error; // Renvoie l'erreur pour qu'elle soit capturée par le thunk
  }
};
