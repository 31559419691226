import apiClient from './apiClient';


export const contactUs = async (data) => {
    try {
        const response = await apiClient.post('/contact', data);
        //console.log('API Response in contactService :', response);

        return response.data;
    } catch (error) {
        //console.error('Error fetching user data', error);
        throw error;
    }
};




