import React, { useState } from 'react';
import Button from '../components/Buttons/Button';
import { contactUs } from '../api/contactService';
import SEO from '../utils/SEO';
import toast from 'react-hot-toast';

const ContactUs = () => {
  const [isLoading, setIsLoading] = useState(false); // État de chargement
  const [isSubmitted, setIsSubmitted] = useState(false); // État pour la soumission réussie

  const [formData, setFormData] = useState({
    first_name: '',
    last_name: '',
    email: '',
    category: '',
    message: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    try {
      await contactUs(formData);
      setIsSubmitted(true);
    } catch (error) {
      toast.error('An error occurred. Please try again later.');
      //console.error('Erreur de réseau ou serveur:', error);
    } finally {
      setIsLoading(false);
    }
  };

  // Si le formulaire a été soumis avec succès, afficher un message de confirmation
  if (isSubmitted) {
    return (
      <div className="flex flex-col items-center p-8 bg-white">
        <h2 className="text-3xl font-bold mb-4">Thank you!</h2>
        <p className="text-gray-600">
          Your message has been received. We will get back to you soon.
        </p>
      </div>
    );
  }

  // Formulaire à afficher si pas encore soumis
  return (
    <div className="flex flex-col items-center p-8 bg-gray-100">
      
      <SEO 
        title="Contact US - Havenude" 
        description="Contact us for any inquiries or questions about our services. We are here to help." 
        name="HaveNude" 
        type="website"
      />
      <div className="text-center mb-12">
        <h2 className="text-3xl font-bold mb-4">Contact Us</h2>
        <p className="text-gray-600">
          Need to get in touch with us? Either fill out the form with your inquiry or find the department email you’d like to contact below.
        </p>
      </div>
      <form onSubmit={handleSubmit} className="bg-white p-8 rounded-lg shadow-lg w-full max-w-md">
        <div className="flex space-x-4 mb-4">
          <div className="w-1/2">
            <label htmlFor="first_name" className="block text-sm font-medium text-gray-700">
              First name*
            </label>
            <input
              type="text"
              name="first_name"
              id="first_name"
              required
              className="mt-1 p-2 block w-full border-gray-300 rounded-md shadow-sm"
              value={formData.first_name}
              onChange={handleChange}
            />
          </div>
          <div className="w-1/2">
            <label htmlFor="last_name" className="block text-sm font-medium text-gray-700">
              Last name
            </label>
            <input
              type="text"
              name="last_name"
              id="last_name"
              className="mt-1 p-2 block w-full border-gray-300 rounded-md shadow-sm"
              value={formData.last_name}
              onChange={handleChange}
            />
          </div>
        </div>
        <div className="mb-4">
          <label htmlFor="email" className="block text-sm font-medium text-gray-700">
            Email*
          </label>
          <input
            type="email"
            name="email"
            id="email"
            required
            className="mt-1 p-2 block w-full border-gray-300 rounded-md shadow-sm"
            value={formData.email}
            onChange={handleChange}
          />
        </div>
        <div className="mb-4">
          <label htmlFor="category" className="block text-sm font-medium text-gray-700">
            Category*
          </label>
          <select
            name="category"
            id="category"
            required
            className="mt-1 p-2 block w-full border-gray-300 rounded-md shadow-sm"
            value={formData.category}
            onChange={handleChange}
          >
            <option value="">Select a category</option>
            <option value="technical_support">Technical Support</option>
            <option value="connection_problem">Connection problem</option>
            <option value="feature_request">Feature Request</option>
            <option value="other">Other</option>
          </select>
        </div>
        <div className="mb-6">
          <label htmlFor="message" className="block text-sm font-medium text-gray-700">
            What can we help you with?
          </label>
          <textarea
            name="message"
            id="message"
            rows="4"
            required
            className="mt-1 p-2 block w-full border-gray-300 rounded-md shadow-sm"
            value={formData.message}
            onChange={handleChange}
          />
        </div>
        <Button
          type="submit"
          variant={'filled'}
          className="w-full mt-3"
          disabled={isLoading}
        >
          {isLoading ? 'Submitting...' : 'Submit'}
        </Button>
      </form>
    </div>
  );
};

export default ContactUs;
