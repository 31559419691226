import React from 'react';
import Modal from 'react-modal';

Modal.setAppElement('#root'); // Set root element for accessibility

const FeatureModal = ({ isOpen, onRequestClose, title, subtitle, description, image, logo }) => {
  const customStyles = {
    content: {
      position: 'none',
      background: 'white',
      outline: 'none',
      margin: 'auto',
      width: '90%', // Default width for small screens
      padding: '0',
      maxWidth: '500px', // Max width to prevent it from becoming too large on big screens
      borderRadius: '0.5rem',
    },
    overlay: {
      backgroundColor: 'rgba(0, 0, 0, 0.6)',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      style={customStyles}
      shouldCloseOnOverlayClick={true} // Close the modal when clicking outside
    >
      <div className="relative bg-white shadow p-6">
        <div className="flex items-center justify-between mb-4">
          <div className="flex items-center">
            {logo && <img src={logo} alt={title} className="w-8 h-8 mr-2" />}
            <span className="text-gray-600 font-medium">{subtitle}</span>
          </div>
          <button
            type="button"
            className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 inline-flex items-center"
            onClick={onRequestClose}
          >
            <svg
            aria-hidden="true"
            className="w-5 h-5"
            fill="#c6c7c7"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
              clipRule="evenodd"
            ></path>
          </svg>
            <span className="sr-only">Close popup</span>
          </button>
        </div>

        <h3 className="text-xl font-semibold mb-2">{title}</h3>
        <p className="text-sm font-normal text-gray-800 mb-4 text-center">{description}</p>
        {image && (
          <div className="flex justify-center mb-4">
            <img src={image} alt={title} className="rounded-full w-24 h-24" />
          </div>
        )}
      </div>
    </Modal>
  );
};

export default FeatureModal;
