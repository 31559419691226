import React, { useState, useRef, useEffect } from 'react';
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';
import Button from '../Buttons/Button';
import ReactFlagsSelect from 'react-flags-select';
import { FaSearch, FaTrash } from 'react-icons/fa'; // Import de l'icône poubelle
import './css/Filters.css';
import predefinedTags from '../../data/tags'; // Import des tags depuis le fichier
import { genderOptions, orientationOptions } from '../../data/enum';

const Filters = ({ onFiltersChange }) => {
  const [age, setAge] = useState([18, 80]);
  const [username, setUsername] = useState('');
  const [gender, setGender] = useState('All');
  const [orientation, setOrientation] = useState('All');
  const [isVIP, setIsVIP] = useState(false);
  const [country, setCountry] = useState("");
  const [tagInput, setTagInput] = useState('');
  const [tags, setTags] = useState([]);
  const [filteredTags, setFilteredTags] = useState([]);
  const tagInputRef = useRef(null);
  const filteredTagsRef = useRef(null);
  const [hasFiltersChanged, setHasFiltersChanged] = useState(false);
  const [isSearchDone, setIsSearchDone] = useState(false); // État pour gérer la recherche

  // Valeurs par défaut des filtres pour comparaison
  const defaultFilters = {
    username: '',
    age: [18, 80],
    gender: 'All',
    orientation: 'All',
    country: '',
    isVIP: false,
    tags: [],
  };

  // Fonction pour vérifier si un filtre a changé
  useEffect(() => {
    const currentFilters = {
      username,
      age,
      gender,
      orientation,
      country,
      isVIP,
      tags,
    };

    const hasChanged = JSON.stringify(currentFilters) !== JSON.stringify(defaultFilters);
    setHasFiltersChanged(hasChanged);

    // Réactive le bouton si les filtres sont modifiés après une recherche
    if (hasChanged) {
      setIsSearchDone(false);
    }
  }, [username, age, gender, orientation, country, isVIP, tags]);

  const handleClearFilters = () => {
    setUsername('');
    setAge([18, 80]);
    setGender('All');
    setOrientation('All');
    setCountry('');
    setIsVIP(false);
    setTags([]);
    setIsSearchDone(false); // Réinitialise l'état de recherche
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const filters = {
      username,
      age,
      gender,
      orientation,
      country,
      isVIP,
      tags,
    };
    //console.log('Selected Filters:', filters);
    onFiltersChange(filters); // Appeler la fonction de gestion des filtres
    setIsSearchDone(true); // Indique qu'une recherche a été effectuée
  };


  {/* Fonctions pour les tags */}

  const handleTagInputChange = (e) => {
    const input = e.target.value;
    setTagInput(input);
    if (input) {
      const filtered = predefinedTags
        .filter((tag) =>
          tag.toLowerCase().includes(input.toLowerCase())
        )
        .filter((tag) => !tags.includes(tag)); // Exclut les tags déjà sélectionnés
      setFilteredTags(filtered);
    } else {
      setFilteredTags(predefinedTags.filter((tag) => !tags.includes(tag))); // Affiche tous les tags non sélectionnés
    }
  };

  const handleTagInputFocus = () => {  // Affiche tous les tags disponibles non sélectionnés lors du clic
    setFilteredTags(predefinedTags.filter((tag) => !tags.includes(tag)));
  };

  const handleTagSelect = (selectedTag) => {
    if (!tags.includes(selectedTag)) {
      setTags((prev) => [...prev, selectedTag]);
    }
    setTagInput('');
    setFilteredTags([]);
  };

  const handleTagRemove = (tagToRemove) => {
    setTags((prev) => prev.filter((tag) => tag !== tagToRemove));
  };

  const handleClickOutside = (event) => {
    if (
      tagInputRef.current && 
      !tagInputRef.current.contains(event.target) &&
      filteredTagsRef.current && 
      !filteredTagsRef.current.contains(event.target)
    ) {
      setFilteredTags([]);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div className="bg-white shadow-md rounded-lg lgplus:p-6 lgplus:mb-4 lgplus:mx-10 lg:p-6 lg:mx-10 ">
      <form onSubmit={handleSubmit}>
        {/* Username */}
        <div className="mb-6">
          <input 
            type="text" 
            className="w-full p-2 border border-gray-300 rounded focus:outline-none focus:border-[#AC3B61]" 
            placeholder="Username"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
          />
        </div>

        {/* Country */}
        <div className="mb-6">
          <label className="block text-gray-700 font-bold text-sm mb-2">Country</label>
          <ReactFlagsSelect
            searchable={true}
            onSelect={(code) => setCountry(code)}
            selected={country}
            optionsSize={14}
            customStyles={{
              menu: (provided) => ({
                ...provided,
                maxHeight: '300px', // Adjust this height to show more options
                overflowY: 'auto', // Allow scrolling if the content overflows
              }),
            }}
          />
        </div>

        

        {/* Has Tags */}
        <div className="mb-6 relative" ref={tagInputRef}>
        <label className="block text-gray-700 font-bold text-sm mb-2">Tags</label>
          <div className="flex flex-wrap gap-2 mb-2">
            {tags.map((tag, index) => (
              <div key={index} className="flex items-center bg-gray-200 rounded-full px-3 py-1">
                <span className="mr-2">{tag}</span>
                <button 
                  type="button" 
                  className="text-gray-500 hover:text-gray-700"
                  onClick={() => handleTagRemove(tag)}
                >
                  &times;
                </button>
              </div>
            ))}
          </div>
          <input
            type="text"
            className="w-full p-2 border border-gray-300 rounded focus:outline-none focus:border-[#AC3B61]"
            placeholder="Search tags..."
            value={tagInput}
            onChange={handleTagInputChange}
            onFocus={handleTagInputFocus} // Affiche la liste au focus
            ref={tagInputRef}
          />
          {filteredTags.length > 0 && (
            <ul
              className="absolute bg-white border border-gray-300 rounded w-full mt-1 max-h-40 overflow-y-auto z-10"
              ref={filteredTagsRef}
            >
              {filteredTags.map((t, index) => (
                <li 
                  key={index} 
                  className="p-2 hover:bg-[#AC3B61] hover:text-white cursor-pointer"
                  onClick={() => handleTagSelect(t)}
                >
                  {t}
                </li>
              ))}
            </ul>
          )}
        </div>

        {/* Gender and Orientation */}
        <div className="flex space-x-4 mb-6">
          <div className="w-1/2">
            <label className="block text-gray-700 font-bold text-sm mb-2 ">Gender</label>
            <select 
              className="w-full p-2 border border-gray-300 rounded focus:outline-none focus:border-[#AC3B61] cursor-pointer"
              value={gender}
              onChange={(e) => setGender(e.target.value)}
            >
              {genderOptions.map((option, index) => (
                <option key={index} value={option}>{option}</option>
              ))}
            </select>
          </div>
          <div className="w-1/2">
            <label className="block text-gray-700 font-bold text-sm mb-2">Orientation</label>
            <select 
              className="w-full p-2 border border-gray-300 rounded focus:outline-none focus:border-[#AC3B61] cursor-pointer"
              value={orientation}
              onChange={(e) => setOrientation(e.target.value)}
            >
              {orientationOptions.map((option, index) => (
                <option key={index} value={option}>{option}</option>
              ))}
            </select>
          </div>
        </div>

        {/* Age */}
        <div className="mb-6">
          <label className="block text-gray-700 font-bold text-sm mb-2">Age</label>
          <Slider 
            range 
            min={18} 
            max={80} 
            value={age} 
            onChange={setAge} 
            trackStyle={[{ backgroundColor: '#AC3B61' }]}
            handleStyle={[{ borderColor: '#AC3B61' }, { borderColor: '#AC3B61' }]}
          />
          <div className="flex justify-between text-sm text-gray-500 mt-2">
            <span>{age[0]}</span>
            <span>{age[1]}</span>
          </div>
        </div>

        

        {/* Is VIP */}
        <div className="mb-6 flex items-center">
          <label className="block text-gray-700 font-bold text-sm mr-4">Is VIP</label>
          <input 
            type="checkbox" 
            className="form-checkbox h-5 w-5 text-[#AC3B61] focus:outline-none focus:ring-0 cursor-pointer"   
            checked={isVIP}
            onChange={(e) => setIsVIP(e.target.checked)}
          />
        </div>

        
        {/* Buttons */}
        <div className="flex space-x-4">
        <Button
            variant="outline"
            className={`w-full flex items-center justify-center gap-2 ${
              !hasFiltersChanged || isSearchDone
                ? 'cursor-not-allowed opacity-50'
                : 'hover:bg-[#AC3B61] hover:text-white'
            }`}
            type="submit"
            disabled={!hasFiltersChanged || isSearchDone} // Désactivé après une recherche
          >
            <FaSearch />
            Search
          </Button>
          {hasFiltersChanged && (
            <Button
              variant="outline"
              className="w-full flex items-center justify-center gap-2"
              type="button"
              onClick={handleClearFilters}
            >
              Clear Filters<FaTrash />
            </Button>
          )}
        </div>
      </form>
    </div>
  );
};

export default Filters;
