import React, { useState, useEffect, useRef } from 'react';
import { FaCheckCircle, FaStar, FaEllipsisH, FaMars, FaVenus, FaTransgender, FaGenderless, FaVenusMars, FaMale, FaFemale, FaTransgenderAlt } from 'react-icons/fa';
import { FaSnapchat } from "react-icons/fa6";
import { MdContentCopy } from "react-icons/md";
import { CiUser } from "react-icons/ci";
import { useNavigate } from 'react-router-dom';
import ImageGallery from 'react-image-gallery';
import ReactCountryFlag from "react-country-flag";
import { useSelector } from 'react-redux';
import "react-image-gallery/styles/css/image-gallery.css";
import ReportIcon from "../../assets/report.png";
import './css/Post.css';
import toast from 'react-hot-toast';
import { reportUser } from '../../api/userService';

const Post = ({ onProfileClick, author, sexe, orientation, age, time, images, description, isVerified, tier, country, tags, firePinExpiresAt, last_top_up}) => {
  const [isViewerOpen, setIsViewerOpen] = useState(false);
  const [activeImageIndex, setActiveImageIndex] = useState(0); // Nouvel état pour l'index de l'image active
  const galleryRef = useRef(null);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const menuRef = useRef(null);
  const user = useSelector((state) => state.user.user);
  const isAuthenticated = useSelector((state) => state.user.isAuthenticated);
  const [isReportModalOpen, setIsReportModalOpen] = useState(false);
  const [timeLeft, setTimeLeft] = useState(null);

  // Calculer la durée restante
  const calculateTimeLeft = () => {
    const now = new Date();
    const expiresAt = new Date(firePinExpiresAt);

    const difference = expiresAt - now;

    if (difference > 0) {
      const minutes = Math.floor((difference / 1000 / 60) % 60);
      const seconds = Math.floor((difference / 1000) % 60);
      return `${minutes}m ${seconds}s`;
    }

    return "Expired";
  };

  // Mettre à jour le timer toutes les secondes
  useEffect(() => {
    if (firePinExpiresAt) {
      const timer = setInterval(() => {
        const remainingTime = calculateTimeLeft();
        setTimeLeft(remainingTime);

        // Stop the timer if expired
        if (remainingTime === "Expired") {
          clearInterval(timer);
        }
      }, 1000);

      // Nettoyer l'intervalle
      return () => clearInterval(timer);
    }
  }, [firePinExpiresAt]);


  const scrollPosition = window.scrollY;
  localStorage.setItem('scrollPosition', scrollPosition);
  
  // Create a list of images excluding the profile photo
  const imageItems = images.map((image) => ({
    original: image,
    thumbnail: image,
  }));
  
  const navigate = useNavigate();

  const handleProfileClick = (userId) => {
    const profileData = {
      author,
      sexe,
      orientation,
      age,
      images,
      description,
      isVerified,
      tier,
      country,
      tags
    };
    onProfileClick(userId, profileData); // Pass profileData along with userId
  };

  const openViewer = (index) => {
    setActiveImageIndex(index); // Définit l'index de l'image cliquée
    setIsViewerOpen(true);
  };

  const closeViewer = () => {
    setIsViewerOpen(false);
  };

  const handleClickOutside = (event) => {
    if (galleryRef.current && !galleryRef.current.contains(event.target)) {
      closeViewer();
    }
  };

  const handleClickOutsideMenu = (event) => {
    if (menuRef.current && !menuRef.current.contains(event.target)) {
      setIsMenuOpen(false);
    }
  };

  const handleReportClick = () => {
    if( isAuthenticated) {

    setIsMenuOpen(false); // Fermer le menu
    setIsReportModalOpen(true); // Afficher le modal
    } else {
      toast.error("You should be logged in to report a post.");
    }
  };

  const handleReportSubmit = async () => {
    try {
        const postUserId = author.id;
        setIsReportModalOpen(false); // Fermer le modal
        if (!postUserId) {
            toast.error("Not possible to report this post.");
            return;
        }
        // Appel à l'API avec l'ID de l'utilisateur du post
        await reportUser(postUserId);
        toast.success('This post has been reported successfully.');
    } catch (error) {
      if (error.response && error.response.data.message) {
        // Gérer les erreurs spécifiques retournées par l'API
        toast.error(error.response.data.message);
    } else {
        // Message d'erreur générique pour les erreurs inattendues
        toast.error("An error occurred while reporting this post.");
    }
    //console.error('Error reporting user:', error);
    }
};


  const renderOrientationIcon = (orientation) => {
    switch (orientation) {
      case "Gay":
        return <FaMale className="text-blue-500 ml-1" />;
      case "Lesbian":
        return <FaFemale className="text-pink-500 ml-1" />;
      case "Bisexual":
        return <FaTransgenderAlt className="text-purple-500 ml-1" />;
      case "Straight":
        return <FaVenusMars className="text-green-500 ml-1" />;
      case "Other":
        return <FaGenderless className="text-gray-500 ml-1" />;
      default:
        return null;
    }
  };

  const renderGenderIcon = (gender) => {
    switch (gender) {
      case "Female":
        return <FaVenus className="text-pink-500 ml-1" />;
      case "Male":
        return <FaMars className="text-blue-500 ml-1" />;
      case "Transgender":
        return <FaTransgender className="text-purple-500 ml-1" />;
      case "Other":
        return <FaGenderless className="text-gray-500 ml-1" />;
      default:
        return null;
    }
  };

  useEffect(() => {
    if (isViewerOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    if (isMenuOpen) {
      document.addEventListener('mousedown', handleClickOutsideMenu);
    } else {
      document.removeEventListener('mousedown', handleClickOutsideMenu);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('mousedown', handleClickOutsideMenu);
    };
  }, [isViewerOpen, isMenuOpen]);

  const timeAgo = (dateString) => {

    if (dateString === "exemple") {
      return "1 min ago";
    }

    const now = new Date();
    const date = new Date(dateString);
    const diffInSeconds = Math.floor((now - date) / 1000);
  
    if (diffInSeconds < 60) {
      return `${diffInSeconds} sec ago`;
    } else if (diffInSeconds < 3600) {
      const minutes = Math.floor(diffInSeconds / 60);
      return `${minutes} min ago`;
    } else if (diffInSeconds < 86400) {
      const hours = Math.floor(diffInSeconds / 3600);
      return `${hours} hour${hours > 1 ? "s" : ""} ago`;
    } else {
      const days = Math.floor(diffInSeconds / 86400);
      return `${days} day${days > 1 ? "s" : ""} ago`;
    }
  };



  return (
    <div className={`bg-white rounded-md p-2 md:p-6 mb-10 relative ${firePinExpiresAt ? 'border-4 border-[#FF4500]' : ''}`}>
      {/* Subtle My Post Badge */}
      {isAuthenticated && author.id === user.user_id && (
        <div className="absolute -top-2 -left-2 bg-blue-400 text-white text-xs px-2 py-1 rounded-full shadow">
          My Post
        </div>
      )}

      
      {firePinExpiresAt && (
        <div className="absolute top-0 right-0 bg-[#FF4500] text-white text-[13px] px-3 py-1 rounded-bl-lg shadow-lg z-10">
          🔥 Fire Pin Active
          <div className="mt-1 text-white text-[13px] flex justify-center items-center">
            {timeLeft || "Calculating..."}
          </div>
        </div>
      )}
      
      <div className="flex justify-between mb-4 z-10">
      <div className="flex items-center z-10">
      <img
        src={author.image}
        alt="Author"
        className="h-14 w-14 rounded-full mr-4 cursor-pointer"
        onClick={() => handleProfileClick(author.id)}
        loading="lazy"
      />
      <div>
        {/* Première ligne */}
        <div className="font-semibold text-lg flex items-center">
          {author.name}
          {country !== "Unknown" && (
            <span>
              <ReactCountryFlag
                countryCode={country}
                style={{ fontSize: "1.5em" }}
                className="pl-2"
                svg
                aria-label={country}
              />
            </span>
          )}

          {isVerified && <FaCheckCircle className="text-[#c63e6c] ml-2" />}
          {tier === "VIP" && <FaStar className="text-yellow-500 ml-2" />}

          <div className="hidden lg:flex text-sm text-gray-500 items-center pl-5">
            {age} - {sexe}
            <>{renderGenderIcon(sexe)}</> - {orientation}
            <>{renderOrientationIcon(orientation)}</>
          </div>
        </div>

        {/* Deuxième ligne */}
        <div className="lg:hidden text-sm flex items-center pt-2">
          {age} - {sexe}
          <>{renderGenderIcon(sexe)}</> - {orientation}
          <>{renderOrientationIcon(orientation)}</>
        </div>
        {/* Nouvelle ligne ajoutée */}
        <div className="text-sm text-gray-500 flex items-center pt-1">
          {timeAgo(last_top_up)}
        </div>
      </div>
    </div>
        {isAuthenticated && author.id !== user.user_id && (
        <div className="relative">
          <button
            className="text-gray-500 hover:text-gray-700 focus:outline-none"
            onClick={() => setIsMenuOpen(!isMenuOpen)}
          >
            <FaEllipsisH />
          </button>

          {isMenuOpen && (
            <div
            ref={menuRef}
            className="absolute right-0 mt-2 w-48 bg-white rounded-lg shadow-xl border border-gray-200 z-50"
          >
            <ul>
              <li
                className="flex items-center py-3 px-4 hover:bg-red-100 hover:text-red-600 cursor-pointer transition duration-150 ease-in-out rounded-lg" // Ajout de rounded-lg
                onClick={() => {
                  handleReportClick();
                  setIsMenuOpen(false); // Ferme le menu après avoir cliqué
                }}
              >
                <img src={ReportIcon} alt="Report Icon" className="w-7 h-7 mr-2" />
                Report this user
              </li>
            </ul>
          </div>
          )}
        </div>
      )}
        
      </div>
      <div className="border-t border-gray-200 my-4"></div>

      <div className="text-gray-700 m-6">{description}</div>
      
      <div className="flex justify-center mb-4">
        {imageItems[0] && (
          <div className="block md:hidden">
            <img
              src={imageItems[0]?.original || '/assets/blank-post-image.png'}
              alt="Post"
              className="rounded-xl w-28 h-56 object-cover cursor-pointer border"
              onClick={() => openViewer(0)} // Définit l'index 0 pour la première image
              loading="lazy"
            />
          </div>
        )}
        <div className="lgplus:hidden hidden md:flex lg:flex space-x-10">
          {imageItems.slice(0, 3).map((image, index) => (
            <img
              key={index}
              src={image.original}
              alt={`Post ${index}`}
              className="rounded-xl w-28 h-56 object-cover cursor-pointer border"
              onClick={() => openViewer(index)} // Définit l'index de l'image cliquée
              loading="lazy"
            />
          ))}
        </div>
        <div className="hidden lgplus:flex space-x-10">
          {imageItems.slice(0, 3).map((image, index) => (
            <img
              key={index}
              src={image.original}
              alt={`Post ${index}`}
              className="rounded-xl w-28 h-56 object-cover cursor-pointer border"
              onClick={() => openViewer(index)} // Définit l'index de l'image cliquée
              loading="lazy"
            />
          ))}
        </div>
      </div>

      {/* Affichage dynamique des tags */}
      <div className="text-gray-700 m-6 flex flex-wrap gap-2">
        {tags && tags.map((tag, index) => (
          <span 
            key={index} 
            className="bg-gray-200 rounded-full px-3 py-1 text-sm"
          >
            {tag}
          </span>
        ))}
      </div>

      <div className="border-t border-gray-200 my-4"></div>
      <div className="flex justify-between items-center">
        <div className="flex space-x-4">
          {/* Bouton Snapchat */}
          <button
            onClick={() => {
              if (author.name) {
                window.open('https://www.snapchat.com/add/' + author.name, '_blank');
              } else {
                //console.error('Author name is not defined');
                alert('Author name is missing!');
              }
            }}
            className="flex items-center space-x-2 hover:text-yellow-600 focus:outline-none"
          >
            <FaSnapchat className="text-yellow-600"/>
            <span>Snapchat</span>
          </button>

          {/* Bouton Copy */}
          <button
            onClick={() => {
              navigator.clipboard.writeText(author.name).then(() => {
                toast.success('Text copied to clipboard!');
              }).catch((error) => {
                //console.error('Failed to copy text:', error);
                toast.error('Failed to copy text.');
              });
            }}
            className="flex items-center space-x-2 hover:text-gray-500 focus:outline-none pl-2"
          >
            <MdContentCopy />
            <span>Copy</span>
          </button>
        </div>
        <button className="flex items-center space-x-2 hover:text-pink-800 focus:outline-none" onClick={() => handleProfileClick(author.id)}>
          <CiUser className="text-pink-800"/>
          <span>Profile</span>
        </button>
      </div>

      {isViewerOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-75 flex items-center justify-center z-50" onClick={closeViewer}>
          <div className="relative w-full max-w-4xl" ref={galleryRef} onClick={(e) => e.stopPropagation()}>
            <button
              className="absolute top-2 right-2 text-white text-2xl z-50"
              onClick={closeViewer}
            >
              &times;
            </button>
            <ImageGallery
              items={imageItems}
              startIndex={activeImageIndex}  // Définit l'image active à afficher
              showThumbnails={false}
              showFullscreenButton={false}
              showPlayButton={false}
              additionalClass="custom-gallery"
            />
          </div>
        </div>
      )}

       {/* Modal pour Report */}
       {isReportModalOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white rounded-lg shadow-lg p-6 w-1/3">
            <h2 className="text-lg font-semibold mb-4">Report this user</h2>
            <p className="text-sm text-gray-600 mb-6">
              Are you sure you want to report this user? This action cannot be undone.
            </p>
            <div className="flex justify-end space-x-4">
              <button
                className="bg-gray-300 hover:bg-gray-400 text-gray-700 px-4 py-2 rounded"
                onClick={() => setIsReportModalOpen(false)}
              >
                Cancel
              </button>
              <button
                className="bg-red-500 hover:bg-red-600 text-white px-4 py-2 rounded"
                onClick={handleReportSubmit}
              >
                Report
              </button>
            </div>
          </div>
        </div>
      )}

<svg style={{ position: 'fixed', top: '100vh', display: 'none' }}>
        <defs>
          <filter id="blob">
            <feGaussianBlur in="SourceGraphic" stdDeviation="10" result="blur"></feGaussianBlur>
            <feColorMatrix 
              in="blur" 
              mode="matrix" 
              values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 19 -9" 
              result="blob"
            ></feColorMatrix>
          </filter>
        </defs>
      </svg>

    </div>
    
  );
  
};

export default Post;
