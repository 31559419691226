import React, { useState } from 'react';
import SEO from '../utils/SEO';

const TermsOfUse = () => {
  const [expandedSections, setExpandedSections] = useState({});

  const toggleSection = (section) => {
    setExpandedSections((prev) => ({
      ...prev,
      [section]: !prev[section],
    }));
  };

  return (
    <div className="px-6 md:px-16 lg:px-32 py-10 text-gray-800">        
      <SEO 
        title="Terms Of Use - Havenude" 
        description="Here are the terms of use for Have Nude, a platform for sharing Snapchat content and profiles." 
        name="HaveNude" 
        type="website"
      />
      <h1 className="text-3xl font-bold text-center mb-6">Terms of Use</h1>

      <section>
        <h2 className="text-xl font-semibold my-4">1. Who Are We?</h2>
        <p className="mb-4">
          The site <a href="https://havenude.com" className="text-blue-600 underline">https://HAVENUDE.COM</a> is hosted by OVH and managed by the company HaveNude, structured as an SCI.
        </p>
        <p className="mb-4">
          Only individuals of legal age may access HAVENUDE.COM, with a required validation: <br /><br />
          <em>This website contains suggestive photos. By clicking on ENTER, I certify that I am of legal age in my country.<br /> I also acknowledge that the content I am about to view may offend some people's sensibilities, and therefore, I waive any legal action against the authors or managers.
            Promoting or allowing minors access to adult content is strictly punishable by law. To prevent sensitive content, you can install filters that block access to certain content, such as Qustodio or Netnanny.<br />
            We inform you that this website uses cookies to personalize the content offered. By clicking on ENTER, you authorize this website to use cookies during your navigation.”<br />
            All persons accessing the site as minors expose themselves to psychological and sociological risks. Additionally, all persons accessing the website voluntarily accept the terms and conditions of use and certify that they have read them
            </em>
        </p>
        <p className="mb-4">
          Minors accessing this site risk psychological and sociological harm. Accessing HAVENUDE.COM means you agree to the terms and certify that you have read them.
        </p>
      </section>

      <section>
        <h2 className="text-xl font-semibold my-4">2. Glossary</h2>
        <button onClick={() => toggleSection("glossary")} className="text-blue-600 underline mb-2">
          {expandedSections.glossary ? "Hide" : "Show"} Glossary Terms
        </button>
        {expandedSections.glossary && (
          <div className="mb-4">
            <p className="mb-2">TThese definitions apply to all legal conditions applicable to the Website, specifically to the General Conditions of Use, the Privacy Policy, the Advertiser Charter, and the General Conditions of Sale.:</p>
            <ul className="list-disc pl-6">
              <li><strong>My Account:</strong> The user’s personal account for accessing data, posting profiles, making purchases, etc.</li>
              <li><strong>Profile:</strong> User-created profile showcasing the individual, optionally with photos.</li>
              <li><strong>User:</strong> Any natural person of legal age acting for private purposes.</li>
              <li><strong>CGU:</strong> General Conditions of Use governing obligations and relationships between users and the Company.</li>
              <li><strong>CGV:</strong> General Terms and Conditions of Sale subscribed by users.</li>
              <li><strong>Advertiser Charter:</strong> Charter users declare compliance with.</li>
              <li><strong>Personal Data:</strong> Information provided by users, defined in the Privacy Policy.</li>
              <li><strong>Privacy Policy:</strong> Rules on the protection and security of Personal Data.</li>
              <li><strong>Customer Service:</strong> Department providing additional information, contactable at contact@havenude.com.</li>
            </ul>
          </div>
        )}
      </section>

      <section>
        <h2 className="text-xl font-semibold my-4">3. General Conditions of Use - GCU</h2>
        <button onClick={() => toggleSection("gcu")} className="text-blue-600 underline mb-2">
          {expandedSections.gcu ? "Hide" : "Show"} GCU Details
        </button>
        {expandedSections.gcu && (
          <div className="mb-4">
            <h3 className="font-semibold my-2">3-1 Purpose</h3>
            <p>The purpose of the GCU is to determine the conditions of use of the HaveNude.com website and its services for Users. Any user with a personal space on the website declares to have read these Terms and accepts them. Users undertake to comply with these Terms.
            .</p>
            <br />
            <h3 className="font-semibold my-2">3-2 Accessible Services on the Website</h3>
            <ul className="list-disc pl-6 mb-2">
              <li>Profile consultation</li>
              <li>Creating a personal space</li>
              <li>Managing profile listings</li>
              <li>Receiving email alerts</li>
              <li>Management of contact details and personal information</li>
            </ul>
            <br />
            <h3 className="font-semibold my-2">3-3 Creation of a Personal Space</h3>
            <p>The user undertakes to complete the online registration form in good faith, providing accurate information. They must not usurp identity or use a false identity. On the website, users must provide the following information: title, first and last name. They must be at least 18 years old.
            .</p>
            <p>HaveNude may ask a user to verify their account for security purposes and may request a copy of proof of identity to verify the user's age. All data collected as a result of such confirmation will be treated in accordance with our privacy policy.</p>
            <br />
            <h3 className="font-semibold my-2">3-4 Password</h3>
            <p>
            of the Services, particularly their personal space and messaging. Users cannot hold the company responsible for actions on the website by a third party in possession of their password. Users acknowledge that the company is not responsible for fraudulent use of their personal space or messaging. Users undertake to notify the Company as soon as possible of any unauthorized use of their account or messaging.

            Any forgotten password can be reset through the "forgotten password" section of the website.

            </p>
            <br />
            <h3 className="font-semibold my-2">3-5 Creating and Managing a Profile</h3>
            <p>Users may create their profile according to the conditions indicated on the profile submission form. For technical reasons, the posting of a profile on the Website may not be instantaneous. 
            .</p>

            <h5 className="font-black my-2">3-5-1 Creating a profile</h5>
            <p>Users may create their profile according to the conditions indicated on the profile submission form. For technical reasons, the posting of a profile on the Website may not be instantaneous. </p>
            <h5 className="font-black my-2">3-5-2 Rules for profile distribution</h5>
            <p>Users may create their profile according to the conditions indicated on the profile submission form. Users determine the number of photos (up to three).</p>
            <h5 className="font-black my-2">3-5-3 Compliance with legislation and third-party rights</h5>
            <p>Users expressly guarantee to be personally responsible for obtaining all necessary rights and authorizations for the publication of any profile content. Users guarantee that their profile does not contravene any applicable standards and regulations.</p>
            <p>Users undertake that their profile:<br /><br />
                - Does not link to websites with content contrary to legislation or regulations<br />
                - Does not present false or misleading information, defamatory content, or counterfeit material<br />
                - Does not contain images prohibited by law or that violate human integrity<br />
                <br />
                HaveNude reserves the right to not publish any profiles of this type and to refer to competent authorities if necessary.

                Users are responsible for the content they publish and make accessible to other users. They assume full editorial responsibility for the content of their profiles and guarantee the company against any conviction that may result from this content.
                </p>
            <h5 className="font-black my-2">3-5-4 Rights cession on profiles</h5>
            <p>By publishing any profile on the Website, each user grants HaveNude a free, non-exclusive, transferable, sublicensable license for all profile content, including photographs, texts, videos, illustrations, trademarks, logos, and titles. This grants HaveNude the right to use, adapt, modify, delete, add, perform, reproduce, translate, and distribute all or part of the Content for commercial or non-commercial purposes.</p>
            <p>Users accept that their profile may be accessible, reproduced, and represented by other users of the site, including on social networks. HaveNude is not responsible for comments published on the internet, especially abusive or defamatory comments.</p>
            <h5 className="font-black my-2">3-5-5 Withdrawal or suspension of profiles</h5>
            <p>HaveNude reserves the right to delete, without notice or compensation, all or part of a profile that does not comply with these GCU or that is likely to infringe on the rights of a third party.</p>
            <h5 className="font-black my-2">3-5-6 Notification of abuse and judicial requests</h5>
            <p>HaveNude reserves the right to remove content that a user has identified as illegal or manifestly illicit. Users are advised that, at the request of a judicial authority, HaveNude will provide all information allowing or facilitating the identification of the user, such as IP address and connection time.</p>


            <br />
            <h3 className="font-semibold my-2">3-6 Liability of the Company</h3>
            <p>HHaveNude cannot be held responsible for the content of profiles published by users and gives no guarantee, express or implied, in this regard. HaveNude is not obliged to exercise any control over the quality, lawfulness, veracity, or accuracy of the profiles published. However, if a user publishes content that is illegal or manifestly illicit, HaveNude reserves the right to remove it without notice or compensation.<br />

            HaveNude is a third party to the correspondence and relations between users and therefore excludes any liability in this regard. Agreements are concluded directly between users. HaveNude does not guarantee that an agreement can be concluded between users.<br />

            The Services are offered for information purposes only and do not constitute a commitment or guarantee on HaveNude's part. HaveNude cannot be held responsible for the direct or indirect consequences of the use of this information or the Services made available to users on the Site.<br />
            HaveNude is not responsible for any fraudulent use of information found on the website by users. HaveNude reserves the right to modify or interrupt access to the Site or Services at any time. HaveNude cannot be held responsible for interruptions and modifications of the website and Services. Users are responsible for taking necessary precautions to preserve their profiles published on the Site.<br />
            HaveNude undertakes to implement all necessary means to ensure the best possible provision of the Services it offers to users.<br />
            HaveNude cannot be held liable for the loss of data or information stored on the website or for any direct or indirect damage resulting from the management, use, operation, interruption, or malfunction of the Site or Services.<br />
            HaveNude cannot be held liable in the event of force majeure or circumstances beyond its control, particularly in the event of interruption of the Site or Services resulting from a failure of the communications network or the user's Internet service provider.<br />
            .</p>
            <br />
            <h3 className="font-semibold my-2">3-7 User's Warranties</h3>
            <p>In the event that HaveNude's liability is judicially sought due to a breach by a user of their obligations under the Terms of Use, User Charter, or Privacy Policy, HaveNude may call upon the user as a guarantee. Consequently, the user guarantees HaveNude against the consequences of any legal or factual disturbance, particularly any action directed against HaveNude in connection with a violation of the Terms of Use, User Charter, or Privacy Policy. The user would bear all damages to which HaveNude would be sentenced, including legal costs, lawyers' fees, and publication costs.<br />

            The user agrees to immediately inform HaveNude of any claim made by a third party relating to the dissemination of their profile, so that HaveNude may suspend or interrupt the publication of the profile on the Site, without this suspension or interruption giving rise to any right to compensation for the user.<br />
            .</p>
            <br />
            <h3 className="font-semibold my-2">3-8 Personal Data</h3>
            <p>HaveNude undertakes to comply with the legislation in force regarding the collection of personal data relating to users. The information collected is intended for HaveNude's use and may not be communicated or rented to third parties or technical service providers involved in the execution of the Terms of Use, User Charter, or Privacy Policy. These third parties or service providers undertake to respect the confidentiality of this personal data.

            Users are invited to consult the Privacy Policy accessible on the Site.
            </p>
            <br />
            <h3 className="font-semibold my-2">3-9 Intellectual Property</h3>
            <p>HaveNude is a registered trademark. Any total or partial reproduction of trademarks and logos made from the elements of the website without HaveNude's express prior authorization is prohibited. All elements constituting the Site (texts, illustrations, photographs, images, videos, sounds, plans, names, logos, trademarks, software, and databases) are the exclusive property of HaveNude or are used by HaveNude with the authorization of the rights holders and are protected under intellectual property laws.

            HaveNude reserves the right to take legal action against any person who does not comply with this prohibition.
            </p>
            <br />
            <h3 className="font-semibold my-2">3-10 Suspension and Termination</h3>
            <p>In the event of a breach of the Terms of Use, User Charter, or Privacy Policy, HaveNude reserves the right to temporarily or permanently suspend a profile and/or a personal space.

            In the event of termination of the personal space, the profiles posted by the user on the website will be deleted automatically and will not remain accessible to other users.
            HaveNude may terminate the registration of a user without notice in the event of manifest abuse, particularly in the event of violation of these Terms, or in the event of the dissemination of one or more profiles that would seriously violate the principles set out in the User Charter or the Privacy Policy.
            </p>
            <br />
            <h3 className="font-semibold my-2">3-11 Evolution of the Terms of Use</h3>
            <p>HaveNude reserves the right to modify, in whole or in part, the Terms of Use and the Services, to adapt them to changes in its operation and/or changes in legislation.
            </p>
            <br />
            <h3 className="font-semibold my-2">3-12 Complaints</h3>
            <p>Complaints must be sent to contact@havenude.com.</p>
          </div>
        )}
      </section>

      <section>
        <h2 className="text-xl font-semibold my-4">4. External Privacy Policy</h2>
        <button onClick={() => toggleSection("privacyPolicy")} className="text-blue-600 underline mb-2">
          {expandedSections.privacyPolicy ? "Hide" : "Show"} Privacy Policy
        </button>
        {expandedSections.privacyPolicy && (
          <div className="mb-4">

            <h3 className="font-semibold my-2">4-1 Privacy Policy</h3>
            <p>Through this privacy policy, HaveNude undertakes to protect the privacy of users by ensuring the protection, confidentiality, and security of personal data. HaveNude commits to protecting human dignity, legitimate interests, and fundamental rights of the persons concerned.

            This privacy policy aims to provide clear, simple, and precise information about data processing to enable users to understand what information and personal data are collected, how they are used, and what their rights are in relation to this personal data.

            HaveNude reserves the right to modify this privacy policy at any time, in accordance with applicable regulations relating to personal data protection.
            </p>
            <br />
            <h3 className="font-semibold my-2">4-2 Data Controller</h3>
            <p>All personal data provided during registration and use of our services are managed by HaveNude's support team.</p>
            <br />
            <h3 className="font-semibold my-2">4-3 Personal Data</h3>
            <p>Personal data includes identification and operation data collected during registration and usage.</p>
            <p>HaveNude may collect Personal Data when you: <br />
                - Open a personal space<br />
                - Use services, send a complaint, or request information<br />
                - Browse the website pages (cookies may be saved on your device)<br />
                <br />
            </p>
            <p>We use essential cookies to ensure the proper functioning of our website. These include cookies for:</p><br /><br />
            <p>- Persisting your session or preferences (e.g., Redux state management).</p>
            <p>- Remembering your scroll position for a seamless user experience.</p>

            <p>These cookies are necessary for the website to function correctly and cannot be disabled.</p>
            
            <h5 className="font-black my-2">4-3-1 Declarative Personal Data:</h5><br />
            <p>This includes identification information recorded during registration, such as surname, first name, title, address, telephone number, email address, and potentially bank details. Some information may be optional. User credentials are available on the "your profile" page accessible from the personal space.<br />

            HaveNude does not store banking data. For payment transactions, HaveNude uses a secure third-party service provider.<br />

            We do not deliberately process sensitive data, but may do so incidentally if:<br />
            - You provide such information in free text fields (e.g., information on racial or ethnic origin, sexual life, sexual orientation, health, or biometric data)<br />
            - You respond to a profile (sexual preferences)<br />
            <br />
            </p>
            <h5 className="font-black my-2"> 4-3-2 Website Operation Data:</h5><br />
            <p>This includes electronic identification data (e.g., IP address, pages visited) and location data collected via cookies. This data allows us to provide geographically personalized services.</p>


            <h3 className="font-semibold my-2"> 4-4 Personal Data Provided by Users</h3><br />
            <p>Users are solely responsible for the data they communicate. They undertake to:<br />
            - Provide accurate and complete identification information and keep it updated<br />
            - Choose a username (email address) and password that allows access to services<br />
            - Not use an email address that infringes on third-party rights or is contrary to regulations, public order, and morality<br />

            HaveNude reserves the right to refuse any names or terms contrary to these principles without providing a reason.<br />

            We do not share or transfer your personal data to third parties unless required by law or with your explicit consent.<br />

            Here's an adapted version of sections 4-5 to 4-10 for HaveNude.com, based on the provided script:<br />
            <br />
            </p>

            <br />
            <h3 className="font-semibold my-2">4-5 Purposes of Processing</h3>
            <p>Data is used for account maintenance, profile publication, user connections, and service improvements.</p>
            <p>Personal data may be used for the following purposes:<br /><br />

            - Recording, maintaining, and authenticating personal spaces<br />
            - Publishing and managing online profiles<br />
            - Connecting users<br />
            - Providing services available on the website<br />
            - Offering personalized services (email alerts, profile follow-ups, etc.)<br />
            - Enabling participation in surveys (including satisfaction surveys)<br />
            - Establishing anonymous statistics on website usage<br />
            - Evaluating and improving service quality<br />
            - Preventing violations of the Terms of Use and strengthening security measures<br />
            - Complying with applicable laws and regulations<br />
            - Improving understanding of user behavior and preferences<br />
            <br />
            </p>
            <br />
            <h3 className="font-semibold my-2">4-6 Sharing of Personal Data with Third Parties</h3>
            <p>Some personal data may be shared with third parties to meet legal, regulatory, or contractual obligations, or with legally authorized authorities. Data may also be shared to improve user experience through service provision.

            Personal data may be accessible to HaveNude's technical service providers acting as subcontractors for hosting, maintaining personal spaces, and managing customer relations. These providers are required to respect data confidentiality and may only use the data for purposes specified by HaveNude.

            Users are informed that HaveNude may be required to communicate personal data to authorized public authorities in case of legal violations or when required by law.
            </p>
            <br />
            <h3 className="font-semibold my-2">4-7 Disclosure of Personal Data in Profiles</h3>
            <p>Profiles published on HaveNude are freely accessible to users and may be indexed by search engines. Any disclosure of personal data in profiles may expose users to unsolicited messages, for which they are solely responsible.

            Users agree never to disclose personal information about other individuals without their express consent.
            </p>
            <br />
            <h3 className="font-semibold my-2">4-8 Specific Provisions Relating to Messaging</h3>
            <p>In accordance with applicable legislation, messages exchanged between users are considered private correspondence and confidential. HaveNude acts exclusively as the host of the messaging system and does not control the content of messages.</p>
            <br />
            <h3 className="font-semibold my-2">4-9 User Rights Regarding Personal Data</h3>
            <p>In accordance with current legislation, users have the following rights:<br /><br />

            - Right to information<br />
            - Right of access<br />
            - Right of rectification<br />
            - Right to erasure (right to be forgotten)<br />
            - Right to restriction of processing<br />
            - Right to data portability<br />
            - Right to define guidelines for personal data after death<br />
            - Right to object and not be subject to automated individual decisions<br />

            Users can exercise these rights by writing to: contact@havenude.com<br />

            To process requests, users must provide a copy of an official identity document containing their full name, date, and place of birth, signed by them. Users are responsible for updating their information if their situation changes.<br />

            HaveNude keeps records of user requests to exercise their rights for follow-up purposes.<br />
            <br />
            </p>
            <br />
            <h3 className="font-semibold my-2">4-10 Retention Period of Personal Data</h3><br />
            <p>Personal data is stored in HaveNude's information systems. Subject to transmission to third parties, personal data is stored in a data center and processed at HaveNude's headquarters.<br />

                HaveNude maintains records of data hosting locations to justify them to relevant supervisory authorities.<br />

                Personal data is kept for the time necessary to meet the purposes described in the "Purposes of Processing" section and in accordance with applicable legislation, typically for a period of five years.<br />

                For user rights requests, HaveNude retains proof of identity for one year from receipt.<br />

                Here's an adapted version of sections 4-11 to 5-3 for HaveNude.com, based on the provided script:<br />
                </p>

                <h5 className="font-black my-2">4-11 Protecting Your Privacy Policy Data</h5><br />
                <p>HaveNude implements strict security procedures to prevent unauthorized access and comply with applicable data protection laws. All personal information is stored on secure servers of our technical provider.<br />

                Our secure servers encrypt all Personal Data before transmission. Your password for accessing your personal space protects your personal data. You are required to keep this password confidential and never share it with anyone.<br />

                You are responsible for any use of the Website and associated Services by anyone using your personal space identifier and password. Contact HaveNude immediately if you believe your account has been compromised. As a precaution, we advise closing your personal space after each session.<br />

                When exercising your rights, proof of identity is kept only for the duration of processing your request.<br />
                <br />
                </p>

                <h5 className="font-black my-2"> 4-12 Cookies</h5><br />
                <p>HaveNude uses cookies to enhance user experience:<br />

                - Comment cookies: If you leave a comment, you may opt-in to save your name, email address, and website in cookies for one year.<br />
                - Login cookies: Temporary cookies check if your browser accepts cookies. Login cookies last for two days, and screen options cookies last for a year. The "Remember Me" option extends login persistence to two weeks.<br />
                - Content editing cookies: When you edit or publish content, an additional cookie is saved, expiring after one day.<br />
                <br />
                </p>
                <h5 className="font-black my-2"> 4-13 Embedded Content from Other Websites</h5><br />

                <p>Our site may include embedded content (e.g., videos, images, articles). This content behaves as if you visited the other website directly. These websites may collect data about you, use cookies, embed additional third-party tracking, and monitor your interaction with the embedded content.</p>


          </div>
        )}
      </section>

    <section>
        <h2 className="text-xl font-semibold my-4">5. TRANSACTION CONDITIONS</h2>
        <button onClick={() => toggleSection("profileVisibility")} className="text-blue-600 underline mb-2">
          {expandedSections.profileVisibility ? "Hide" : "Show"} Profile Visibility and Promotion
        </button>
        {expandedSections.profileVisibility && (
            <div className="mb-4"> 
            <h3 className="font-semibold my-2">5-1 Principles</h3>
            <p>HaveNude is a platform for sharing Snapchat content and profiles. It connects users to exchange content or promote their profiles. HaveNude is not a party to any agreements between users.

            HaveNude provides a secure messaging system for users to communicate. Users must identify themselves by creating a personal space on the site.
            </p>

            <h3 className="font-semibold my-2">5-2 Conduct of Interactions</h3>
            <p>Interactions between users are initiated when one user expresses interest in another's profile. Users can then communicate through the site's messaging system to arrange content exchanges or further interactions.
            </p>
            <h3 className="font-semibold my-2">5-3 Profile Visibility and Promotion</h3>
            <p>Profiles are visible to all users on the platform. Users can enhance their profile visibility through various means offered by HaveNude.
            <br /><br />
            The visibility and promotion of profiles may involve:<br />
            - Free basic listing<br />
            - Premium placement options (if applicable)<br />
            - Featured profile status (if offered)<br />
            <br />
            HaveNude reserves the right to modify visibility options and may offer promotional services to enhance user experiences and profile reach.
            <br />
            Based on the provided script for HaveNude.com, which focuses on sharing Snapchat content and profiles rather than selling physical items, the sections on shipping, payments, refunds, and returns need to be significantly adapted or removed. Here's a revised version that aligns with HaveNude's purpose:
            </p>

            </div>
        )}
      </section>

      <section>
        <h2 className="text-xl font-semibold my-4">6. Profile Visibility and Promotion</h2>
        <button onClick={() => toggleSection("profileVisibility")} className="text-blue-600 underline mb-2">
          {expandedSections.profileVisibility ? "Hide" : "Show"} Profile Visibility and Promotion
        </button>
        {expandedSections.profileVisibility && (
          <div className="mb-4">
            <h3 className="font-semibold my-2">5-1 Principles</h3>
            <p>HaveNude is a platform for sharing Snapchat content and profiles. The website connects users to exchange content or promote their profiles. HaveNude is not a party to any agreements between users.
            </p>
            <br />
            <h3 className="font-semibold my-2">5-2 Profile Visibility</h3>
            <p>Profiles are visible to all users on the platform. Users can enhance their profile visibility through various means offered by HaveNude.</p>
            <br />
            <h3 className="font-semibold my-2">5-3 Profile Promotion Options</h3>
            <p>HaveNude may offer different promotion options for user profiles:<br /><br />
                1. Basic listing: Free visibility on the platform<br />
                2. Featured profile: Enhanced visibility for a set period (if applicable)<br />
                3. Premium placement: Priority positioning in search results or on the homepage (if offered)<br />

                Pricing for promotional options, if any, will be clearly displayed on the site.<br /><br />
                </p>
            <br />
            <h3 className="font-semibold my-2">5-4 Content Guidelines</h3>
            <p>Users are responsible for ensuring their content adheres to HaveNude's guidelines and terms of service. HaveNude reserves the right to remove content that violates these guidelines.</p>
            <br />
            <h3 className="font-semibold my-2">5-5 Reporting Inappropriate Content</h3>
            <p>If a user finds content that violates HaveNude's guidelines, they should report it to HaveNude's support team at contact@havenude.com within 5 days of noticing the violation. HaveNude will investigate and take appropriate action.</p>
          </div>
        )}
      </section>

      <section>
        <h2 className="text-xl font-semibold my-4">7. Content Removal and Account Suspension</h2>
        <button onClick={() => toggleSection("contentRemoval")} className="text-blue-600 underline mb-2">
          {expandedSections.contentRemoval ? "Hide" : "Show"} Content Removal and Account Suspension
        </button>
        {expandedSections.contentRemoval && (
          <div className="mb-4">
            <h3 className="font-semibold my-2">6-1 Content Removal</h3>
            <p>HaveNude reserves the right to remove content that violates our terms of service or community guidelines. Users will be notified if their content is removed.</p>
            <br />
            <h3 className="font-semibold my-2">6-2 Account Suspension</h3>
            <p>HaveNude may suspend or terminate accounts that repeatedly violate our terms of service or community guidelines. Users will be notified of any account actions taken.</p>
            <br />
            <h3 className="font-semibold my-2">6-3 Appeals Process</h3>
            <p>If a user believes their content was wrongfully removed or their account incorrectly suspended, they can appeal the decision by contacting contact@havenude.com within 14 days of the action.</p>
            <br />
            <h3 className="font-semibold my-2">6-4 Permanent Content Removal</h3>
            <p>Users can request permanent removal of their content or profile by contacting contact@havenude.com. HaveNude will process these requests within a reasonable timeframe.

            Note: This adapted version removes references to physical item sales, shipping, and refunds, focusing instead on content sharing and profile management, which is more appropriate for HaveNude's purpose as described in the script.

            Here's an adapted version of the Disjointed Liability Policy for HaveNude.com:
            </p>
          </div>
        )}
      </section>

      <section>
        <h2 className="text-xl font-semibold my-4">8. Disjointed Liability Policy</h2>
        <button onClick={() => toggleSection("liabilityPolicy")} className="text-blue-600 underline mb-2">
          {expandedSections.liabilityPolicy ? "Hide" : "Show"} Liability Policy
        </button>
        {expandedSections.liabilityPolicy && (
          <div className="mb-4">
                <h3 className="font-semibold my-2">7-1 GENERAL OBLIGATIONS
                </h3>
                <p>HaveNude and each user, as data controllers, undertake to comply with all applicable personal data protection legislation, including national and international laws. Each party is responsible for fulfilling all legal and regulatory obligations related to personal data protection within their respective scopes.</p>


                <h3 className="font-semibold my-2"> 7-2 OBLIGATIONS FOR DATA TRANSFERS</h3>


                <p>HaveNude implements the necessary legal prerequisites for data communication in compliance with applicable protection regulations, including formalities, informing individuals, and where applicable, obtaining consent when required.

                As a data controller that may not have collected personal data directly from data subjects, HaveNude shall provide users with the following information:
                </p>
                <p>• HaveNude's identity and contact details<br />
                    • The purposes and legal basis for processing personal data<br />
                    • Categories of personal data concerned<br />
                    • Recipients of personal data, if applicable<br />
                    • Intention to transfer personal data to a third country or international organization, if applicable<br />
                    • Data retention period<br />
                    • Users' rights to request access, rectification, erasure, or restriction of processing of their personal data, as well as the right to object to processing and the right to data portability<br />
                    • The right to withdraw consent at any time, where processing is based on consent<br />
                    • The right to lodge a complaint with a supervisory authority<br />
                    • The source of personal data<br />
                    • The existence of automated decision-making, including profiling, if any<br />
                    <br />
                    </p>
                    <p>This information will be provided within a reasonable timeframe, no later than one month after obtaining the data. If the data is used for communication with the user, the information will be provided at the latest at the time of the first communication. If the information is to be communicated to another recipient, it will be provided at the latest when the personal data is first communicated.

                    HaveNude commits to transparency in its data handling practices and to respecting users' privacy rights in accordance with applicable laws and regulations.
                    </p>
          </div>
        )}
      </section>

      <footer className="text-center text-gray-500 mt-10">
        <p>For any questions, please contact us at <a href="mailto:contact@havenude.com" className="text-blue-600">contact@havenude.com</a></p>
      </footer>
    </div>
  );
};

export default TermsOfUse;
