import React from 'react';
import SEO from '../utils/SEO';
import { Link } from "react-router-dom";
import FAQPage from './FAQ';

const AboutUs = () => {
  return (
    <div className="bg-gray-50 min-h-screen overflow-hidden">
            <SEO 
              title="About Us - Havenude" 
              description="The most popular social media platform for sextings. Get thousands of adds for your profiles and browse through thousands of profiles." 
              name="HaveNude" 
              type="website"
            />
      {/* Hero Section */}
      <section className="text-center py-16 px-6 bg-white shadow-md">
        <h1 className="text-4xl sm:text-5xl font-bold text-gray-800 leading-tight">
        Welcome to Havenude : Your best social media exchange content.
        </h1>
        <p className="mt-4 text-gray-600 text-lg sm:text-xl">
        Connecting People, One Profile at a Time.


        </p>
        <div className="mt-8 flex justify-center space-x-4">
          <Link className="px-6 py-3 bg-[#AC3B61] text-white font-semibold rounded-3xl shadow-md hover:bg-[#8e324f] transition" to="/">Get Started</Link>
        </div>
      </section>

      {/* Our Story Section */}
      <section className="py-16 px-6 bg-gray-50">
        <div className="max-w-5xl mx-auto">
          <h2 className="text-3xl sm:text-4xl font-bold text-gray-800 text-center">
            Our Story
          </h2>
          <p className="mt-4 text-gray-600 text-center text-lg sm:text-xl">
          Havenude is an innovative platform that allows you to promote your social and professional profiles with ease. Whether you're an individual looking to expand your network or an entrepreneur aiming to grow your business, Havenude is the tool you need.
          </p>

          <div className="mt-12 grid grid-cols-1 md:grid-cols-2 gap-8 items-center">
            {/* Left Column */}
            <div>
              <h3 className="text-xl font-semibold text-gray-800">
              A Personalized Showcase for Your Social Networks
              </h3>
              <p className="mt-2 text-gray-600">
              Create your unique display featuring your Snapchat media profile². Customize your presence with:
              </p>
              <div className="mt-6 flex space-x-6">
                <div className="flex items-center space-x-3">
                  <span className="w-4 h-4 rounded-full bg-green-500"></span>
                  <p className="text-gray-700 text-sm">Attractive photos</p>
                </div>
                <div className="flex items-center space-x-3">
                  <span className="w-4 h-4 rounded-full bg-yellow-500"></span>
                  <p className="text-gray-700 text-sm">Compelling descriptions</p>
                </div>
                <div className="flex items-center space-x-3">
                  <span className="w-4 h-4 rounded-full bg-pink-500"></span>
                  <p className="text-gray-700 text-sm">Relevant tags</p>
                </div>
              </div>
            </div>

            {/* Right Column */}
            <div>
              <ul className="space-y-6">
                <li className="flex space-x-4">
                  <div className="flex items-center justify-center w-10 h-7 bg-[#AC3B61] text-white rounded-full font-bold">
                    01
                  </div>
                  <div>
                    <h4 className="text-lg font-semibold text-gray-800">
                      Instant Connections
                    </h4>
                    <p className="text-gray-600">
                      Discover and chat with people near you. Build
                      relationships effortlessly with our easy-to-use platform.
                    </p>
                  </div>
                </li>
                <li className="flex space-x-4">
                  <div className="flex items-center justify-center w-10 h-7 bg-[#AC3B61] text-white rounded-full font-bold">
                    02
                  </div>
                  <div>
                    <h4 className="text-lg font-semibold text-gray-800">
                      Privacy First
                    </h4>
                    <p className="text-gray-600">
                      Your safety and privacy are our priorities. Share only
                      what you want, and stay in control of your personal
                      information.
                    </p>
                  </div>
                </li>
                <li className="flex space-x-4">
                  <div className="flex items-center justify-center w-10 h-7 bg-[#AC3B61] text-white rounded-full font-bold">
                    03
                  </div>
                  <div>
                    <h4 className="text-lg font-semibold text-gray-800">
                      Building Communities
                    </h4>
                    <p className="text-gray-600">
                      We're more than just profiles. In the future, we're
                      introducing tools to help create communities and foster
                      deeper connections between users.
                    </p>
                  </div>
                </li>
              </ul>
            </div>
          </div>
          <h2 className="text-3xl sm:text-4xl font-bold text-gray-800 text-center pt-28">
            Two Distinct Spaces to Meet Your Needs
          </h2>
          
          <div className="mt-12 grid grid-cols-1 md:grid-cols-2 gap-8 items-center">
            {/* Left Column */}
            <div>
              <h3 className="text-xl font-semibold text-gray-800">
              Open Space: Connect Freely
              </h3>
              <p className="mt-2 text-gray-600">
              Our main page is open to all registered users. Share your links, exchange nudes or promote your activity in a fun and interactive way.
              </p>
              <div className="mt-6 flex space-x-6">
                
              </div>
            </div>

            {/* Right Column */}
            <div>
              <h3 className="text-xl font-semibold text-gray-800">
              Verified Space: Enhanced Credibility and Security
              </h3>
              <p className="mt-2 text-gray-600">
              For those seeking a more professional environment, our verified page is the ideal place. Accessible only to users who have validated their identity through our ID recognition system, this space offers:
              </p>
              <ul className="space-y-6 pt-14">
                <li className="flex space-x-4">
                  <div className="flex items-center justify-center w-10 h-7 bg-[#AC3B61] text-white rounded-full font-bold">
                    01
                  </div>
                  <div>
                    <h4 className="text-lg font-semibold text-gray-800">
                    Reinforced credibility for your business
                    </h4>
                    
                  </div>
                </li>
                <li className="flex space-x-4">
                  <div className="flex items-center justify-center w-10 h-7 bg-[#AC3B61] text-white rounded-full font-bold">
                    02
                  </div>
                  <div>
                    <h4 className="text-lg font-semibold text-gray-800">
                    Guaranteed interaction with authentic profiles
                    </h4>
                    
                  </div>
                </li>
                <li className="flex space-x-4">
                  <div className="flex items-center justify-center w-10 h-7 bg-[#AC3B61] text-white rounded-full font-bold">
                    03
                  </div>
                  <div>
                    <h4 className="text-lg font-semibold text-gray-800">
                    An optimal setting for professional networking
                    </h4>
                    
                  </div>
                </li>
              </ul>
            </div>
          </div>
          <h2 className="text-3xl sm:text-4xl font-bold text-gray-800 text-center pt-28">
            Why Choose Havenude?
          </h2>
          
          <ul className="space-y-6 pt-14 flex flex-col justify-center">
              <li className="flex space-x-4 mt-6">
                <div className="flex items-center justify-center w-10 h-7 bg-[#AC3B61] text-white rounded-full font-bold">
                  01
                </div>
                <div>
                  <h4 className="text-lg font-semibold text-gray-800">
                  Versatility
                  </h4>
                  <p>Ideal for personal exchanges or business promotion
                  </p>
                </div>
              </li>
              <li className="flex space-x-4 pl-10 md:pl-20">
                <div className="flex items-center justify-center w-10 h-7 bg-[#AC3B61] text-white rounded-full font-bold">
                  02
                </div>
                <div>
                  <h4 className="text-lg font-semibold text-gray-800">
                  Security
                  </h4>
                  <p>Our page ensures interactions with authentic profiles
                  </p>
                </div>
              </li>
              <li className="flex space-x-4 pl-20 md:pl-40">
                <div className="flex items-center justify-center w-10 h-7 bg-[#AC3B61] text-white rounded-full font-bold">
                  03
                </div>
                <div>
                  <h4 className="text-lg font-semibold text-gray-800">
                  Maximum Visibility
                  </h4>
                  <p>Your profile stays in view until a new publication replaces it
                  </p>
                </div>
              </li>
              <li className="flex space-x-4 pl-28 md:pl-60 ">
                <div className="flex items-center justify-center w-10 h-7 bg-[#AC3B61] text-white rounded-full font-bold">
                  03
                </div>
                <div>
                  <h4 className="text-lg font-semibold text-gray-800">
                  Simplicity
                  </h4>
                  <p>An intuitive interface to create and manage your online presence

                  </p>
                </div>
              </li>
            </ul>
            <h2 className="text-3xl sm:text-4xl font-bold text-gray-800 text-center pt-28">
            Join Havenude today and give a new dimension to your social media presence!

          </h2>
          <p className="mt-4 text-gray-600 text-center text-lg sm:text-xl">
          Have nude ? Yes, do it 😉

          </p>
        </div>
      </section>
      {/* FAQ Section */}
      <FAQPage /> {/* Add FAQPage below About Us */}
    </div>
  );
};

export default AboutUs;