// src/utils/profileImageService.js
import { getPhoto } from '../redux/features/photoSlice'; // Assure-toi que le chemin est correct

export const checkProfileImage = async (profileImageRedux, setProfileImage, dispatch) => {
  try {

    // Vérifier si l'image existe dans Redux et si elle est encore valide
    if (profileImageRedux) {
    
        //console.log("Using existing photo in Redux:", profileImageRedux);
        setProfileImage(profileImageRedux);
        return;
      
    }

    // Si l'image n'existe pas dans Redux, on la récupère depuis l'API
    try {
      if( profileImageRedux=="ded"){

      }
      const resultAction = await dispatch(getPhoto({ photoId: 'photoprofil' })).unwrap();
      setProfileImage(resultAction.photoUrl);
     
      
    } catch (error) {
      //console.info('Failed to fetch a new photo URL:', error);
      setProfileImage('/assets/blank-profile-login.jpg'); // Fallback to default image
    } 
    
  } catch (error) {
    //console.error("Error fetching photo:", error);
    setProfileImage('/assets/blank-profile-login.jpg');
  }
};
