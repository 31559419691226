import React, { useState, useEffect, useRef } from 'react';
import { NavLink } from 'react-router-dom';

const ProfileNav = () => {
  const [showDropdown, setShowDropdown] = useState(false);
  const [activePath, setActivePath] = useState('settings'); // Set default active path to settings
  const dropdownRef = useRef(null);

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setShowDropdown(false);
    }
  };

  useEffect(() => {
    if (showDropdown) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [showDropdown]);

  const navLinks = [
    { name: 'My Post', path: 'informations' },
    { name: 'Photo', path: 'photo' },
  ];

  return (
    <div className="bg-white shadow-md rounded-t-lg p-4 mt-10 border-b-2 border-gray-200">
      <nav className="flex justify-between">
        {navLinks.map((link, index) => (
          <NavLink
            key={link.name}
            to={link.path}
            className={`text-gray-600 hover:text-[#b36881] hover:bg-gray-100 px-4 py-2 font-semibold text-center flex-grow rounded transition-colors ${
              index < 3 ? 'block' : 'hidden md:block'
            }`}
            style={({ isActive }) =>
              isActive
                ? { color: '#AC3B61', backgroundColor: '#fff3f5', borderBottom: '2px solid #AC3B61' }
                : {}
            }
            onClick={() => setActivePath(link.path)}
          >
            {link.name}
          </NavLink>
        ))}
        
      </nav>
    </div>
  );
};

export default ProfileNav;
