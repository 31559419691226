import React from 'react';
import { useState, useEffect, useRef } from 'react';
import Post from '../components/HomeComponents/Post';
import SubscriptionInfo from '../components/HomeComponents/SubscriptionInfo';
import Filters from '../components/HomeComponents/Filters';
import FilterSmallScreen from '../components/HomeComponents/FilterSmallScreen';
import { fetchPosts } from '../api/postService'; 
import LoginModal from '../components/Modal/LoginModal';
import SignupModal from '../components/Modal/SignUpModal';
import ResetPasswordModal from '../components/Modal/ResetPasswordModal';
import { useSelector } from 'react-redux'; // Import de useSelector
import { useNavigate, useLocation } from 'react-router-dom'; // Import useLocation
import { ThreeDots } from 'react-loader-spinner';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSyncAlt } from '@fortawesome/free-solid-svg-icons';
import toast from 'react-hot-toast';
import { Helmet } from 'react-helmet';
import { faLock } from '@fortawesome/free-solid-svg-icons';
import SEO from '../utils/SEO';
import Advertisement from '../utils/Advertisement';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

const PostSkeleton = () => {
  return (
    <div className="bg-white p-4 rounded-lg shadow-md animate-pulse">
      <div className="flex items-center space-x-4">
        <Skeleton circle width={50} height={50} />
        <div>
          <Skeleton width={150} height={20} />
          <Skeleton width={100} height={15} />
        </div>
      </div>
      <Skeleton width="100%" height={200} className="my-4" />
      <Skeleton width="80%" height={20} />
    </div>
  );
};

const Home = () => {
  const [isLoginModalOpen, setLoginModalOpen] = useState(false);
  const [isSignupModalOpen, setSignupModalOpen] = useState(false);
  const [isResetPasswordModalOpen, setResetPasswordModalOpen] = useState(false);

  const openLoginModal = () => setLoginModalOpen(true);
  const closeLoginModal = () => setLoginModalOpen(false);
  const openSignupModal = () => setSignupModalOpen(true);
  const closeSignupModal = () => setSignupModalOpen(false);
  const openResetPasswordModal = () => setResetPasswordModalOpen(true);
  const closeResetPasswordModal = () => setResetPasswordModalOpen(false);
  const [isFiltering, setIsFiltering] = useState(false);

  const [isLoading, setIsLoading] = useState(false);

  const [posts, setPosts] = useState([]);
  const navigate = useNavigate()
  const isAuthenticated = useSelector((state) => state.user.isAuthenticated);
  const user = useSelector((state) => state.user.user);
  const [isRefreshing, setIsRefreshing] = useState(false);
  const [currentPage, setCurrentPage] = useState(1); // Current page number
  const [hasMorePosts, setHasMorePosts] = useState(true); // Check if there are more posts
  const hasRestoredFromSession = useRef(false); // Flag pour vérifier si la restauration s'est produite


  // Construire les tags dynamiquement
  const userTags = [];
  if (isAuthenticated) {
    if (user.tier === 'VIP') userTags.push('VIP');
    if (user.isCertified) userTags.push('Certified');
    if (user.tier === 'Free' && userTags.length === 0) userTags.push('Free');
  }

  const [filters, setFilters] = useState({}); // État pour stocker les filtres

  const handleFiltersChange = (newFilters) => {
    setIsFiltering(true); // Active le skeleton

    // Transformer les filtres reçus dans le bon format attendu par le backend
    const transformedFilters = {
      username: newFilters.username || undefined,
      age_min: newFilters.age ? newFilters.age[0] : undefined,
      age_max: newFilters.age ? newFilters.age[1] : undefined,
      orientation: newFilters.orientation !== 'All' ? newFilters.orientation : undefined,
      gender: newFilters.gender !== 'All' ? newFilters.gender : undefined,
      tags: newFilters.tags.length > 0 ? newFilters.tags : undefined,
      country: newFilters.country || undefined,
      isVIP: newFilters.isVIP || undefined,
    };

    // Supprimer les propriétés undefined pour n'envoyer que les filtres sélectionnés
    const filteredFilters = Object.fromEntries(
      Object.entries(transformedFilters).filter(([_, value]) => value !== undefined)
    );

    setFilters(filteredFilters);
    setHasMorePosts(true);
    //console.log("load post dans handle filter change")
    loadPosts(filteredFilters); // Recharger les posts avec les filtres sélectionnés
  };

  const postsRef = useRef([]);
  const scrollPositionRef = useRef(0);

  const loadPosts = async (appliedFilters = {}, page = 1) => {
    try {
      setIsLoading(true);
      const posts = await fetchPosts({ ...appliedFilters }, page); // Include the page number in the request
      sessionStorage.setItem('currentPage', page);
      const { fire_pin, users } = posts; // Get fire_pin and users from the response

      //console.log("load post ici" + users)


      if (users.length === 0) {
        //console.log("no more posts")
        toast.error("No more posts.");
        sessionStorage.setItem('currentPage', page - 1);
        // If there are no posts, set hasMorePosts to false and adjust currentPage
        setHasMorePosts(false);
        return; // Ne rien changer dans les posts ni la page courante
      }
  
      const formattedPosts = posts.users.map((currentPost) => {
        //console.log("current post", formattedPosts)
        const images = currentPost.user_photos
          ? Object.entries(currentPost.user_photos)
              .filter(([key]) => key !== 'photoprofil')
              .map(([, url]) => url)
          : [];
  
        return {
          author: {
            name: currentPost.username,
            image: currentPost.user_photos?.photoprofil || '/assets/blank-profile-picture.png',
            id: currentPost.user_id,
            twitter_username: currentPost.twitter_username || null, // Ajout du nom d'utilisateur Twitter
            of_username: currentPost.of_username || null,    // Ajout du nom d'utilisateur OnlyFans
            insta_username: currentPost.insta_username || null
          },
          tier: currentPost.tier,
          sexe: currentPost.gender,
          orientation: currentPost.orientation,
          age: currentPost.age,
          description: currentPost.description,
          images,
          country: currentPost.country,
          tags: currentPost.tags,
          last_top_up: currentPost.last_top_up
        };
      });

      // Ajouter le fire_pin en haut si présent
      const formattedFirePin = fire_pin
      ? {
          author: {
            name: fire_pin.username,
            image: fire_pin.user_photos?.photoprofil || '/assets/blank-profile-picture.png',
            id: fire_pin.user_id,
          },
          tier: fire_pin.tier,
          sexe: fire_pin.gender,
          orientation: fire_pin.orientation,
          age: fire_pin.age,
          description: fire_pin.description,
          images: fire_pin.user_photos
            ? Object.entries(fire_pin.user_photos)
                .filter(([key]) => key !== 'photoprofil')
                .map(([, url]) => url)
            : [],
          country: fire_pin.country,
          tags: fire_pin.tags,
          firePinExpiresAt: fire_pin.fire_pin_expires_at
        }
      : null;

      const allPosts = formattedFirePin ? [formattedFirePin, ...formattedPosts] : formattedPosts;
  
      // Instead of appending, replace the current posts with the new set
    setPosts(allPosts);
    postsRef.current = allPosts; // Persist new posts in ref
    sessionStorage.setItem('posts', JSON.stringify(postsRef.current));
    window.scrollTo({ top: 0, behavior: 'smooth' });

    } catch (error) {
      toast.error("Failed to load posts.");
      console.error('Error loading posts:', error);
    } finally {
      setIsLoading(false);
      setIsRefreshing(false);
      setIsFiltering(false);
    }
  };

  const handleNextPage = () => {
    if (hasMorePosts) {
      // Update currentPage and load posts
      setCurrentPage((prevPage) => {
        const nextPage = prevPage + 1;
        sessionStorage.setItem('currentPage', nextPage);
        loadPosts(filters, nextPage); // Load posts for the next page
        return nextPage;
      });
    }
  };
  
  const handlePreviousPage = () => {
    if (currentPage > 1) {
      // Update currentPage and load posts
      setCurrentPage((prevPage) => {
        setHasMorePosts(true);
        const newPage = prevPage - 1;
        sessionStorage.setItem('currentPage', newPage);
        loadPosts(filters, newPage); // Load posts for the previous page
        return newPage;
      });
    }
  };
  

   // Function to handle refresh
   const handleRefresh = async () => {
    if (isRefreshing) return; // Prevent multiple refreshes
    setIsRefreshing(true);

    try {
      // Reload posts
      //console.log("refresh dans load post ici")
      await loadPosts(filters);
      setCurrentPage(1);
      setHasMorePosts(true)

      sessionStorage.setItem('currentPage', 1);

    } catch (error) {
      toast.error("Failed to refresh posts.");
      //console.error('Error refreshing posts:', error);
    } finally {
      setIsRefreshing(false);
    }
  };

   // Detect page reload and set a flag
   useEffect(() => {
    const handleBeforeUnload = () => { sessionStorage.setItem('isReload', 'true'); };
    window.addEventListener('beforeunload', handleBeforeUnload);
    return () => {window.removeEventListener('beforeunload', handleBeforeUnload);};
  }, []);

  useEffect(() => {
    const savedPosts = sessionStorage.getItem('posts');
    const savedPage = sessionStorage.getItem('currentPage');
    const savedScrollPosition = sessionStorage.getItem('scrollPosition');
   

    if (savedPosts && savedPage && !hasRestoredFromSession.current) {
      //console.log("savvee")
      setPosts(JSON.parse(savedPosts));
      setCurrentPage(parseInt(savedPage, 10));
      scrollPositionRef.current = parseInt(savedScrollPosition, 10);
      //window.scrollTo(0, scrollPositionRef.current);
      hasRestoredFromSession.current = true; // Marque que la restauration s'est produite
    } else {
      //console.log("load post si on a pas save")

      loadPosts({}, currentPage);
    }
  }, []);

  const handleProfileClick = (userId, profileData) => {
    postsRef.current = posts;
    scrollPositionRef.current = window.scrollY; // Save current scroll position

    // Save posts and scroll position to sessionStorage
    sessionStorage.setItem('posts', JSON.stringify(postsRef.current));
    sessionStorage.setItem('scrollPosition', scrollPositionRef.current.toString());
    //console.log("la cest le set scroll" + scrollPositionRef.current.toString())

    navigate(`/public-profile/${userId}`, {
      state: { ...profileData },
    });
  };

  const handleFeedChange = (feed) => {
    setSelectedFeed(feed);
    //console.log("Feed : ",feed);
  };

  //const [selectedOffers, setSelectedOffers] = useState(offers); // Default to two years subscription
  //const [selectedCategory, setSelectedCategory] = useState('twoYears'); // Default to two years subscription

  const [selectedFeed, setSelectedFeed] = useState('Public_Feed'); // Default Public_Feed


  return (
    <div 
    className="flex flex-col w-full p-4 space-y-4 lg:space-y-0 lg:space-x-4 bg-gray-100">
      
      <SEO 
        title="Havenude.com - Find your sexting friends from SnapChat." 
        description="Welcome to the most popular platform for social Sexting including SnapChat, Twitter ... Get thousands of adds for your SnapChat Usernames, browse through thousands of new SnapChat nudes" 
        name="HaveNude" 
        type="website"
      />

      {/* SubscriptionInfo on top for small screens */}
      <div className=" relative lgplus:hidden lg:px-32 md:px-20 sm:px-10 px-2 py-10 flex justify-center ">
          {isAuthenticated ? (
              <SubscriptionInfo
                title="Your Bonuses"
                tags={userTags}
                onViewSubscription={() => {
                  // Naviguer vers la page de gestion d'abonnement
                  navigate('/profile-info/payment');
                }}
                onViewOffers={() => {
                  // Naviguer vers la page des offres d'abonnement
                  navigate('/shop');
                }}
                description="Welcome back - Enjoy your time on Have Nude."
                handleRefresh={handleRefresh}
              />
            ) : (
              <SubscriptionInfo
                title="Join Us !"
                tags={userTags}
                description="Sign up to publish content, unlock premium features and exclusive content."
                onLearnMore={openSignupModal}
              />
            )}
      </div>

      <div className="flex justify-center pb-8">
      <div className="inline-flex space-x-2 bg-gray-100 p-2 rounded-full border-2">

        <button
          className={`px-5 py-3 rounded-3xl transition-all duration-300 ease-in-out transform ${
            selectedFeed === 'Public_Feed'
              ? 'bg-[#AC3B61] text-white scale-105'
              : 'bg-gray-100 text-gray-400 hover:text-black hover:scale-105'
          }`}
          onClick={() => handleFeedChange('Public_Feed')}
        >
          Public Feed
        </button>

        <button
          className={`px-5 py-3 rounded-3xl flex items-center space-x-2 transition-all duration-300 ease-in-out transform bg-gray-100 text-gray-400`}
          disabled={true} // Disable the button
        >
          <span>VIP Feed</span>
          <FontAwesomeIcon icon={faLock} className="text-gray-500" /> {/* Lock icon */}
        </button>

        </div>
        
      </div>

      {/* Publicité */}
      <Advertisement
        classNameMobile="eas6a97888e10"
        dataZoneIdMobile="5521186"
        classNameDesktop="eas6a97888e2"
        dataZoneIdDesktop="5521188"
      />
      
      <FilterSmallScreen handleRefresh={handleRefresh} onFiltersChange={handleFiltersChange}/> {/* Include FilterBar for small screens */}
      
      <div className="flex flex-col lg:flex-row lgplus:flex lgplus:flex-row lg:space-x-4">
        {/* Sidebar for filters */}
        <div className="lgplus:w-1/4 lg:w-1/3 lg:flex justify-center hidden lg:mb-10">
          <div className="self-start w-full sticky top-28 h-auto"> {/* Hauteur automatique */}
            <Filters onFiltersChange={handleFiltersChange} />
          </div>
        </div>
        
        {/* Main content area */}
        <div
          className={`lgplus:w-1/2 lg:w-2/3 w-full lg:pr-8 transition-opacity duration-500 ${
            isLoading ? 'opacity-0' : 'opacity-100'
          }`}
        >
          <div className="justify-between items-center mb-4 hidden lg:flex">
            
            <button
              onClick={handleRefresh}
              className="text-gray-600 hover:text-gray-800 transition-all duration-200 focus:outline-none pr-8"
            >
              <FontAwesomeIcon icon={faSyncAlt} size="2x" spin={isRefreshing} />
            </button>
          </div>
          
          
          {isFiltering || isRefreshing ? (
              // 🟢 Afficher les skeletons pendant le refresh
              <div className="space-y-6">
                {Array.from({ length: 5 }).map((_, index) => (
                  <PostSkeleton key={index} />
                ))}
              </div>
            ) : posts.length === 0 ? (
              <div className="flex justify-center items-center w-full h-96">
                <p className="text-gray-500 text-xl">No posts found. Try adjusting your filters.</p>
              </div>
            ) : (
            posts.map((post, index) => (
              <div key={index} className="mb-10">
                <Post {...post} onProfileClick={handleProfileClick}/>
                  {/* Ajouter une publicité toutes les 5 posts */}
                  {(index + 1) % 5 === 0 && (
                    <div className="mb-10">
                      {/* Publicité */}
                      <Advertisement
                        classNameMobile="eas6a97888e10"
                        dataZoneIdMobile="5521196"
                        classNameDesktop="eas6a97888e2"
                        dataZoneIdDesktop="5521194"
                      />
                    </div>
                  )}
              </div>
              
            ))

          )}
          <div className="flex justify-between mt-4">
            <button
              onClick={handlePreviousPage}
              className="px-4 py-2 bg-gray-200 text-gray-800 rounded disabled:opacity-50"
              disabled={parseInt(sessionStorage.getItem('currentPage'), 10) === 1 || currentPage === 1} // Check sessionStorage and currentPage
            >
              Previous
            </button>
            {/* Affichage du numéro de page */}
            <span className="px-2 py-2 text-gray-800 font-medium mt-4">{currentPage}</span>
            <button
              onClick={handleNextPage}
              className="px-4 py-2 bg-gray-200 text-gray-800 rounded disabled:opacity-50"
              disabled={!hasMorePosts} // Disable if there are no more posts
            >
              Next
            </button>
          </div>
        </div>

        {/* SubscriptionInfo on side for large screens */}
        <div className="lg:w-1/4 lgplus:flex justify-center hidden">
          <div className="self-start w-full sticky top-40 flex justify-center">
            {isAuthenticated ? (
              <SubscriptionInfo
                title="Your Bonuses"
                tags={userTags}
                onViewSubscription={() => {
                  // Naviguer vers la page de gestion d'abonnement
                  navigate('/profile-info/payment');
                }}
                onViewOffers={() => {
                  // Naviguer vers la page des offres d'abonnement
                  navigate('/shop');
                }}
                description="Welcome back - Enjoy your time on Have Nude."
                handleRefresh={handleRefresh}
              />
            ) : (
              <SubscriptionInfo
                title="Join Us !"
                tags={userTags}
                description="Sign up to publish content, unlock premium features and exclusive content."
                onLearnMore={openSignupModal}
              />
            )}
          </div>
        </div>
         
      </div>
      {/* Publicité */}
      <Advertisement
        classNameMobile="eas6a97888e10"
        dataZoneIdMobile="5521186"
        classNameDesktop="eas6a97888e2"
        dataZoneIdDesktop="5521188"
      />
      
      {/* Modals */}
      <LoginModal isOpen={isLoginModalOpen} onRequestClose={closeLoginModal} openSignupModal={openSignupModal} openResetPasswordModal={openResetPasswordModal}/>
      <SignupModal isOpen={isSignupModalOpen} onRequestClose={closeSignupModal} openLoginModal={openLoginModal} />
      <ResetPasswordModal isOpen={isResetPasswordModalOpen} onRequestClose={closeResetPasswordModal} openLoginModal={openLoginModal}  />
    </div>
  );
};

export default Home;
