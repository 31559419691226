import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import './css/modal.css'; 
import Button from '../Buttons/Button';
import authService from '../../api/authService';
Modal.setAppElement('#root'); // Set root element for accessibility

const ResetPasswordModal = ({ isOpen, onRequestClose, openLoginModal }) => {

  const [isLoading, setIsLoading] = useState(false); // État de chargement
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState({ text: '', color: '' });
  const [cooldown, setCooldown] = useState(0); // État pour gérer le délai d'attente
  
  const cooldownTime = 60; // Délai d'attente en secondes

  const customStyles = { // Custom styles for the modal
    content: {
      position: 'none',
      background: 'white',
      outline: 'none',
      margin: 'auto',
      width: '90%', // Default width for small screens
      padding: '0',
      maxWidth: '500px', // Max width to prevent it from becoming too large on big screens
      borderRadius: '0.5rem',
    },
    overlay: {
      backgroundColor: 'rgba(0, 0, 0, 0.9)',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
  };

  const handleReset = async (e) => {
    e.preventDefault();
    try {
      setMessage({ text: '', color: '' });
      setIsLoading(true);
      await authService.resetPassword({ email });
      setMessage({ text: 'A reset email has been sent!', color: 'green' });
      setCooldown(cooldownTime); // Démarre le délai d'attente après un envoi réussi
    } catch (error) {
      setMessage({ text: 'Password reset failed.', color: 'red' });
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (cooldown > 0) {
      const timer = setInterval(() => {
        setCooldown((prev) => prev - 1);
      }, 1000);

      return () => clearInterval(timer);
    }
  }, [cooldown]);

  useEffect(() => { // Reset when modal opens or closes
    if (!isOpen) {
      setEmail('');
      setMessage({ text: '', color: '' });
      setIsLoading(false);
      setCooldown(0);
    }
  }, [isOpen]);

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      style={customStyles}
      shouldCloseOnOverlayClick={true} // Close the modal when clicking outside
    >
      <div className="relative bg-white  shadow">
        <button
          type="button"
          className="absolute top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center"
          onClick={onRequestClose}
        >
          <svg
            aria-hidden="true"
            className="w-5 h-5"
            fill="#c6c7c7"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
              clipRule="evenodd"
            ></path>
          </svg>
          <span className="sr-only">Close popup</span>
        </button>

        <div className="p-10">
          <div className="text-center">
            <p className="mb-3 text-2xl font-semibold leading-5 text-slate-900">
              Reset your password
            </p>
          </div>

          
          <div className="flex w-full items-center gap-2 py-6 text-sm text-slate-600">
            <p>Enter your email address, and we'll send you a link to reset your password.</p>
          </div>

          <form className="w-full" onSubmit={handleReset}>
            <label htmlFor="email" className="sr-only">
              Email address
            </label>
            <input
              name="email"
              type="email"
              autoComplete="email"
              required
              className="block w-full rounded-lg border border-gray-300 px-3 py-2 shadow-sm outline-none placeholder:text-gray-400"
              placeholder="Email Address"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              disabled={cooldown > 0} // Désactiver pendant le délai d'attente
            />

            <Button
              type="submit"
              variant={'filled'}
              className="w-full mt-3"
              disabled={isLoading || cooldown > 0} // Désactiver pendant le délai d'attente
            >
              {isLoading ? 'Sending email...' : cooldown > 0 ? `Wait before resending an email ${cooldown}s` : 'Send email'}
            </Button>
          </form>

          {/* Affichage du message */}
          {message.text && (
            <div className={`mt-3 mb-3 text-sm text-${message.color}-600 bg-${message.color}-100 border border-${message.color}-400 text-${message.color}-700 px-4 py-3 rounded mb-4`}>
              {message.text}
            </div>
          )}

          <div className="mt-6 text-center text-sm text-slate-600">
            Already have an account?
            <button onClick={() => { onRequestClose(); openLoginModal(); }} className="font-medium text-[#4285f4]">
              Log In
            </button>
          </div>

        </div>
      </div>
    </Modal>
  );
};

export default ResetPasswordModal;