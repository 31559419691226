// src/redux/features/photoSlice.js
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { uploadPhotoAPI, getPhotoAPI, deletePhotoAPI } from "../../api/storagePhoto";

const initialState = {
  photos: {},
  uploadStatus: 'idle',  // Status pour upload
  getStatus: 'idle',     // Status pour get
  deleteStatus: 'idle',  // Status pour delete
  error: null,
};

// Thunks
export const uploadPhoto = createAsyncThunk(
  "photos/uploadPhoto",
  async ({ photoId, file }, { rejectWithValue }) => {
    try {
      const photoUrl = await uploadPhotoAPI(photoId, file);
      return { photoId, photoUrl };
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

// Thunks
export const uploadPhotoWithoutStorage = createAsyncThunk(
  "photos/uploadPhotoWithoutStorage",
  async ({ photoId, file }, { rejectWithValue }) => {
    try {
      //console.log("Before calling uploadPhotoAPI");
      const photoUrl = await uploadPhotoAPI(photoId, file);
      //console.log("API call succeeded", photoUrl);
      return { photoId, photoUrl };
    } catch (error) {
      //console.log("API call failed", error);
      return rejectWithValue(error.message);
    }
  }
);

export const getPhoto = createAsyncThunk(
  "photos/getPhoto",
  async ({ photoId }, { rejectWithValue }) => {
    try {
      //console.log("Before calling getPhotoAPI");
      //console.log("photoId", photoId);
      const data = await getPhotoAPI(photoId);

      // Vérifiez si la réponse contient un URL valide
      if (data.exists) {
        //console.log("Photo URL:", data.photo_url);
        return { photoId, photoUrl: data.photo_url };
      } else {
        //console.log("Pas de photo de profil trouvée, utilisation de l'image par défaut.");
        return { photoId, photoUrl: '/assets/blank-profile-login.jpg' };
      }
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const deletePhoto = createAsyncThunk(
  "photos/deletePhoto",
  async ({ photoId}, { rejectWithValue }) => { // Accepts both photoId and index
    try {
      const { photoIdd, message } = await deletePhotoAPI(photoId); // Capture both photoId and message from the API
      return { photoIdd, message }; // Return both photoId and index
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

const photoSlice = createSlice({
  name: "photos",
  initialState,
  reducers: {
    resetPhotoState(state) {
      return initialState; // Réinitialise l'état du slice
    },
  },
  extraReducers: (builder) => {
    builder
      // Upload Photo
      .addCase(uploadPhoto.pending, (state) => {
        state.uploadStatus = 'pending';  // Gère le statut d'upload
        state.error = null;
      })
      .addCase(uploadPhoto.fulfilled, (state, action) => {
        state.uploadStatus = 'succeeded';
        state.photos[action.payload.photoId] = action.payload.photoUrl;
          
      })
      .addCase(uploadPhoto.rejected, (state, action) => {
        state.uploadStatus = 'rejected';
        state.error = action.payload;
      })

      // Get Photo
      .addCase(getPhoto.pending, (state) => {
        state.getStatus = 'pending';  // Gère le statut pour getPhoto
        state.error = null;
      })
      .addCase(getPhoto.fulfilled, (state, action) => {
        state.getStatus = 'succeeded';
        state.photos[action.payload.photoId] = action.payload.photoUrl;
      })
      .addCase(getPhoto.rejected, (state, action) => {
        state.getStatus = 'rejected';
        state.error = action.payload;
      })

      // Delete Photo
      .addCase(deletePhoto.pending, (state) => {
        state.deleteStatus = 'pending';  // Gère le statut pour delete
        state.error = null;
      })
      .addCase(deletePhoto.fulfilled, (state, action) => {
        state.deleteStatus = 'succeeded';
        delete state.photos[action.payload];
      })
      .addCase(deletePhoto.rejected, (state, action) => {
        state.deleteStatus = 'rejected';
        state.error = action.payload;
      });
  },
});

export const { resetPhotoState } = photoSlice.actions;
export default photoSlice.reducer;
