import React from 'react';
import Button from '../../components/Buttons/Button';
import { useState } from 'react';
import { createPaymentSession } from '../../api/stripeService';
import { loadStripe } from "@stripe/stripe-js";
import { Oval } from 'react-loader-spinner';
import toast from 'react-hot-toast';
import { useSelector } from 'react-redux';

const stripePromise = loadStripe("pk_test_51PqdBDGDrrsa3O4D12tg1VmGEC3tmyEA05Z16zUnHFMfoBrzhtxMR6dslFFTLFUveMOrWJJW3aWCqAkLGHbGHWDe000Gibnfz8");


const OrderSummary = ({ offer, wantsInvoice }) => {

  const [isLoading, setIsLoading] = useState(false); // État de chargement
  //console.log(offer)

  const isAuthenticated = useSelector((state) => state.user.isAuthenticated); // Exemple avec Redux


  const handleCheckout = async () => {

    if (!isAuthenticated) {
      // Show a toast message if the user is not authenticated
      toast.error("You must be logged in to make a payment.");
      return;
    }

    try {
      setIsLoading(true);
      //console.log(offer.stripeId)
      //console.log(wantsInvoice)
      const sessionId = await createPaymentSession(offer.stripeId, wantsInvoice); // Passez l'ID et l'option de facture

      // Charger Stripe.js et rediriger vers la page de paiement
      const stripe = await stripePromise;
      const result = await stripe.redirectToCheckout({ sessionId });

      if (result.error) {
        toast.error("Error during redirection to Stripe Checkout.");
        //console.error("Erreur lors de la redirection vers Stripe Checkout", result.error.message);
      }
    } catch (error) {
      toast.error("Error during redirection to Stripe Checkout.");
      //console.error("Erreur lors de la création de la session Stripe", error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
<>
      
    <h2 className="text-2xl font-medium mb-8">Order Summary</h2>


    <div className="bg-white rounded-2xl border p-6">
      <div className="flex items-center mb-4">
        <img src={offer.logo} alt={offer.title} className="w-10 h-10 mr-2" />
        <strong>{offer.title}</strong>
      </div>
      <hr className="my-4"/>
      <div className="mb-2">
        <p className="text-sm">{offer.description}</p>
        <p className="text-sm text-red-500 font-semibold">{offer.discount}</p>
      </div>
      <div className="flex justify-between items-center mb-2">
        <span>Subscription {offer.period}</span>
        {offer.originalPrice ? 
          <span className="line-through text-gray-500">{offer.originalPrice} €</span> : null
        }
        
      </div>
      <div className="flex justify-between items-center mb-2">
        <span>Price </span>
        <span>{offer.price} €</span>
      </div>
      
      <hr className="my-4"/>

      <div className="flex justify-between items-center mb-4">
        <strong>Total</strong>
        <strong>{offer.price} €</strong>
      </div>
     
      <div className="flex justify-end">
      <Button
          onClick={handleCheckout}
          type="submit"
          variant={'outline'}
          className="w-full mt-3 flex justify-center items-center"  // Ajoutez flexbox ici
          disabled={isLoading}
        >
          {isLoading ? (
            <Oval
              height={20}
              width={20}
              color="#FFFFFF"
              wrapperStyle={{}}
              wrapperClass=""
              visible={true}
              ariaLabel='oval-loading'
              secondaryColor="#FFFFFF"
              strokeWidth={9}
              strokeWidthSecondary={8}
            />
          ) : (
            'Pay'
          )}
        </Button>
      </div>
    </div>
</>
  );
};

export default OrderSummary;
