// src/api/postService.js
import apiClient from './apiClient'; // Assuming you have an axios instance

// Fetch posts (top-ups) with pagination or a limit
export const fetchPosts = async (filters = {}, page = 1) => {
  try {
    // Include the page number as a query parameter
    const response = await apiClient.post(`/search?page=${page}`, filters);
    //console.log("response fetch post", response.data);
    return response.data; // Adjust this if your API returns a different structure
  } catch (error) {
    //console.error('Error fetching posts:', error);
    throw error;
  }
};
