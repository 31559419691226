import React, { useState, useRef, useEffect } from 'react';
import { FaTrash, FaExpand } from 'react-icons/fa';
import ImageGallery from 'react-image-gallery';
import "react-image-gallery/styles/css/image-gallery.css";
import './css/photo.css';
import { uploadPhotoWithoutStorage, deletePhoto } from '../../redux/features/photoSlice'; // Import redux actions
import { useDispatch, useSelector } from 'react-redux';
import { getAvailablePhotosAPI } from '../../api/storagePhoto';
import SEO from '../../utils/SEO';
import toast from 'react-hot-toast';

const MAX_FILE_SIZE_MB = 3; // Set maximum file size in MB
const ALLOWED_FILE_TYPES = ['image/jpeg', 'image/png', 'image/gif']; // Allowed file types
const MAX_PHOTOS = 5;

const Photo = () => {
  const dispatch = useDispatch();
  const idToken = useSelector((state) => state.user.idToken);
  const [isViewerOpen, setIsViewerOpen] = useState(false);
  const [currentPhotoIndex, setCurrentPhotoIndex] = useState(0);
  const galleryRef = useRef(null);
  const [photos, setPhotos] = useState([]);
  const [loading, setLoading] = useState(true); // Ajout de l'état de chargement

  const openViewer = (index) => {
    setCurrentPhotoIndex(index);
    setIsViewerOpen(true);
  };

  const closeViewer = () => {
    setIsViewerOpen(false);
  };

  const handleAddPhoto = (event) => {
    event.preventDefault();
  
    const file = event.target.files[0];
    if (file) {
      // Validate file size
      const fileSizeInMB = file.size / (1024 * 1024); // Convert bytes to MB
      //console.log("File size:", fileSizeInMB);  
      if (fileSizeInMB > MAX_FILE_SIZE_MB) {
        //console.error("File is too large.");
        toast.error(`File size exceeds ${MAX_FILE_SIZE_MB}MB. Please upload a smaller file.`);
        return;
      }
  
      /** * Validate file type
      if (!ALLOWED_FILE_TYPES.includes(file.type)) {
        console.error("Invalid file type.");
        toast.error("Invalid file type. Only JPEG, PNG, and GIF are allowed.");
        return;
      }
      */
  
      const reader = new FileReader();
      reader.onload = async () => {
        const tempPhotoUrl = reader.result;
  
        const existingIds = photos.map(photo => parseInt(photo.photoId.replace('Photonum', ''), 10));
  
        let newIndex = 0;
        while (existingIds.includes(newIndex) && newIndex < MAX_PHOTOS) {
          newIndex++;
        }
  
        const tempPhotoId = `Photonum${newIndex}`;
        setPhotos([...photos, { photoId: tempPhotoId, photoUrl: tempPhotoUrl }]);
  
        try {
          await dispatch(uploadPhotoWithoutStorage({ photoId: tempPhotoId, file })).unwrap();
          toast.success("Photo uploaded successfully!");
        } catch (error) {
          //console.error('Error uploading photo:', error);
          toast.error("Failed to upload the photo.");
        }
      };
      reader.readAsDataURL(file);
    }
  };

  const handleDeletePhoto = async (photoId) => {
    const updatedPhotos = photos.filter(photo => photo.photoId !== photoId);
    setPhotos(updatedPhotos);
    await dispatch(deletePhoto({ photoId })).unwrap();
  };

  useEffect(() => {
    const fetchPhotos = async () => {
      try {
        const availablePhotos = await getAvailablePhotosAPI();

        const photosArray = Object.entries(availablePhotos)
          .filter(([photoId]) => photoId !== 'photoprofil')
          .map(([photoId, photoUrl]) => ({
            photoId,
            photoUrl,
          }));

        setPhotos(photosArray);
        setLoading(false); // Arrêter le chargement après avoir mis à jour les photos
        //console.log(photos)
      } catch (error) {
        //console.error("Error fetching photos:", error);
        setLoading(false); // Arrêter le chargement après avoir mis à jour les photos
      } 
    };

    fetchPhotos();
  }, [idToken, dispatch]);

  return (
    <div className="p-6 bg-white shadow-md rounded-b-lg">

            <SEO 
              title="Update your photos - Havenude" 
              description="Choose the best photos to attract more attention. Add up to 5 photos to your profile." 
              name="HaveNude" 
              type="website"
            />
      <h2 className="text-2xl font-semibold mb-6">Add Photos</h2>

      {/* Section explicative */}
      <div className="bg-gray-100 p-4 rounded mb-6">
        <h3 className="text-lg font-semibold text-gray-800">How to use this feature?</h3>
        <p className="text-gray-600 mt-2">
          You can upload up to <span className="font-bold">{MAX_PHOTOS}</span> photos. These photos will appear on the home page if you use a Top-Up or a FirePIN. 
          Once uploaded, you can organize or delete photos as needed. If you're a VIP, enjoy additional features such as 
          increased visibility for your profile. Choose your photos wisely to attract more attention!
        </p>
        <p className="text-gray-600 mt-2">Keep in mind that:</p>
        <ul className="list-disc list-inside text-gray-600">
          <li>Each photo must be less than {MAX_FILE_SIZE_MB}MB.</li>
          <li>Be creative and pick the photos that best represent you!</li>
        </ul>
      </div>

      <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 lgplus:grid-cols-6 gap-4 mb-6">
        {loading ? (
          // Affichage des placeholders de squelette pendant le chargement
          [...Array(MAX_PHOTOS)].map((_, index) => (
            <div key={index} className="w-32 h-64 mx-auto my-2 skeleton skeleton-wrapper"></div>
          ))
        ) : (
          photos.map((photo, index) => (
            <div key={index} className="relative group w-32 h-64 mx-auto my-2">
              <img
                src={photo.photoUrl}
                alt={`Uploaded ${index}`}
                className="object-cover w-full h-full rounded"
              />
              <div className="absolute inset-0 bg-gray-800 bg-opacity-50 flex justify-center items-start opacity-0 group-hover:opacity-100 transition-opacity pt-2">
                <button
                  className="text-white p-2"
                  onClick={() => handleDeletePhoto(photo.photoId)}
                >
                  <FaTrash size={20} />
                </button>
                <button
                  className="text-white p-2 ml-2"
                  onClick={() => openViewer(index)}
                >
                  <FaExpand size={20} />
                </button>
              </div>
            </div>
          ))
        )}
        {photos.length < MAX_PHOTOS && !loading && (
          <label className="w-32 h-64 flex items-center justify-center bg-gray-200 rounded cursor-pointer hover:bg-gray-300 mx-auto my-2">
            <input
              type="file"
              className="hidden"
              accept="image/*"
              onChange={handleAddPhoto}
            />
            <span className="text-gray-500 text-2xl">+</span>
          </label>
        )}
      </div>

      {isViewerOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-75 flex items-center justify-center z-50" onClick={closeViewer}>
          <div className="relative w-full max-w-4xl" ref={galleryRef} onClick={(e) => e.stopPropagation()}>
            <button
              className="absolute top-2 right-2 text-white text-2xl z-50"
              onClick={closeViewer}
            >
              &times;
            </button>
            <ImageGallery
              items={photos.map(photo => ({ original: photo.photoUrl, thumbnail: photo.photoUrl }))}
              startIndex={currentPhotoIndex}
              showThumbnails={false}
              showFullscreenButton={false}
              showPlayButton={false}
              additionalClass="custom-gallery"
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default Photo;