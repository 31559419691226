import React, { useState, useRef, useEffect } from 'react';
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';
import './css/FilterSmallScreen.css'; // Assurez-vous que ce fichier CSS est importé
import Button from '../Buttons/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSyncAlt } from '@fortawesome/free-solid-svg-icons';
import ReactFlagsSelect from 'react-flags-select';
import predefinedTags from '../../data/tags'; // Import des tags depuis le fichier
import { genderOptions, orientationOptions } from '../../data/enum';

const FilterSmallScreen = ({ handleRefresh, onFiltersChange }) => {
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [isTrieOpen, setIsTrieOpen] = useState(false);

  const [age, setAge] = useState([18, 60]);
  const [username, setUsername] = useState('');
  const [gender, setGender] = useState('All');
  const [orientation, setOrientation] = useState('All');
  const [country, setCountry] = useState('');
  const [isVIP, setIsVIP] = useState(false);
  const [tagInput, setTagInput] = useState('');
  const [tags, setTags] = useState([]);
  const [filteredTags, setFilteredTags] = useState([]);
  const tagInputRef = useRef(null);
  const filteredTagsRef = useRef(null);


  const toggleTrie = () => {
    setIsTrieOpen(!isTrieOpen);
  };

  const toggleFilter = () => {
    setIsFilterOpen(!isFilterOpen);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const filters = {
      username,
      age,
      gender,
      orientation,
      country,
      isVIP,
      tags,
    };
    //console.log('Selected Filters:', filters);
    onFiltersChange(filters); // Appeler la fonction de gestion des filtres
  };

  const handleClearCountry = () => {
    setCountry(''); // Clear the selected country
  };

  const handleTagInputChange = (e) => {
    const input = e.target.value;
    setTagInput(input);
    if (input) {
      const filtered = predefinedTags.filter(t => t.toLowerCase().includes(input.toLowerCase()));
      setFilteredTags(filtered);
    } else {
      setFilteredTags([]);
    }
  };

  const handleTagSelect = (selectedTag) => {
    if (!tags.includes(selectedTag)) {
      setTags([...tags, selectedTag]);
    }
    setTagInput('');
    setFilteredTags([]);
  };

  const handleTagRemove = (tagToRemove) => {
    setTags(tags.filter(tag => tag !== tagToRemove));
  };

  const handleTagInputFocus = () => {  // Affiche tous les tags disponibles non sélectionnés lors du clic
    setFilteredTags(predefinedTags.filter((tag) => !tags.includes(tag)));
  };


  const handleClickOutside = (event) => {
    if (
      tagInputRef.current && 
      !tagInputRef.current.contains(event.target) &&
      filteredTagsRef.current && 
      !filteredTagsRef.current.contains(event.target)
    ) {
      setFilteredTags([]);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div>
      <div className="bg-[#A9A9A9] flex justify-between items-center lg:hidden">
        <div className="flex items-center justify-center w-1/2 cursor-pointer" onClick={handleRefresh}>
          <button className="font-semibold text-gray-700 flex items-center space-x-2 p-4">
            <span>Refresh</span>
            <FontAwesomeIcon icon={faSyncAlt} className="w-4 h-4" />
          </button>
        </div>
        <div className="border-l h-6"></div>
        <div className="flex items-center justify-center w-1/2 cursor-pointer" onClick={toggleFilter}>
          <button className="font-semibold text-gray-700 flex items-center space-x-2 p-4">
            <span>FILTER</span>
            <svg
              className={`w-4 h-4 ${isFilterOpen ? 'rotate-180' : 'rotate-0'}`}
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 9l-7 7-7-7"></path>
            </svg>
          </button>
        </div>
      </div>
      {isFilterOpen && (
        <div className="bg-white p-4 mt-2 shadow-lg rounded-lg">
          <form onSubmit={handleSubmit}>
            <div className="mb-4">
              <label className="block text-gray-700 font-semibold mb-2">Username</label>
              <input 
                type="text" 
                className="w-full p-2 border border-gray-300 rounded focus:outline-none focus:border-[#AC3B61]" 
                placeholder="Username"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
              />
            </div>

            {/* Country */}
            <div className="mb-6">
              <label className="block text-gray-700 font-bold text-sm mb-2">Country</label>
              <ReactFlagsSelect
                searchable={true}
                onSelect={(code) => setCountry(code)}
                selected={country}
                optionsSize={14}
                customStyles={{
                  menu: (provided) => ({
                    ...provided,
                    maxHeight: '300px', // Adjust this height to show more options
                    overflowY: 'auto', // Allow scrolling if the content overflows
                  }),
                }}
                
              />
              {country && (
                <button
                  type="button"
                  className="mt-2 text-sm text-blue-500 hover:underline"
                  onClick={handleClearCountry}
                >
                  Clear Country
                </button>
              )}
            </div>

            {/* Tags */}
            <div className="mb-6 relative" ref={tagInputRef}>
            <label className="block text-gray-700 font-bold text-sm mb-2">Tags</label>
              <div className="flex flex-wrap gap-2 mb-2">
                {tags.map((tag, index) => (
                  <div key={index} className="flex items-center bg-gray-200 rounded-full px-3 py-1">
                    <span className="mr-2">{tag}</span>
                    <button 
                      type="button" 
                      className="text-gray-500 hover:text-gray-700"
                      onClick={() => handleTagRemove(tag)}
                    >
                      &times;
                    </button>
                  </div>
                ))}
              </div>
              <input
                type="text"
                className="w-full p-2 border border-gray-300 rounded focus:outline-none focus:border-[#AC3B61]"
                placeholder="Search tags..."
                value={tagInput}
                onChange={handleTagInputChange}
                onFocus={handleTagInputFocus} // Affiche la liste au focus
                ref={tagInputRef}
              />
              {filteredTags.length > 0 && (
                <ul
                  className="absolute bg-white border border-gray-300 rounded w-full mt-1 max-h-40 overflow-y-auto z-10"
                  ref={filteredTagsRef}
                >
                  {filteredTags.map((t, index) => (
                    <li 
                      key={index} 
                      className="p-2 hover:bg-[#AC3B61] hover:text-white cursor-pointer"
                      onClick={() => handleTagSelect(t)}
                    >
                      {t}
                    </li>
                  ))}
                </ul>
              )}
            </div>

            
            {/* Gender */}
            <div className="flex space-x-4 mb-4">
              <div className="w-1/2">
                <label className="block text-gray-700 font-semibold mb-2">Gender</label>
                <select 
                  className="w-full p-2 border border-gray-300 rounded focus:outline-none focus:border-[#AC3B61]"
                  value={gender}
                  onChange={(e) => setGender(e.target.value)}
                >
                  {genderOptions.map((option, index) => (
                    <option key={index} value={option}>{option}</option>
                  ))}
                </select>
              </div>
              <div className="w-1/2">
                <label className="block text-gray-700 font-semibold mb-2">Orientation</label>
                <select 
                  className="w-full p-2 border border-gray-300 rounded focus:outline-none focus:border-[#AC3B61]"
                  value={orientation}
                  onChange={(e) => setOrientation(e.target.value)}
                >
                  {orientationOptions.map((option, index) => (
                    <option key={index} value={option}>{option}</option>
                  ))}
                </select>
              </div>
            </div>

            {/* Age */}
            <div className="mb-4">
              <label className="block text-gray-700 font-semibold mb-2">Age</label>
              <Slider 
                range 
                min={18} 
                max={60} 
                value={age} 
                onChange={setAge} 
                 trackStyle={[{ backgroundColor: '#AC3B61' }]}
                handleStyle={[{ borderColor: '#AC3B61' }, { borderColor: '#AC3B61' }]}
          
              />
              <div className="flex justify-between text-sm text-gray-500 mt-2">
                <span>{age[0]}</span>
                <span>{age[1]}</span>
              </div>
            </div>

            <div className="mb-4 flex items-center">
              <label className="block text-gray-700 font-semibold mr-4">Is VIP</label>
              <input 
                type="checkbox" 
                className="form-checkbox h-5 w-5 text-[#AC3B61]" 
                checked={isVIP}
                onChange={(e) => setIsVIP(e.target.checked)}
              />
            </div>

        <Button
          onClick={null}
          variant={'outline'}
          className="w-full"
        >
          Filter
        </Button>
          </form>
        </div>
      )}
    </div>
  );
};

export default FilterSmallScreen;
