import React from 'react';
import classNames from 'classnames';

const Button = ({
  onClick,
  children,
  size = 'medium',
  variant = 'filled',
  rounded = 'lg',
  className = '',
  disabled = false, // Nouvelle prop pour le statut désactivé
}) => {
  // Définir les classes de taille
  const sizeClasses = {
    small: 'px-2 py-1 text-sm',
    medium: 'px-4 py-2',
    large: 'px-6 py-3 text-lg',
  };

  const roundedClasses = {
    sm: 'rounded-sm',
    lg: 'rounded-lg',
    xl: 'rounded-xl',
    twoxl: 'rounded-2xl',
    threexl: 'rounded-3xl',
    full: 'rounded-full',
  };

  // Définir les classes dynamiques basées sur les props
  const baseClasses = `text-center transition duration-300 ease-in-out ${className}`;
  const dynamicClasses = classNames(
    sizeClasses[size],
    roundedClasses[rounded],
    {
      'bg-[#AC3B61] text-white hover:bg-[#b85173]': variant === 'filled',
      'bg-transparent border border-[#AC3B61] text-[#AC3B61] hover:bg-[#b85173] hover:text-white': variant === 'outline',
      'opacity-50': disabled, // Appliquer l'opacité lorsque le bouton est désactivé
    }
  );

  return (
    <button
      onClick={disabled ? undefined : onClick} // Désactive onClick si le bouton est désactivé
      className={`${baseClasses} ${dynamicClasses}`}
      disabled={disabled} // Ajoute l'attribut disabled au bouton
    >
      {children}
    </button>
  );
};

export default Button;
