import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import apiClient from '../api/apiClient';
import Button from '../components/Buttons/Button';
import { Helmet } from 'react-helmet';
import SEO from '../utils/SEO';

const ResetPasswordPage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [isValidCode, setIsValidCode] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isReseting, setIsReseting] = useState(false);
  const [newPassword, setNewPassword] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState(false);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const oobCode = params.get('oobCode');

    if (!oobCode) {
      navigate('/'); // Rediriger si oobCode est manquant
      return;
    }

    const checkCode = async () => {
      try {
        const response = await apiClient.post('/auth/ckeck-code', { oobCode });
        if (response.status === 200) {
            setIsValidCode(true);
        }
      } catch (error) {
        setError('Invalid or expired reset link');
        //console.log("Invalid or expired reset link");
        //navigate('/'); // Rediriger si le code est invalide
      }
      finally {
        setIsLoading(false); // Assure-toi que le chargement est terminé
      }
    };

    checkCode();
  }, [location, navigate]);

  const handlePasswordReset = async () => {
    setError('')
    setIsReseting(true)
    const params = new URLSearchParams(location.search);
    const oobCode = params.get('oobCode');

    try {
      const response = await apiClient.post('/auth/password-reset', { oobCode, newPassword });
      if (response.status === 200) {
        setSuccess(true);
      }
    } catch (error) {
      setError('Failed to reset password');
      //console.error("Failed to reset password");
    }
    finally {
      setIsReseting(false);
    }
  };

  if (isLoading) {
    return (
        <div className="flex items-center justify-center h-96 ">
            <div className="flex items-center justify-center" >Loading...</div>
        </div>
    );
  }

  return (
    <div className="flex items-center justify-center h-96 ">
      
      <SEO 
        title="Reset Password - Havenude" 
        description="Reset your password without any hassle." 
        name="HaveNude" 
        type="website"
      />
      <div className="bg-white shadow-lg rounded-lg p-8 max-w-md w-full text-center">
        <h2 className="text-2xl font-bold text-gray-800 mb-6">Reset Your Password</h2>

        {error && <p className="text-red-600 mb-4 text-center text-base">{error}</p>}

        {success ? (
          <p className="text-green-600 mb-4">Password has been reset successfully! You can now log in.</p>
        ) : (
          isValidCode && (
            <form
              onSubmit={(e) => {
                e.preventDefault();
                handlePasswordReset();
              }}
              className="space-y-6"
            >
              <input
                type="password"
                placeholder="New password"
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
                required
                className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
              />
              <Button
                type="submit"
                variant="filled"
                className="w-full mt3"
                disabled={isReseting}
              >
                {isReseting ? 'Reset...' : 'Reset Password'}
              </Button>  
            </form>
          )
        )}
      </div>
    </div>
  );
};

export default ResetPasswordPage;