import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import toast from 'react-hot-toast';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import "./css/Modal.css";
import topUpLogo from '../../assets/TOPUP.png';
import firePinLogo from '../../assets/firepin.png';
import { topUpThunk, firePinThunk } from '../../redux/features/userSlice';

Modal.setAppElement('#root');

const SubscriptionInfo = ({ title, description, tags, onLearnMore, onViewOffers, onViewSubscription, handleRefresh }) => {
  const tagStyles = {
    Free: 'bg-gray-200 text-gray-700',
    VIP: 'bg-pink-100 text-[#AC3B61]',
    Certified: 'bg-blue-100 text-blue-700',
  };
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalAction, setModalAction] = useState(null);
  const [showAlert, setShowAlert] = useState(false);

  const isVIP = tags.includes("VIP");
  const isAuthenticated = useSelector((state) => state.user.isAuthenticated);
  const user = useSelector((state) => state.user.user);
  const [topUp, setTopUp] = useState(100);
  const [firePin, setFirePin] = useState(100);

  const dispatch = useDispatch();

  const requiredFields = ["username", "age", "gender", "orientation"];
  const missingFields = requiredFields.filter(field => !user?.[field]);

  useEffect(() => {
    const rootElement = document.getElementById('root');
    if (isModalOpen) {
      rootElement.setAttribute('inert', 'true');
    } else {
      rootElement.removeAttribute('inert');
    }
  }, [isModalOpen]);

  useEffect(() => {
    if (user) { 
      setTopUp(user.top_up_balance);
      setFirePin(user.fire_pins_balance);
    }
  }, [user]);

  const openConfirmationModal = (action) => {
    if (missingFields.length > 0) {
      setShowAlert(true);
      return;
    }

    if (action === 'topUp' && topUp <= 0) {
      toast.error("Insufficient top-up balance.");
      return;
    }

    if (action === 'firePin' && firePin <= 0) {
      toast.error("Insufficient FirePin balance.");
      return;
    }

    setModalAction(action);
    setIsModalOpen(true);
  };

  const closeModal = () => setIsModalOpen(false);

  const handleConfirmation = async () => {
    setIsLoading(true);
    try {
      if (modalAction === 'topUp') {
        await dispatch(topUpThunk()).unwrap();
        await handleRefresh();
        toast.success('Top up used successfully! Refresh your page to see the effect.');
      } else if (modalAction === 'firePin') {
        await dispatch(firePinThunk()).unwrap();
        await handleRefresh();
        toast.success('Fire pin used successfully! Wait a few seconds for the effect to take place.');
      }
    } catch (error) {
      //console.error("Action failed", error);
      toast.error(error.data.message);
    } finally {
      setIsLoading(false);
      closeModal();
    }
  };

  return (
    <div className="bg-white shadow-md rounded-lg p-6 mb-4 lgplus:w-72">
      {/* Bandeau d'avertissement conditionnel */}
      {user?.report >= 3 && (
        <div className="p-4 mb-6 bg-red-100 text-red-800 border border-red-300 rounded-md">
          <p className="text-sm font-semibold">
            Your account has been reported multiple times. To resolve this issue, please contact us.
            <button
              onClick={() => navigate("/contact")}
              className="text-blue-600 underline ml-2"
            >
              Go to Contact Page
            </button>
          </p>
        </div>
      )}

      {isAuthenticated && showAlert && missingFields.length > 0 && (
        <div className="p-4 mb-6 bg-yellow-100 text-yellow-800 border border-yellow-300 rounded-md">
          <p className="text-sm font-semibold">
            Please complete your profile information: {missingFields.join(", ")}.{" "}
            <button
              onClick={() => navigate("/profile-info/informations")}
              className="text-blue-600 underline ml-2"
            >
              Go to Profile
            </button>
          </p>
        </div>
      )}

      {title && <h3 className="font-semibold text-2xl mb-5">{title}</h3>}
      {tags && tags.length > 0 && (
        <div className="flex space-x-2 mb-4">
          {tags.map((tag, index) => (
            <span
              key={index}
              className={`inline-block px-3 py-1 text-sm font-semibold rounded-full ${tagStyles[tag] || ''}`}
            >
              {tag}
            </span>
          ))}
        </div>
      )}
      {description && <p className="text-gray-600 mb-4">{description}</p>}
      {isVIP && <p className="text-gray-600 mb-4">As a VIP user, you have 1 top Up each hour</p>}

      {isAuthenticated && (
        <div className="grid grid-cols-2 gap-4 mt-4">
          <button
            onClick={() => openConfirmationModal('topUp')}
            className="relative flex items-center justify-center h-24 bg-gray-200 text-gray-700 hover:bg-gray-300 border border-gray-300 rounded-lg transition duration-300"
            disabled={isLoading && modalAction === 'topUp'}
          >
            <img
              src={topUpLogo}
              alt="Top Up Logo"
              className="h-6 w-6 sm:mb-1 sm:static sm:mt-1 lgplus:mb-1 lgplus:static lgplus:mt-1 md:absolute md:top-2 md:right-2 "
            />
            <span className="absolute top-2 left-2 font-semibold">{isLoading && modalAction === 'topUp' ? "Processing..." : "Use Top Up"}</span>
            <span className="absolute bottom-2 left-2 text-sm">{`Remain: ${topUp}`}</span>
          </button>

          <button
            onClick={() => openConfirmationModal('firePin')}
            className="relative flex items-center justify-center h-24 bg-red-300 text-red-800 hover:bg-red-200 border border-red-300 rounded-lg transition duration-300"
            disabled={isLoading && modalAction === 'firePin'}
          >
            <img
              src={firePinLogo}
              alt="FirePin Logo"
              className="h-6 w-6 sm:mb-1 sm:static sm:mt-1 lgplus:mb-1 lgplus:static lgplus:mt-1 md:absolute md:top-2 md:right-2"
            />
            <span className="absolute top-2 left-2 font-semibold">{isLoading && modalAction === 'firePin' ? "Processing..." : "Use FirePin"}</span>
            <span className="absolute bottom-2 left-2 text-sm">{`Remain: ${firePin}`}</span>
          </button>
        </div>
      )}

      {/**
       * {isAuthenticated && (
        isVIP ? (
          <button
            className="flex items-center text-[#AC3B61] hover:text-[#b08392] font-semibold mt-4"
            onClick={onViewSubscription}
          >
            View Your Subscription
            <span className="ml-2">→</span>
          </button>
        ) : (
          <button
            className="flex items-center text-[#AC3B61] hover:text-[#b08392] font-semibold mt-4"
            onClick={onViewOffers}
          >
            See Offers
            <span className="ml-2">→</span>
          </button>
        )
      )}
       * 
       */}
      

      <Modal
        isOpen={isModalOpen}
        onRequestClose={closeModal}
        contentLabel="Confirmation Modal"
        className="modal-content"
        overlayClassName="modal-overlay"
      >
        <h2 className="text-lg font-semibold mb-4">Confirm Action</h2>
        <p className="text-gray-700 mb-6">
          {modalAction === 'topUp' 
            ? "Are you sure you want to use a top up? This action will decrease your top-up balance." 
            : "Are you sure you want to activate a FirePin? This action will make your profile stand out for a limited time."
          }
        </p>
        <div className="flex justify-end space-x-4">
          <button onClick={closeModal} className="px-4 py-2 bg-gray-300 rounded">Cancel</button>
          <button onClick={handleConfirmation} className="px-4 py-2 bg-blue-600 text-white rounded">
            {isLoading ? "Processing..." : "Confirm"}
          </button>
        </div>
      </Modal>
    </div>
  );
};

export default SubscriptionInfo;
