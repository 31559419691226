import React, { useState, useRef, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom'; // Importez useLocation et useNavigate pour naviguer et récupérer les données du state
import { FaTwitter, FaInstagram, FaArrowLeft, FaCheckCircle, FaStar, FaMars, FaVenus, FaTransgender, FaGenderless, FaVenusMars, FaMale, FaFemale, FaTransgenderAlt } from 'react-icons/fa';
import { Helmet } from 'react-helmet-async';
import ImageGallery from 'react-image-gallery';
import "react-image-gallery/styles/css/image-gallery.css";
import ReactCountryFlag from 'react-country-flag';
import { FaSnapchat } from "react-icons/fa6";

const PublicProfile = () => {

  useEffect(() => {
    window.scrollTo(0, 0); // Force la page à remonter en haut
  }, []);

  const navigate = useNavigate();
  const location = useLocation();
  const profileData = location.state; // Récupère les données passées via le state

  const [isViewerOpen, setIsViewerOpen] = useState(false);
  const [currentPhotoIndex, setCurrentPhotoIndex] = useState(0);
  const galleryRef = useRef(null);

  const handleGoBack = () => {
    // Pass posts and scroll position back to Home
    navigate(-1 );
  };
  
  // Si les données du profil ne sont pas disponibles
  if (!profileData) {
    return <div>Loading...</div>;
  }

  // Extraire les données du profil transmises via le state
  const {
    author,
    sexe,
    orientation,
    age,
    images,
    description,
    country,
    tier,
    tags,
    isVerified
  } = profileData;

  console.log(author)

  // Map des images
  const imageItems = images.map((photo) => ({
    original: photo,
    thumbnail: photo,
  }));

  const openViewer = (index) => {
    setCurrentPhotoIndex(index);
    setIsViewerOpen(true);
  };

  const closeViewer = () => {
    setIsViewerOpen(false);
  };

  const renderOrientationIcon = (orientation) => {
    switch (orientation) {
      case "Gay":
        return <FaMale className="text-blue-500 ml-1" />;
      case "Lesbian":
        return <FaFemale className="text-pink-500 ml-1" />;
      case "Bisexual":
        return <FaTransgenderAlt className="text-purple-500 ml-1" />;
      case "Straight":
        return <FaVenusMars className="text-green-500 ml-1" />;
      case "Other":
        return <FaGenderless className="text-gray-500 ml-1" />;
      default:
        return null;
    }
  };

  const renderGenderIcon = (gender) => {
    switch (gender) {
      case "Female":
        return <FaVenus className="text-pink-500 ml-1" />;
      case "Male":
        return <FaMars className="text-blue-500 ml-1" />;
      case "Transgender":
        return <FaTransgender className="text-purple-500 ml-1" />;
      case "Other":
        return <FaGenderless className="text-gray-500 ml-1" />;
      default:
        return null;
    }
  };

  return (
    <div className="bg-gray-100 rounded-lg shadow-md">
      <Helmet>
        <title>Public Profile :  {author.name} - Havenude</title>
        <meta name="description" content="Add thousands of profiles to your list and chat with them." />
        <meta property="og:title" content="Havenude" />
        <meta property="og:description" content="Add thousands of profiles to your list and chat with them." />
        {/* Ajouter d'autres balises SEO ou Open Graph ici */}
      </Helmet>
            
      <div className="bg-white h-40 grid grid-cols-1 lg:grid-cols-3 lgplus:gap-8 relative">
        <div>
          <button
            className="text-gray-600 hover:text-gray-900 focus:outline-none mt-32 ml-5"
            onClick={handleGoBack}
          >
            <FaArrowLeft size={24} />
          </button>
        </div>
        <div className="items-end ml-8 relative lg:flex hidden">
          <p className="text-lg pl-6 pb-2">Profile</p>
          <div className="absolute bottom-0 left-0 w-24 h-1 bg-gradient-to-r from-pink-500 to-red-500"></div>
        </div>
      </div>

      <div className="grid grid-cols-1 lg:grid-cols-3 p-6 relative">
        {/* Section gauche : Informations du profil */}
        <div className="col-span-1 flex flex-col items-center relative">
          <img
            src={author.image}
            alt="Avatar"
            className="h-44 w-44 rounded-full mb-4 absolute -top-28 border-4 border-white object-cover"
          />
          <div className="mt-16 text-center">
          <div className="flex items-center justify-center mb-2 mt-6">
            <h1 className="text-3xl font-semibold ">{author.name}</h1>
            {isVerified && <FaCheckCircle className="text-[#c63e6c] ml-2" size={24}/>}
            {tier === "VIP" && <FaStar className="text-yellow-500 text-2xl ml-3" size={24}/>}
            {country !== "Unknown" && (
                <span className='ml-3'>
                  <ReactCountryFlag 
                    countryCode={country} 
                    style={{ fontSize: "2em" }} 
                    className="pl-2" 
                    svg
                    aria-label={country}
                  />  
                </span>
              )}  
          </div>
            
            
            
            <p className="text-gray-600 text-center mt-4">{description}</p>
            <p className="text-gray-600 text-center mt-4 flex items-center justify-center space-x-2">
              <span>{age}</span>
              <span>-</span>
              <span className="flex items-center">
                {sexe} {renderGenderIcon(sexe)}
              </span>
              <span>-</span>
              <span className="flex items-center">
                {orientation} {renderOrientationIcon(orientation)}
              </span>
            </p>
            
            
              <div className="flex justify-center space-x-8 mt-8">
                {/* Lien Twitter */}
                {author.twitter_username && (
                  <a
                    href={`https://twitter.com/${author.twitter_username}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="hover:opacity-80"
                  >
                    <FaTwitter className="text-blue-500 cursor-pointer" size={24} />
                  </a>
                )}

                {/* Lien OnlyFans */}
                {author.of_username && (
                  <a
                    href={`https://onlyfans.com/${author.of_username}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="hover:opacity-80"
                  >
                    <img
                      src={require('../../assets/images/onlyfans.svg').default} // Chemin vers le fichier SVG
                      alt="OnlyFans"
                      className="w-6 h-6 cursor-pointer"
                    />
                  </a>
                )}

                {/* Lien Instagram */}
                {author.insta_username && (
                  <a
                    href={`https://www.instagram.com/${author.insta_username}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="hover:opacity-80"
                  >
                    <FaInstagram className="text-pink-600 cursor-pointer" size={24} />
                  </a>
                )}

                {/* Lien Snapchat */}
                {author.name && (
                  <a
                    href={`https://www.snapchat.com/add/${author.name}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="hover:opacity-80"
                  >
                    <FaSnapchat className="text-yellow-600" size={24}/>
                  </a>
                )}
              </div>
            
          </div>
        </div>

        {/* Section droite : Images et autres informations */}
        <div className="col-span-2">
        <div className="rounded-lg bg-white grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-4 lgplus:grid-cols-5 gap-4 mb-6 p-5 mt-8">
            {images.length > 0 ? (
              images.map((photo, index) => (
                <div key={index} className="relative group w-full h-full flex items-center justify-center">
                  <img
                    src={photo}
                    alt={`Uploaded ${index}`}
                    className="object-contain rounded w-32 h-64 hover:opacity-75 transition-opacity duration-300 cursor-pointer"
                    onClick={() => openViewer(index)}
                  />
                </div>
              ))
            ) : (
              <div className="col-span-full text-center text-gray-500">
                No photos
              </div>
            )}
          </div>


          <div className="bg-white p-6 rounded-lg">
            <h2 className="text-xl font-semibold mb-4">Tags</h2>
            {/* Affichage dynamique des tags */}
            <div className="text-gray-700 m-4 flex flex-wrap gap-2">
              {tags && tags.length > 0 ? (
                tags.map((tag, index) => (
                  <span key={index} className="bg-gray-200 rounded-full px-3 py-1 text-sm">{tag}</span>
                ))
              ) : (
                <span className="text-gray-500 text-sm">No tags</span>
              )}
            </div>
          </div>
        </div>
      </div>

      {isViewerOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-75 flex items-center justify-center z-50" onClick={closeViewer}>
          <div className="relative w-full max-w-4xl" ref={galleryRef} onClick={(e) => e.stopPropagation()}>
            <button
              className="absolute top-2 right-2 text-white text-2xl z-50"
              onClick={closeViewer}
            >
              &times;
            </button>
            <ImageGallery
              items={imageItems}
              startIndex={currentPhotoIndex}
              showThumbnails={false}
              showFullscreenButton={false}
              showPlayButton={false}
              showBullets={true}
              showNav={true}
              additionalClass="custom-gallery"
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default PublicProfile;
