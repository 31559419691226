import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import SEO from '../utils/SEO';

const faqData = [
  {
    category: 'General',
    questions: [
      {
        question: 'I forgot my password! ',
        answer:
          'On the login part, click on forgotten password and enter the email address of your user account.  You will find a link to reset your password on your email!',
      },
      { question: 'My account is not working, what should I do ?', answer: `If your account does not work, it is possible that your browser is not up to date or that the site is temporarily under maintenance. Wait a few moments or reset your connection or the media you're using. If your account still doesn't work, contact us with the contact form` },
      { question: 'How do I buy a product ?', answer: 'We are working on a payment platform for you to have VIP feature and a lot of more..' },
    ],
  },
  
  {
    category: 'My informations',
    questions: [
      { question: 'How do I change my account email?', answer: `You can't change your email for now, in a near future it will be possible. You can create an other account if needed.` },
      { question: 'How does support work?', answer: 'Support is available 24/7 via email.' },
    ],
  },
  {
    category: 'Products',
    questions: [
      { question: 'What is a top Up ?', answer: 'A Top-Up is a feature that allows your profile to appear at the very top of the post list on the platform. By gaining this prime position, your profile becomes instantly visible, making it much easier to get noticed by others.' },
  
      { question: 'What is a FirePIN ?', answer: 'A FirePIN is a premium feature that locks your profile at the top of the post list for 30 minutes. Unlike the Top-Up, where other users can quickly take your place, the FirePIN guarantees that your profile stays fixed at the top.' },
    ],
  },
];

const FAQPage = () => {
    const navigate = useNavigate()
  const [activeCategory, setActiveCategory] = useState('General');
  const [activeQuestion, setActiveQuestion] = useState(null);

  const toggleQuestion = (index) => {
    setActiveQuestion(activeQuestion === index ? null : index);
  };

  const handleCategoryChange = (category) => {
    setActiveCategory(category);
    setActiveQuestion(null); // Reset the active question
  };

  return (
    <div className="container mx-auto px-4 py-12 max-w-3xl">
      <SEO 
        title="FAQ - Havenude" 
        description="Here some answers to the most frequently asked questions." 
        name="HaveNude" 
        type="website"
      />
      <h1 className="text-3xl font-semibold text-center mb-4">Frequently Asked Questions</h1>
      <p className="text-center text-gray-500 mb-8">
        These are the most commonly asked questions. Can’t find what you’re looking for?{' '}
        <button
            onClick={() => navigate('/contact')}
            className="text-blue-600 underline"
            >
          Contact page
          </button>
        
      </p>

      {/* Category Tabs */}
      <div className="flex flex-wrap justify-center gap-4 mb-8">
      {faqData.map((item) => (
          <button
            key={item.category}
            onClick={() => handleCategoryChange(item.category)}
            className={`px-4 py-2 rounded-full font-medium ${
              activeCategory === item.category
                ? 'bg-black text-white'
                : 'bg-gray-200 text-gray-600 hover:bg-gray-300'
            }`}
          >
            {item.category}
          </button>
        ))}
      </div>

      {/* FAQ Items */}
      <div>
        {faqData
          .find((item) => item.category === activeCategory)
          .questions.map((item, index) => (
            <div key={index} className="border-b border-gray-200 py-4">
              <button
                onClick={() => toggleQuestion(index)}
                className="flex justify-between items-center w-full text-left font-medium text-gray-800"
              >
                {item.question}
                <span>{activeQuestion === index ? '-' : '+'}</span>
              </button>
              {activeQuestion === index && <p className="text-gray-600 mt-2">{item.answer}</p>}
            </div>
          ))}
      </div>
    </div>
  );
};

export default FAQPage;
